import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Table, Pagination, Dropdown, Button } from "react-bootstrap";
import data from "../../../Dashboard/Projects/Tabldata.js";
import { APICall } from "../../../../../API/index";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Select from "react-select";
import Rte from "./Rte";
const Alldata = () => {
  const sort = 10;
  const {id}= useParams();
const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [dataTableFull, setDataTableFull] = useState([]);
  const [Filter, setFilter] = useState("");
  const [filterCombos, setFilterCombos] = useState({});
  const [pais, setPais] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [departamento, setDepartamento] = useState("");
  const [rubro, setRubro] = useState("");
  const [segmento, setSegmento] = useState("");
  const [dataCotizacion, setDataCotizacion] = useState({
    count: 0,
    status: false
  });
  const [filterOptions, setFilterOptions] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  const [Cotizacion, setCotizacion] = useState({title:"",descripcion:null});
  useEffect(() => {
    APICall.getCotizacionById(id).then(res => {
     
      setLoading(false);
      setDataTable(res.data.fornecedores);
      setDataTableFull(res.data.fornecedores);
      setCotizacion(res.data);
    });
    APICall.getFilters().then(res => {
      let filterOptionsAux = {
        paises: [],
        ciudades: [],
        departamentos: [],
        rubros: [],
        segmentos: []
      };
      res.data.paises.forEach(pais => {
        filterOptionsAux.paises.push({
          value: pais,
          label: pais
        });
      });
      res.data.ciudades.forEach(ciudad => {
        filterOptionsAux.ciudades.push({
          value: ciudad,
          label: ciudad
        });
      });
      res.data.estados.forEach(estado => {
        filterOptionsAux.departamentos.push({
          value: estado,
          label: estado
        });
      });
      res.data.rubros.forEach(rubro => {
        filterOptionsAux.rubros.push({
          value: rubro,
          label: rubro
        });
      });
      res.data.segmentos.forEach(segmento => {
        filterOptionsAux.segmentos.push({
          value: segmento,
          label: segmento
        });
      });
      setFilterOptions(filterOptionsAux);
    });
  }, []);

  useEffect(() => {
    if (
      Filter == "" &&
      pais == "" &&
      ciudad == "" &&
      departamento == "" &&
      rubro == "" &&
      segmento == ""
    ) {
      setDataTable(dataTableFull);
      let countChecked = 0;
      let activateEnviarCotizacion = false;
      dataTableFull.forEach(e => {
        if (e.checked) {
          countChecked++;
          activateEnviarCotizacion = true;
        }
      });
      setDataCotizacion({
        count: countChecked,
        status: activateEnviarCotizacion
      });
    } else {
      let listToPush = [];
      for (let i = 0; i < dataTableFull.length; i++) {
        const element = dataTableFull[i];

        if (
          JSON.stringify(element)
            .toLowerCase()
            .includes(Filter.toLowerCase()) &&
          (element.pais == pais.value || !pais.value) &&
          (element.ciudad == ciudad.value || !ciudad.value) &&
          (element.departamento == departamento.value || !departamento.value) &&
          (element.rubro.find(r => r == rubro.value) || !rubro.value) &&
          (element.segmento == segmento.value || !segmento.value)
        ) {
          listToPush.push(element);
        }
      }
      let countChecked = 0;
      let activateEnviarCotizacion = false;
      listToPush.forEach(e => {
        if (e.checked) {
          countChecked++;
          activateEnviarCotizacion = true;
        }
      });
      setDataCotizacion({
        count: countChecked,
        status: activateEnviarCotizacion
      });
      setDataTable(listToPush);
    }
  }, [Filter, pais, ciudad, departamento, rubro, segmento]);

  const columns = [
    "",
    "Nombre Real",
    "RUC / CNPJ /  ID",
    "Tipo de actividad",
   
    "País",

    "Correo"
  ];

  useEffect(() => {
    onClick(0);
  }, [dataTable]);

  let jobPagination = Array(Math.ceil(dataTable.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const activePag = useRef(0);
  const jobData = useRef(
    dataTable.slice(activePag.current * sort, (activePag.current + 1) * sort)
  );
  const [demo, setdemo] = useState();
  const onClick = i => {
    activePag.current = i;

    jobData.current = dataTable.slice(
      activePag.current * sort,
      (activePag.current + 1) * sort
    );
    setdemo(
      dataTable.slice(activePag.current * sort, (activePag.current + 1) * sort)
    );
  };

  const handleSearchChange = e => {
    setFilter(e.target.value);
  };

  const chackbox = document.querySelectorAll(".check-input input");
  const motherChackBox = document.querySelector(".check-input-all input");

  const chackboxFun = type => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  const checkBoxTypeElement = (i, type, e) => {
    let dataTableAux = [...dataTable];
    let activateEnviarCotizacion = false;
    let countChecked = 0;
    if (type === "all") {
      setCheckedAll(e.target.checked);
      for (let i = 0; i < dataTableAux.length; i++) {
        dataTableAux[i].checked = e.target.checked;
      }
    } else {
      dataTableAux[i].checked = !dataTableAux[i].checked;
    }
    dataTableAux.forEach(e => {
      if (e.checked) {
        countChecked++;
        activateEnviarCotizacion = true;
      }
    });
    setDataCotizacion({
      count: countChecked,
      status: activateEnviarCotizacion
    });
    setDataTable(dataTableAux);
  };

  const filterChange = (e, type) => {
    let filterCombosAux = { ...filterCombos };

    switch (type) {
      case "rubro":
        e ? setRubro(e) : setRubro("");
        break;
      case "segmento":
        e ? setSegmento(e) : setSegmento("");
        break;
      case "pais":
        e ? setPais(e) : setPais("");
        break;
      case "departamento":
        e ? setDepartamento(e) : setDepartamento("");
        break;
      case "ciudad":
        e ? setCiudad(e) : setCiudad("");
        break;
    }

    /*console.log(type);
    if (e) {
      filterCombosAux[type] = e.value;
      setFilterCombos(filterCombosAux);
      console.log(filterCombosAux);
    }
    else {
      filterCombosAux[type] = "";
      setFilterCombos(filterCombosAux);
    } */
  };

  const cleanFilters = () => {
    setPais("");
    setCiudad("");
    setDepartamento("");
    setRubro("");
    setSegmento("");
    setFilter("");
  };

  return (
    <Fragment>
      <div>
        <div className="row">
        <h3 className="pt-2 pb-3 col-12 col-md-6 col-lg-9">Detalles Cotización</h3>
      
              <div className={"col-12 col-md-6 col-lg-3"}>
              <Button
                        variant="primary"
                        style={{ display: "flex", alignItems: "center",marginBottom:20 }}
                        size={"sm"}
                        onClick={()=>{

                          history.push("/cotizacion-confirmar/?cotizacion="+encodeURIComponent(JSON.stringify(Cotizacion))+"&editable="+encodeURIComponent(JSON.stringify({isEditable:true})))
                          
                                  }}
                      >
                        <i
                          className="las la-paper-plane"
                          style={{ fontSize: "1.2rem" }}
                        ></i>
                        <span style={{ paddingLeft: 10, color: "white" }}>
                          Enviar Nuevo pedido de cotización
                        </span>
                      </Button>
                      </div>
                      </div>

      <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text">Línea de asunto</span>
          </div>
          <input
            defaultValue={Cotizacion.title}
            disabled
            id="title"
            type="text"
            className="form-control"
          />
        </div>
        {Cotizacion.descripcion &&    <Rte
          handleChange={e => {
           
          }}
          readOnly={true}
          initialState={Cotizacion.descripcion}
        ></Rte>}
     
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="navpills-1"
              role="tabpanel"
            >
              <Table
                responsive
                className="table-responsive card-table rounded table-hover fs-14"
              >
                <div
                  style={{
                    display: "flex",
                    padding: "15px",
                    backgroundColor: "white"
                  }}
                >
                  <div
                    className="col-5"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <h4 style={{ fontWeight: "bold", color: "black" }}>
                     Proveedores 
                    </h4>
                  </div>
                  <div
                    className="col-3"
                    style={{ display: "flex", alignItems: "center" }}
                  ></div>
                  <div className="col-4">
                    {
                      <Filters
                        handleSearchChange={handleSearchChange}
                        filter={Filter}
                      ></Filters>
                    }
                  </div>
                </div>

                <div id="example_wrapper" className="dataTables_wrapper">
                  <table
                    id="example"
                    className="table border-no display mb-4 dataTablesCard table-responsive-xl project-bx"
                  >
                    <thead>
                      <tr role="row">
                        {columns.map((d, i) => (
                          <th key={i} className="check-input-all">
                            {i === 0 ? (
                              <div
                                className="custom-control custom-checkbox"
                                style={{ zIndex: 0 }}
                              ></div>
                            ) : (
                              d
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {!loading &&
                        jobData.current.map((d, i) => {
                          
                          
                          return(
                          <tr key={i}>
                            <td>
                              <div
                                className="custom-control custom-checkbox "
                                style={{ zIndex: 0 }}
                              ></div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div>
                                  <span className="text-black font-w600 text-nowrap">
                                    {d.razonSocial || "No Tiene"}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div>
                                  <span className="text-black font-w600 text-nowrap">
                                    {d.registro || "NO TIENE"}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div>
                                  <span className="text-black font-w600 text-nowrap">
                                    {d.actividad || "NO TIENE"}
                                  </span>
                                </div>
                              </div>
                            </td>
                           
                            <td>
                              <div className="d-flex align-items-center">
                                <div>
                                  <span className="text-black font-w600 text-nowrap">
                                  {d.pais +", "+d.ciudad   || "NO TIENE"}
                                  </span>
                                </div>
                              </div>
                            </td>
                       
                            <td>
                              <div className="d-flex align-items-center">
                                <div>
                 
                                  <span className="text-black font-w600 text-nowrap">
                                    {d.email || d.gmail}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <Link
                                to={`/operar-proveedor/${d._id}/consultar-proveedor`}
                              >
                                <i
                                  className="fa fa-eye mr-3"
                                  style={{ fontSize: "1.5rem" }}
                                ></i>
                              </Link>
                            </td>
                          </tr>
                        )})}
                    </tbody>
                  </table>
                  {loading && (
                    <div
                      style={{
                        display: "flex",
                        flex: "1",
                        justifyContent: "center"
                      }}
                    >
                      <div
                        className="spinner-border"
                        style={{ height: "200px", width: "200px" }}
                      ></div>
                    </div>
                  )}
                  <div className="d-sm-flex justify-content-between  align-items-center mt-3">
                    <div className="dataTables_info">
                      {/* Total de {data.patientTable.data.length} elementos */}
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers">
                      <Pagination size="lg">
                        <li
                          className="page-item page-indicator "
                          onClick={() =>
                            activePag.current > 0 &&
                            onClick(activePag.current - 1)
                          }
                        >
                          {/* <Link className="page-link" to="#">  <i className="la la-angle-left" /></Link> */}
                          <Link className="paginate_button previous" to="#">
                            {" "}
                            Volver
                          </Link>
                        </li>
                        {jobPagination.map((number, i) => (
                          <Pagination.Item
                            className={activePag.current === i ? "active" : ""}
                            onClick={() => onClick(i)}
                          >
                            <span style={{ color: "black" }}>{number}</span>
                          </Pagination.Item>
                        ))}
                        <li
                          className="page-item page-indicator"
                          onClick={() =>
                            activePag.current + 1 < jobPagination.length &&
                            onClick(activePag.current + 1)
                          }
                        >
                          {/* <Link className="page-link" to="#"><i className="la la-angle-right" /></Link> */}
                          <Link className="paginate_button next" to="#">
                            {" "}
                            Siguiente
                          </Link>
                        </li>
                      </Pagination>
                    </div>
                  </div>
                </div>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Alldata;

const Filters = ({ handleSearchChange, filter }) => {
  return (
    <input
      type="text"
      value={filter}
      onChange={e => handleSearchChange(e)}
      className="form-control"
      placeholder="Busqueda Rapida"
    />
  );
};
