import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";
import { Link } from "react-router-dom";
import { Component } from "react";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    //this.mm("dispose");
    this.mm.dispose();
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={el => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}
function RoutesAnalista({ onClick, onClick3, path = "", handleShow }) {
  console.log(path)
  return (
    <PerfectScrollbar className="dlabnav-scroll">
      <MM className="metismenu" id="menu">
        <li
          className={`${[""].includes(path.toLowerCase()) ? "mm-active" : ""}`}
        >
          <Link className="ai-icon" to="/">
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.7728 0.5H2.77277C1.67277 0.5 0.772766 1.4 0.772766 2.5V16.5C0.772766 17.6 1.67277 18.5 2.77277 18.5H16.7728C17.8728 18.5 18.7728 17.6 18.7728 16.5V2.5C18.7728 1.4 17.8728 0.5 16.7728 0.5ZM2.77277 16.5V2.5H8.77277V16.5H2.77277ZM16.7728 16.5H10.7728V9.5H16.7728V16.5ZM16.7728 7.5H10.7728V2.5H16.7728V7.5Z"
                fill={[""].includes(path.toLowerCase()) ? "#526FCE" : "#797979"}
              />
            </svg>

            <span className="nav-text">Dashboard</span>
          </Link>
        </li>
        <li
          className={`${
            ["proveedores", "registrar-proveedor","operar-proveedor","editar-proveedor","consultar-proveedor"].includes(path.toLowerCase())
              ? "mm-active"
              : ""
          }`}
        >
          <Link className="has-arrow ai-icon" to="#">
            <svg
              width="21"
              height="23"
              viewBox="0 0 21 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.2204 8.86923L6.25531 3.69938"
                stroke={
                  ["proveedores", "registrar-proveedor"].includes(
                    path.toLowerCase()
                  )
                    ? "#526FCE"
                    : "#797979"
                }
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.7029 15.4436V7.47466C19.7025 7.1253 19.6103 6.78217 19.4355 6.4797C19.2606 6.17723 19.0093 5.92606 18.7068 5.75138L11.7339 1.76691C11.4311 1.59205 11.0875 1.5 10.7378 1.5C10.3881 1.5 10.0446 1.59205 9.74171 1.76691L2.76888 5.75138C2.46633 5.92606 2.21502 6.17723 2.04019 6.4797C1.86535 6.78217 1.77312 7.1253 1.77277 7.47466V15.4436C1.77312 15.793 1.86535 16.1361 2.04019 16.4386C2.21502 16.741 2.46633 16.9922 2.76888 17.1669L9.74171 21.1514C10.0446 21.3262 10.3881 21.4183 10.7378 21.4183C11.0875 21.4183 11.4311 21.3262 11.7339 21.1514L18.7068 17.1669C19.0093 16.9922 19.2606 16.741 19.4355 16.4386C19.6103 16.1361 19.7025 15.793 19.7029 15.4436Z"
                stroke={
                  ["proveedores", "registrar-proveedor"].includes(
                    path.toLowerCase()
                  )
                    ? "#526FCE"
                    : "#797979"
                }
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.04172 6.4387L10.7378 11.4691L19.4339 6.4387"
                stroke={
                  ["proveedores", "operar"].includes(
                    path.toLowerCase()
                  )
                    ? "#526FCE"
                    : "#797979"
                }
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.7378 21.5V11.4591"
                stroke={
                  ["proveedores", "registrar-proveedor"].includes(
                    path.toLowerCase()
                  )
                    ? "#526FCE"
                    : "#797979"
                }
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span
              className="nav-text"
              style={{
                color: ["proveedores", "registrar-proveedor"].includes(
                  path.toLowerCase()
                )
                  ? "#526FCE"
                  : "#797979"
              }}
            >
              Proveedores
            </span>
          </Link>
          <ul>
            <li>
              <Link
                className={`${path === "proveedores" ? "mm-active" : ""}`}
                onClick={() => onClick()}
                to="/proveedores"
              >
                Todos los proveedores
              </Link>
            </li>
           
          </ul>
        </li>

        <li
          className={`${
            ["cotizaciones", "nueva-cotizacion"].includes(path.toLowerCase())
              ? "mm-active"
              : ""
          }`}
        >
          <Link className="has-arrow ai-icon" to="#">
            <svg
              width="21"
              height="16"
              viewBox="0 0 21 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.7728 1.52588e-05H4.77279C3.66979 1.52588e-05 2.7728 0.897013 2.7728 2.00001V7H4.77279V4.00001L11.1728 8.8C11.3459 8.92966 11.5564 8.99975 11.7728 8.99975C11.9891 8.99975 12.1996 8.92966 12.3728 8.8L18.7728 4.00001V13H10.7728V15H18.7728C19.8758 15 20.7728 14.103 20.7728 13V2.00001C20.7728 0.897013 19.8758 1.52588e-05 18.7728 1.52588e-05ZM11.7728 6.75L5.43879 2.00001H18.1068L11.7728 6.75Z"
                fill={
                  ["cotizaciones", "nueva-cotizacion"].includes(
                    path.toLowerCase()
                  )
                    ? "#526FCE"
                    : "#797979"
                }
              />
              <path
                d="M0.772766 7.99998H7.77275V9.99998H0.772766V7.99998ZM2.77276 11H8.77275V13H2.77276V11ZM5.77276 14H9.77275V16H5.77276V14Z"
                fill={
                  ["cotizaciones", "nueva-cotizacion"].includes(
                    path.toLowerCase()
                  )
                    ? "#526FCE"
                    : "#797979"
                }
              />
            </svg>

            <span className="nav-text">Cotizaciones</span>
          </Link>
          <ul>
            <li>
              <Link
                className={`${path === "cotizaciones" ? "mm-active" : ""}`}
                onClick={() => onClick()}
             to="/cotizaciones"
              >
                Ver Cotizaciones
              </Link>
            </li>
            <li>
              <Link
                className={`${path === "nueva-cotizacion" ? "mm-active" : ""}`}
                onClick={() => onClick()}
             to="/cotizacion/new/nueva-cotizacion"
              >
                Nueva Cotización
              </Link>
            </li>
            {/* <li>
              <Link
                className={`${path === "nueva-cotizacion" ? "mm-active" : ""}`}
                onClick={() => onClick()}
              to="/nueva-cotizacion"
              >
                Crear cotización
              </Link>
            </li> */}
          </ul>
        </li>

           </MM>
  
   
      <div className="copyright">
        <p>
          <strong>Tecnoedil</strong> © 2021 Todos los derechos reservados
        </p>
        <p>Powered by InfiniteDev</p>
      </div>
    </PerfectScrollbar>
  );
}

export default RoutesAnalista;
