import React, { Fragment, useState, useRef, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Table, Pagination, Dropdown } from "react-bootstrap";
import data from "../../../Dashboard/Projects/Tabldata.js";
import { APICall } from "../../../../../API/index";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Select from "react-select";
import { Button, Row } from "react-bootstrap";
import ModalCot from "./ModalCot.js";
import GlobalContext from "../../../../../gContext";
const Alldata = () => {
  const sort = 10;
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [dataTableFull, setDataTableFull] = useState([]);
  const [cotizacionObj, setcotizacionObj] = useState({
    title: "",
    descripcion: "",
    fornecedores: []
  });
  const history = useHistory()
  const [canshowCot, setcanshowCot] = useState(false);
  const [Filter, setFilter] = useState("");
  const [filterCombos, setFilterCombos] = useState({});
  const [pais, setPais] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [departamento, setDepartamento] = useState("");
  const [rubro, setRubro] = useState("");
  const [segmento, setSegmento] = useState("");
  const gContext = useContext(GlobalContext);
  const [dataCotizacion, setDataCotizacion] = useState({
    count: 0,
    status: false,
    list: []
  });
  const [filterOptions, setFilterOptions] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  useEffect(() => {
    APICall.getProveedores().then(res => {
      
      setLoading(false);
      setDataTable(res.data);
      setDataTableFull(res.data);
    });
    APICall.getFilters().then(res => {
      let filterOptionsAux = {
        paises: [],
        ciudades: [],
        departamentos: [],
        rubros: [],
        segmentos: []
      };
      res.data.paises.forEach(pais => {
        filterOptionsAux.paises.push({
          value: pais,
          label: pais
        });
      });
      res.data.ciudades.forEach(ciudad => {
        filterOptionsAux.ciudades.push({
          value: ciudad,
          label: ciudad
        });
      });
      res.data.estados.forEach(estado => {
        filterOptionsAux.departamentos.push({
          value: estado,
          label: estado
        });
      });
      res.data.rubros.forEach(rubro => {
        filterOptionsAux.rubros.push({
          value: rubro,
          label: rubro
        });
      });
      res.data.segmentos.forEach(segmento => {
        filterOptionsAux.segmentos.push({
          value: segmento,
          label: segmento
        });
      });
      setFilterOptions(filterOptionsAux);
    });
  }, []);

  useEffect(() => {
    if (
      Filter == "" &&
      pais == "" &&
      ciudad == "" &&
      departamento == "" &&
      rubro == "" &&
      segmento == ""
    ) {
      let listtmp = [];
      setDataTable(dataTableFull);
      let countChecked = 0;
      let activateEnviarCotizacion = false;
      dataTableFull.forEach(e => {
        if (e.checked) {
          countChecked++;
          activateEnviarCotizacion = true;
          listtmp.push({ _id: e._id });
        }
      });
      setDataCotizacion({
        count: countChecked,
        status: activateEnviarCotizacion,
        list: listtmp
      });
    } else {
      let listToPush = [];

      for (let i = 0; i < dataTableFull.length; i++) {
        const element = dataTableFull[i];

        if (
          JSON.stringify(element)
            .toLowerCase()
            .includes(Filter.toLowerCase()) &&
          (element.pais == pais.value || !pais.value) &&
          (element.ciudad == ciudad.value || !ciudad.value) &&
          (element.estado == departamento.value || !departamento.value) &&
          (element.rubro.find(r => r == rubro.value) || !rubro.value) &&
          (element.segmento == segmento.value || !segmento.value)
        ) {
          listToPush.push(element);
        }
      }
      let countChecked = 0;
      let activateEnviarCotizacion = false;
      let listtmp = [];
      listToPush.forEach(e => {
        if (e.checked) {
          countChecked++;
          activateEnviarCotizacion = true;
          listtmp.push({ _id: e._id });
        }
      });
      setDataCotizacion({
        count: countChecked,
        status: activateEnviarCotizacion,
        list: listtmp
      });
      setDataTable(listToPush);
    }
  }, [Filter, pais, ciudad, departamento, rubro, segmento]);

  const columns = [
    "",
    "Nombre Real",
    "Nombre Fantasia",
    "RUC / CNPJ /  ID",
    "Tipo de actividad",
    "Fecha de registro",
    "Correo",
    "Acciones"
  ];
 
  useEffect(() => {
    onClick(0);
  }, [dataTable]);

  let jobPagination = Array(Math.ceil(dataTable.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const activePag = useRef(0);
  const jobData = useRef(
    dataTable.slice(activePag.current * sort, (activePag.current + 1) * sort)
  );
  const [demo, setdemo] = useState();
  const onClick = i => {
    activePag.current = i;

    jobData.current = dataTable.slice(
      activePag.current * sort,
      (activePag.current + 1) * sort
    );
    setdemo(
      dataTable.slice(activePag.current * sort, (activePag.current + 1) * sort)
    );
  };

  const handleChangeCot = (cot, type) => {
    let aux = cotizacionObj;
    aux[type] = cot;
    setcotizacionObj(aux);
  };

  const handleCreateCot = () => {
    let cotobj = cotizacionObj;
    cotobj.fornecedores = dataCotizacion.list;
   


    swal({
      title: "Desea enviar pedido de Cotización?",
      text: dataCotizacion.list.length +
        " Proveedores recibirán el mail!",
      icon: "info",
      buttons: true,
      dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        APICall.crearCotizacion(cotobj)
          .then(() => {
            toast.success("Cotización enviada con exito");
            setcanshowCot(false);
            history.push("/cotizaciones")
          })
          .catch(e => {
            toast.error("Hubo un error mientras intentamos enviar la cotización");
          });
      }
    })




  };
  const handleDelete = id => {
    swal({
      title: "Desea borrar este proveedor?",
      text: "Esta operación no podrá ser cancelada. Los datos serán borrados de la base!",
      icon: "warning",

      dangerMode: true,
      buttons: ["VOLVER", "BORRAR"]
    }).then(willDelete => {
      if (willDelete) {
        setLoading(true);
        APICall.deleteProveedor(id)
          .then(() => {
            APICall.getProveedores().then(res => {
             
              setLoading(false);
              setDataTable(res.data);
              setDataTableFull(res.data);
              toast.success("Proveedor borrado con exito");
            });
          })
          .catch(e => {
            toast.error("No pudimos borrar el proveedor");
          });
      }
    });
  };

  const handleSearchChange = e => {
    setFilter(e.target.value);
  };

  const chackbox = document.querySelectorAll(".check-input input");
  const motherChackBox = document.querySelector(".check-input-all input");

  const checkBoxTypeElement = (i, type, e) => {
    let dataTableAux = [...dataTable];
    let activateEnviarCotizacion = false;
    let countChecked = 0;
    if (type === "all") {
      setCheckedAll(e.target.checked);
      for (let i = 0; i < dataTableAux.length; i++) {
        dataTableAux[i].checked = e.target.checked;
      }
    } else {
      dataTableAux[i].checked = !dataTableAux[i].checked;
    }
    let listtmp = [];
    dataTableAux.forEach(e => {
      if (e.checked) {
        countChecked++;
        activateEnviarCotizacion = true;
        listtmp.push({ _id: e._id });
      }
    });
    setDataCotizacion({
      count: countChecked,
      status: activateEnviarCotizacion,
      list: listtmp
    });
    setDataTable(dataTableAux);
  };

  const filterChange = (e, type) => {
    let filterCombosAux = { ...filterCombos };

    switch (type) {
      case "rubro":
        e ? setRubro(e) : setRubro("");
        break;
      case "segmento":
        e ? setSegmento(e) : setSegmento("");
        break;
      case "pais":
        e ? setPais(e) : setPais("");
        break;
      case "departamento":
        e ? setDepartamento(e) : setDepartamento("");
        break;
      case "ciudad":
        e ? setCiudad(e) : setCiudad("");
        break;
    }

    /*console.log(type);
    if (e) {
      filterCombosAux[type] = e.value;
      setFilterCombos(filterCombosAux);
      console.log(filterCombosAux);
    }
    else {
      filterCombosAux[type] = "";
      setFilterCombos(filterCombosAux);
    } */
  };

  const cleanFilters = () => {
    setPais("");
    setCiudad("");
    setDepartamento("");
    setRubro("");
    setSegmento("");
    setFilter("");
  };

  return (
    <Fragment>
      <div className="row">
        <div className=" form-group col-sm-12 col-lg-3">
        <label>Todos los campos:</label>
          {
            <Filters
              handleSearchChange={handleSearchChange}
              filter={Filter}
            ></Filters>
          }
        </div>
        <div className="form-group col-12 col-lg-6">
          <label>Rubro</label>
          <Select
            placeholder="Todos"
            isClearable={true}
            isSearchable={true}
            value={rubro}
            onChange={e => {
              filterChange(e, "rubro");
            }}
            options={filterOptions.rubros}
            name="rubro"
          />
        </div>
        <div className="form-group col-12 col-lg-3">
          <label>Segmento</label>
          <Select
            placeholder="Todos"
            isClearable={true}
            isSearchable={true}
            value={segmento}
            options={filterOptions.segmentos}
            onChange={e => {
              filterChange(e, "segmento");
            }}
            name="color"
          />
        </div>
        <div className="form-group  col-12 col-lg-2">
          <label>Pais</label>
          <Select
            placeholder="Todos"
            isClearable={true}
            isSearchable={true}
            value={pais}
            options={filterOptions.paises}
            onChange={e => {
              filterChange(e, "pais");
            }}
            name="color"
          />
        </div>

        <div className="form-group col-12 col-lg-3">
          <label>Departamento</label>
          <Select
            placeholder="Todos"
            isClearable={true}
            isSearchable={true}
            value={departamento}
            options={filterOptions.departamentos}
            onChange={e => {
              filterChange(e, "departamento");
            }}
            name="color"
          />
        </div>
        <div className="form-group col-12 col-lg-3">
          <label>Ciudad</label>
          <Select
            placeholder="Todos"
            isClearable={true}
            isSearchable={true}
            value={ciudad}
            options={filterOptions.ciudades}
            onChange={e => {
              filterChange(e, "ciudad");
            }}
            name="color"
          />
        </div>
        <div
          className="form-group col-12 col-lg-3"
          style={{ display: "flex", alignItems: "flex-end", marginRight: 10 }}
        >
          <Button
            variant="primary"
            size={"sm"}
            onClick={() => cleanFilters()}
            style={{ display: "flex", alignItems: "center" }}
          >
            <i class="las la-broom" style={{ fontSize: "1.2rem" }}></i>
            <span style={{ paddingLeft: 10 }}>Limpiar Filtro</span>
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="navpills-1"
              role="tabpanel"
            >
              <Table
                responsive
                className="table-responsive card-table rounded table-hover fs-14"
              >
                <div
                  style={{
                    display: "flex",
                    padding: "15px",
                    backgroundColor: "white"
                  }}
                >
                  <div
                    className="col-3"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <h2 style={{ fontWeight: "bold", color: "black" }}>
                      {/* {jobData.current.length + "  "} */}
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Proveedor{jobData.current.length == 1 ? "" : "es"}
                      </strong>
                    </h2>
                  </div>
                  <div
                    className="col-5"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {dataCotizacion.status && false && (
                      <Button
                        variant="primary"
                        style={{ display: "flex", alignItems: "center" }}
                        size={"sm"}
                        onClick={() => setcanshowCot(true)}
                      >
                        <i
                          class="las la-paper-plane"
                          style={{ fontSize: "1.2rem" }}
                        ></i>
                        <span style={{ paddingLeft: 10, color: "white" }}>
                          Enviar cotización: {dataCotizacion.count} Proveedor
                          {dataCotizacion.count == 1 ? "" : "es"}
                        </span>
                      </Button>
                    )}
                  </div>

                </div>

                <div id="example_wrapper" className="dataTables_wrapper">
                  <table
                    id="example"
                    className="table border-no display mb-4 dataTablesCard table-responsive-xl project-bx"

                  >
                    <thead >
                      <tr role="row">
                        {columns.map((d, i) => (
                          <th key={i} className="check-input-all">
                            {i === 0 ? (
                              <div
                                className="custom-control custom-checkbox"
                                style={{ zIndex: 0 }}
                              >
                                <input
                                  type="checkbox"
                                  onClick={e =>
                                    checkBoxTypeElement(0, "all", e)
                                  }
                                  className="custom-control-input"
                                  id="checkAll"
                                  value={checkedAll}
                                  style={{ zIndex: -1 }}
                                  required
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="checkAll"
                                />
                              </div>
                            ) : (
                              d
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody >
                      {!loading &&
                        jobData.current.map((d, i) => (
                          <tr key={i} >
                            <td  >
                              <div
                                className="custom-control custom-checkbox "
                                style={{ zIndex: 0 }}
                              >
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`checkAll${i}`}
                                  style={{ zIndex: -1 }}
                                  required
                                  checked={d.checked}
                                  onClick={() =>
                                    checkBoxTypeElement(i, "single")
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`checkAll${i}`}
                                />
                              </div>
                            </td>
                            <td style={{ cursor: "pointer" }} onClick={() => { history.push(`/operar-proveedor/${d._id}/consultar-proveedor`) }}>
                              <div className="d-flex align-items-center">
                                <div>
                                  <span className="text-black font-w600 text-nowrap">
                                    {d.razonSocial || "No Tiene"}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td style={{ cursor: "pointer" }} onClick={() => { history.push(`/operar-proveedor/${d._id}/consultar-proveedor`) }}>
                              <div className="d-flex align-items-center">
                                <div>
                                  <span className="text-black font-w600 text-nowrap">
                                    {d.nombreFantasia || "No Tiene"}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td style={{ cursor: "pointer" }} onClick={() => { history.push(`/operar-proveedor/${d._id}/consultar-proveedor`) }}>
                              <div className="d-flex align-items-center">
                                <div>
                                  <span className="text-black font-w600 text-nowrap">
                                    {d.registro || "NO TIENE"}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td style={{ cursor: "pointer" }} onClick={() => { history.push(`/operar-proveedor/${d._id}/consultar-proveedor`) }}>
                              <div className="d-flex align-items-center">
                                <div>
                                  <span className="text-black font-w600 text-nowrap">
                                    {d.actividad || "NO TIENE"}
                                  </span>
                                </div>
                              </div>
                            </td>

                            <td style={{ cursor: "pointer" }} onClick={() => { history.push(`/operar-proveedor/${d._id}/consultar-proveedor`) }}>
                              <div className="d-flex align-items-center">
                                <div>
                                  <span className="text-black font-w600 text-nowrap">
                                  
                                    { new Date(d.createdAt).toLocaleString() || "NO TIENE"}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td style={{ cursor: "pointer" }} onClick={() => { history.push(`/operar-proveedor/${d._id}/consultar-proveedor`) }}>
                              <div className="d-flex align-items-center">
                                <div>
        
                                  <span className="text-black font-w600 text-nowrap">
                                    {d.emailEjecutivo || d.gmail}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td >
 
                              <Link
                                to={`/operar-proveedor/${d._id}/consultar-proveedor`}
                              >
                                <i
                                  className="fa fa-eye mr-3"
                                  style={{ fontSize: "1.5rem" }}
                                ></i>
                              </Link>
                              {gContext.user.role == "analista" ?  <Link
                                to={`/operar-proveedor/${d._id}/editar-proveedor`}
                              >
                                <i
                                  className="fa fa-edit mr-3"
                                  style={{ fontSize: "1.5rem" }}
                                ></i>
                              </Link> : ""}
                              {gContext.user.role == "analista" ? "" : <Link>
                                <i
                                  className="fa fa-trash-o"
                                  onClick={() => handleDelete(d._id)}
                                  style={{ fontSize: "1.5rem" }}
                                >
                                  {" "}
                                </i>
                              </Link>}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {loading && (
                    <div
                      style={{
                        display: "flex",
                        flex: "1",
                        justifyContent: "center"
                      }}
                    >
                      <div
                        className="spinner-border"
                        style={{ height: "200px", width: "200px" }}
                      ></div>
                    </div>
                  )}
                  <div className="d-sm-flex justify-content-between  align-items-center mt-3">
                    <div className="dataTables_info">
                      {/* Total de {data.patientTable.data.length} elementos */}
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers">
                      <Pagination size="lg">
                        <li
                          className="page-item page-indicator "
                          onClick={() =>
                            activePag.current > 0 &&
                            onClick(activePag.current - 1)
                          }
                        >
                          {/* <Link className="page-link" to="#">  <i className="la la-angle-left" /></Link> */}
                          <Link className="paginate_button previous" to="#">
                            {" "}
                            Volver
                          </Link>
                        </li>
                        {jobPagination.map((number, i) => (
                          <Pagination.Item
                            className={activePag.current === i ? "active" : ""}
                            onClick={() => onClick(i)}
                          >
                            <span style={{ color: "black" }}>{number}</span>
                          </Pagination.Item>
                        ))}
                        <li
                          className="page-item page-indicator"
                          onClick={() =>
                            activePag.current + 1 < jobPagination.length &&
                            onClick(activePag.current + 1)
                          }
                        >
                          {/* <Link className="page-link" to="#"><i className="la la-angle-right" /></Link> */}
                          <Link className="paginate_button next" to="#">
                            {" "}
                            Siguiente
                          </Link>
                        </li>
                      </Pagination>
                    </div>
                  </div>
                </div>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <ModalCot
        handleChangeCot={handleChangeCot}
        showCot={canshowCot}
        handleClose={() => setcanshowCot(false)}
        list={dataCotizacion.count}
        createCot={handleCreateCot}
      ></ModalCot>
    </Fragment>
  );
};

export default Alldata;

const Filters = ({ handleSearchChange, filter }) => {
  return (
    <input
      type="text"
      value={filter}
      onChange={e => handleSearchChange(e)}
      className="form-control"
      style={{height:"55%",borderRadius:4,borderColor:"#CCCCCC"}}
      placeholder="Busqueda Rapida"
    />
  );
};
