import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../images/imagem-banner.png";
import logoSol from "../../images/tecnoedil.png";
import backGround from "../../images/banner-2.jpg";
import { GoogleLogin } from "react-google-login";
import { APICall } from "../../API";
import GlobalContext from "../../gContext";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import WrapperHeader from "../wrapperHeader";
import ReactCodeInput from "react-verification-code-input";

const Recuperar = () => {
  const [IsLoaded, setIsLoaded] = useState(false);
  const [OAuthSms, setOAuthSms] = useState("");
  const [Tel, setTel] = useState("");
  const gContext = useContext(GlobalContext);

  const history = useHistory();

  const login = ( email) => {
    setIsLoaded(false);
    APICall.resetPass( email)
      .then(res => {
        toast.success("Si ingresaste un mail válido, te enviaremos un link para recurar la clave!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose:()=>history.push("/page-login")
        });

       
      })
      .catch(() => {
        toast.error("❌ No se pudo recuperar clave!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });
  };

  
  const handleLogin = e => {
    e.preventDefault();

    let email = e.target.email.value;
 

    login(email);
  };
  return (
    <div className="">
      <div className="container-fluid h-custom">
        <div
          className="row d-flex justify-content-start align-items-center h-30"
          style={{
            backgroundColor: "#526FCE",
            boxShadow: "0px 5px 5px -2px rgba(0,0,0,0.21)"
          }}
        >
          <img
            src={logoSol}
            className="img-fluid"
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              marginLeft: "10%"
            }}
            width={"280vw"}
            alt="Logo"
          />
        </div>

        <div className="row d-flex flex-row-reverse justify-content-center align-items-center h-100">
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1 mt-5 pt-3 "style={{marginLeft: 0}}>
            <form onSubmit={handleLogin}>
              <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                <h2
                  classNameName="mb-5 "
                  style={{ textAlign: "center", width: "100%" }}
                >
                  Recuperar Contraseña <br />
                  Hacé login para empezar!
                </h2>
                <div>
                  <ToastContainer />
                </div>
              </div>

              {true && (
                <>
                  <div className="form-outline mb-4">
                    <label className="form-label" for="email">
                      Correo Electrónico.
                    </label>
                    <input
                      type="text"
                      id="email"
                      className="form-control form-control-lg"
                      placeholder="Escribí tu mail"
                    />
                  </div>

                 
                  <div className="text-center text-lg-start mt-4 pt-2">
                    <button
                      type="submit"
                      className="btn btn-primary btn-lg"
                      style={{
                        paddingLeft: "2.5rem",
                        paddingRight: "2.5rem",
                        width: "100%"
                      }}
                    >
                      Recuperar Clave
                    </button>
                    {/* 
                    <div className="divider d-flex align-items-center my-4">
                      <p className="text-center fw-bold mx-3 mb-0">Redes Sociales</p>
                    </div>

                    <GoogleLogin
                      responseType={"permission"}
                      clientId="906329086825-v28d77s9nd38caj06lt82n1k1qf8vr01.apps.googleusercontent.com"
                      buttonText="Login Google"
                      cookiePolicy={"single_host_origin"}
                      fetchBasicProfile={true}
                      onSuccess={responseGoogle}
                      onFailure={e => console.log("ERRO", e)}
                    /> */}
                  </div>
                </>
              )}
             
            </form>
          </div>
        </div>
      </div>

      <div
        style={{
          bottom: "0",
          position: "fixed",
          height: "40px",
          backgroundColor: "white",
          display: ""
        }}
      ></div>
    </div>
  );
};

export default Recuperar;
