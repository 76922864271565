const roles = {

    "analista": [
        {
            permission: "Dashboard",
            value: true
        },
        {
            permission: "Visualizar Proveedores",
            value: true
        },
        {
            permission: "Editar Proveedores",
            value: true
        },
        {
            permission: "Borrar Proveedores",
            value: false
        },
        {
            permission: "Crear nuevos Usuarios",
            value: false
        },
        {
            permission: "Editar Usuarios",
            value: false
        },
        {
            permission: "Borrar Usuarios",
            value: false
        },
        {
            permission: "Pedir Cotización",
            value: true
        },
        {
            permission: "Histórico de Pedidos de Cotización",
            value: true
        }

    ],
    "gerente": [
        {
            permission: "Dashboard",
            value: true
        },
        {
            permission: "Visualizar Proveedores",
            value: true
        },
        {
            permission: "Editar Proveedores",
            value: true
        },
        {
            permission: "Borrar Proveedores",
            value: true
        },
        {
            permission: "Crear nuevos Usuarios",
            value: true
        },
        {
            permission: "Editar Usuarios",
            value: true
        },
        {
            permission: "Borrar Usuarios",
            value: true
        },
        {
            permission: "Pedir Cotización",
            value: true
        },
        {
            permission: "Histórico de Pedidos de Cotización",
            value: true
        }

    ],

}

export default {
    roles
};

