import React, { useEffect } from "react";
import { Button, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { Col } from "reactstrap";
import { APICall } from "../../../../API";
import GlobalContext from "../../../../gContext";
import WrapperHeader from "../../../wrapperHeader";
import RenderForm from "./RenderForm";

export default function CrudUsuario() {
  const history = useHistory();
  const { id, operacion } = useParams();
  const gContext = React.useContext(GlobalContext);
  const [ProveedorOriginal, setProveedorOriginal] = React.useState({});
  const [parentProveedor,setParentProveedor]=React.useState({});
  const [lastId,setlastId]=React.useState(id);
  useEffect(() => {
if(lastId !=id){
  gContext.setIsLoading(true);
  window.location.reload()
}
    setlastId(id)
    //
    if (id != "new") {
      gContext.setIsLoading(true);
      APICall.getUserById(id)
        .then(res => {

          setProveedorOriginal(res);
       
        })
        .finally(() => {
          gContext.setIsLoading(false);
        });
    }
  }, [id]);

  const registerProveedor=((p)=>{
    APICall.registerUsuario(p).then(()=>{
      toast.success("usuario creado con exito")
      history.push("/usuarios");

    }).catch(e=>{
      toast.error("Hubo un error mientras intentamos crear el usuario")

    })
  });

  const guardarEditProveedor=((p)=>{
    APICall.editUser(p).then(()=>{
      toast.success("usuario editado con exito")
      history.push("/operar-usuario/"+ProveedorOriginal._id+"/consultar-usuario");

    }).catch(e=>{
      toast.error("Hubo un error mientras intentamos editar el usuario")

    })
  })
  
  const editarProveedor=(()=>{
    history.push("/operar-usuario/"+ProveedorOriginal._id+"/editar-usuario");
  })

  const deleteUserA=((p)=>{
    APICall.deleteUser(p).then(()=>{
      toast.success("usuario eliminado con exito")
     // history.push("/operar-usuario/"+ProveedorOriginal._id+"/usuarios");
      history.push("/usuarios")
    }).catch(e=>{
      toast.error("Hubo un error mientras intentamos eliminar el usuario")

    })
  })
  
  const deleteUserH=(()=>{
    history.push("/operar-usuario/"+ProveedorOriginal._id+"/eliminar-usuario");
  })



  return (
    <WrapperHeader roles={["admin","gerente"]}>
      <Row className="m-lg-5 m-sm-0 m-1">
        <Col lg={12}>
          <Row>
            <Col lg={8} md={6} sm={12}>
              <h2 style={{ margin: 0 }}>{operacion.includes("editar") ? "Editando: " : ""} {ProveedorOriginal.name}</h2>
              <h2 style={{ margin: 0 }}>{operacion.includes("registrar") ? "Creando Usuario " : ""} </h2>
              <h2 style={{ margin: 0 }}>{operacion.includes("eliminar") ? "Eliminar Usuario " : ""}  </h2>
              <p>{ProveedorOriginal.razonSocial}</p>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <Row style={{ justifyContent: "flex-end" }}>
                <Button
                  variant="outline-primary badge-rounded pr-4"
                  onClick={() => history.push("/usuarios")}
                  size={"sm"}
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    display: "flex",
                    marginRight: 10
                  }}
                >
                  <svg
                    width="12"
                    height="15"
                    viewBox="0 0 12 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.0807 1.03909C10.9785 0.985891 10.8672 0.959291 10.7559 0.959291C10.6159 0.959291 10.4773 1.00129 10.3576 1.08389L1.25757 7.38389C1.06857 7.51479 0.955872 7.72969 0.955872 7.95929C0.955872 8.18889 1.06857 8.40379 1.25757 8.53469L10.3576 14.8347C10.5711 14.9824 10.8504 14.9999 11.0807 14.8795C11.3117 14.7584 11.4559 14.5197 11.4559 14.2593L11.4559 1.65929C11.4559 1.39889 11.3117 1.16019 11.0807 1.03909Z"
                      fill="#526FCE"
                    />
                  </svg>
                  <span style={{ paddingLeft: 10 }}>Volver para la lista</span>
                </Button >

                {operacion.includes("consultar") && 
                
                <Button variant="primary" size={"sm"} onClick={()=>{editarProveedor()}}>
                  <i class="las la-edit" style={{ fontSize: "1.2rem" }}></i>
                  <span style={{ paddingLeft: 10 }}>Editar Usuario</span>
                </Button>
                
                }
                
                {
                  operacion.includes("editar") && 
                  <Button variant="primary" size={"sm"} onClick={()=>guardarEditProveedor(parentProveedor)}>
                    <svg
                      width="15"
                      height="10"
                      viewBox="0 0 15 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.76446 7.45706L1.56604 4.496L0.192657 5.76747L4.76446 10L14.1927 1.27147L12.8193 0L4.76446 7.45706Z"
                        fill="white"
                      />
                    </svg>
                    <span style={{ paddingLeft: 10 }}>Guardar Cambios</span>
                  </Button>
                }
  {
                  operacion.includes("eliminar") && 
                  <Button variant="primary" size={"sm"} onClick={()=>deleteUserA(parentProveedor._id)}>
                    <svg
                      width="15"
                      height="10"
                      viewBox="0 0 15 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.76446 7.45706L1.56604 4.496L0.192657 5.76747L4.76446 10L14.1927 1.27147L12.8193 0L4.76446 7.45706Z"
                        fill="white"
                      />
                    </svg>
                    <span style={{ paddingLeft: 10 }}>Eliminar Usuario</span>
                  </Button>
                }
                
              </Row>
            </Col>
          </Row>
        </Col>
        <Col lg={12} style={{ padding: 0, marginTop: 10 }}>
          <RenderForm proveedorBack={ProveedorOriginal} parentProveedor={setParentProveedor} registerProveedor={registerProveedor} guardarEditProveedor={guardarEditProveedor} editarProveedor={editarProveedor} deleteUserA={deleteUserA}></RenderForm>
        </Col>
      </Row>
    </WrapperHeader>
  );
}
