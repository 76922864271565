import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, ProgressBar, Tab, Nav } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
//** Import Image */

import ApexchartsNegPost from "./ApexNagetivePosative";
import ApexchartsNegPost1 from "./ApexNagetivePosative1";
import Radialchart from "./Radialchart";
import Linechart from "./Linechart";
import Pie from "../../../components/charts/Chartjs/pie";

import WrapperHeader from "../../../wrapperHeader";
import { APICall } from "../../../../API";

const tabData = [
  {
    name: "Home",
    percenet: "0,45%",
    content: <ApexchartsNegPost />
  },
  {
    name: "Profile",
    percenet: "5,75%",
    content: <ApexchartsNegPost1 />
  },
  {
    name: "Contact",
    percenet: "1,20%",
    content: <ApexchartsNegPost />
  },

  {
    name: "Message",
    percenet: "0,45%",
    content: <ApexchartsNegPost />
  }
];

const Home = () => {
  const history = useHistory();
  const [proveedores, setProveedores] = useState([]);
  const [cotizaciones, setcotizaciones] = useState([]);
  const [pieChartData, setPieChartData] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    APICall.verify()
      .then(res => {
    

        if (res.user.role == "proveedor") {
          history.push("/perfil-proveedor");
        }
        setisLoading(false);
      })
      .catch(e => {
        setisLoading(false);
      });

    APICall.getProveedores()
      .then(p => {
     
        setPieChartData(
          p.data.reduce(
            (acc, o) => ((acc[o.segmento] = (acc[o.segmento] || 0) + 1), acc),
            {}
          )
        );

        setProveedores(p.data);
      })
      .catch(() => {})
      .finally(() => {});

    APICall.getCotizaciones()
      .then(p => {
        setcotizaciones(p.data);
      })
      .catch(() => {})
      .finally(() => {});
  }, []);

  return (
    <WrapperHeader>
      <Fragment>
        {/* <!-- Add Order --> */}
       {!isLoading && ( <><div className="modal fade" id="addOrderModalside">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create Project</h5>
                <button type="button" className="close" data-dismiss="modal">
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label className="text-black font-w500">Project Name</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label className="text-black font-w500">Deadline</label>
                    <input type="date" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label className="text-black font-w500">Client Name</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="form-group">
                    <button type="button" className="btn btn-primary">
                      Criar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body mr-3">
                        <h2 className="num-text text-black font-w600">
                          {proveedores.length}
                        </h2>
                        <span className="fs-14 ">Proveedores Registrados</span>
                      </div>
                      <svg
                        width="39"
                        height="42"
                        viewBox="0 0 39 42"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.04367 5.18998C9.5692 4.27866 10.734 3.96592 11.6453 4.49144L28.7218 14.3389C29.6331 14.8644 29.9458 16.0292 29.4203 16.9405C28.8948 17.8518 27.73 18.1646 26.8187 17.639L9.74221 7.7916C8.83089 7.26607 8.51815 6.10129 9.04367 5.18998Z"
                          fill="#526FCE"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.3863 0.808641C17.2517 0.30993 18.2331 0.0474014 19.232 0.0474014C20.2309 0.0474014 21.2122 0.309908 22.0776 0.80858C22.079 0.809385 22.0804 0.81019 22.0818 0.810997L35.3635 8.40052C36.2291 8.90028 36.948 9.61886 37.4482 10.4842C37.9484 11.3495 38.2122 12.3312 38.2133 13.3307V28.5137C38.2122 29.5131 37.9484 30.4948 37.4482 31.3601C36.948 32.2254 36.2291 32.944 35.3635 33.4438L35.3561 33.448L22.0818 41.0333C22.0803 41.0342 22.0788 41.035 22.0774 41.0358C21.212 41.5344 20.2308 41.7969 19.232 41.7969C18.2332 41.7969 17.2519 41.5344 16.3865 41.0358C16.3851 41.035 16.3836 41.0341 16.3822 41.0333L3.10786 33.448L3.10051 33.4438C2.23493 32.944 1.51598 32.2254 1.01579 31.3601C0.515599 30.4948 0.251758 29.5131 0.250732 28.5137V13.3307C0.251758 12.3312 0.515599 11.3495 1.01579 10.4842C1.51598 9.61886 2.23493 8.90028 3.10051 8.40054L3.10785 8.3963L16.3863 0.808641ZM19.232 3.85696C18.9002 3.85696 18.5743 3.94429 18.287 4.11017L18.2796 4.11442L5.00529 11.6997C5.00425 11.7003 5.00321 11.7009 5.00217 11.7015C4.71653 11.8672 4.47925 12.1048 4.31399 12.3907C4.14826 12.6774 4.06078 13.0026 4.06029 13.3337V28.5106C4.06078 28.8417 4.14826 29.1669 4.31399 29.4536C4.47927 29.7396 4.71659 29.9772 5.00228 30.1428C5.00328 30.1434 5.00428 30.144 5.00529 30.1446L18.287 37.7341C18.5743 37.9 18.9002 37.9873 19.232 37.9873C19.5638 37.9873 19.8897 37.9 20.177 37.7341L20.1843 37.7299L33.4587 30.1446C33.4597 30.144 33.4607 30.1434 33.4617 30.1428C33.7474 29.9772 33.9847 29.7396 34.15 29.4536C34.3159 29.1667 34.4034 28.8412 34.4037 28.5097V13.3346C34.4034 13.0031 34.3159 12.6776 34.15 12.3907C33.9847 12.1048 33.7475 11.8672 33.4618 11.7015C33.4608 11.7009 33.4597 11.7003 33.4587 11.6997L20.177 4.11019C19.8897 3.94431 19.5638 3.85696 19.232 3.85696Z"
                          fill="#526FCE"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.01902 10.4056C1.54577 9.49496 2.71097 9.18379 3.62158 9.71054L19.232 18.7406L34.8424 9.71054C35.753 9.18379 36.9182 9.49496 37.445 10.4056C37.9717 11.3162 37.6605 12.4814 36.7499 13.0081L20.1858 22.5899C19.5957 22.9312 18.8683 22.9312 18.2782 22.5899L1.71404 13.0081C0.803437 12.4814 0.492264 11.3162 1.01902 10.4056Z"
                          fill="#526FCE"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M19.232 19.0174C20.284 19.0174 21.1368 19.8702 21.1368 20.9222V40.0478C21.1368 41.0998 20.284 41.9526 19.232 41.9526C18.18 41.9526 17.3272 41.0998 17.3272 40.0478V20.9222C17.3272 19.8702 18.18 19.0174 19.232 19.0174Z"
                          fill="#526FCE"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body mr-3">
                        <h2 className="num-text text-black font-w600">
                          {cotizaciones.length}
                        </h2>
                        <span className="fs-14">Cotizaciones Enviadas</span>
                      </div>
                      <svg
                        width="39"
                        height="32"
                        viewBox="0 0 39 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M34.5272 0.800049H7.92727C5.83158 0.800049 4.12728 2.50434 4.12728 4.60004V14.1H7.92727V8.40003L20.0872 17.52C20.4163 17.7664 20.8162 17.8995 21.2272 17.8995C21.6383 17.8995 22.0382 17.7664 22.3672 17.52L34.5272 8.40003V25.5H19.3272V29.3H34.5272C36.6229 29.3 38.3272 27.5957 38.3272 25.5V4.60004C38.3272 2.50434 36.6229 0.800049 34.5272 0.800049ZM21.2272 13.625L9.19267 4.60004H33.2618L21.2272 13.625Z"
                          fill="#526FCE"
                        />
                        <path
                          d="M0.327209 16H13.6272V19.8H0.327209V16ZM4.1272 21.7H15.5272V25.5H4.1272V21.7ZM9.82719 27.4H17.4272V31.2H9.82719V27.4Z"
                          fill="#526FCE"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-12">
                <div className="widget-stat card bg-primary">
                  <div className="card-header border-0 pb-0">
                    <h3 className="card-title text-black">
                      Proveedor por categoria
                    </h3>
                  </div>
                  <div className="card-body text-center" style={{ zoom: 1.2 }}>
                    <div className="ico-sparkline">
                      <div id="sparkline12">
                        {pieChartData && (
                          <Pie
                            pieData={pieChartData}
                            color1="#7099ED"
                            color2="#B3CCFF"
                            color3="#9DBDFF"
                            height="220"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-8">
            <div className="row">
              <div className="col-12 ">
                <TableProveedores proveedores={proveedores}history={history}></TableProveedores>
              </div>
            </div>
          </div>
        </div>
        </>)}
      </Fragment>
    </WrapperHeader>
  );
};

export default Home;

const TableProveedores = ({ proveedores, history }) => {
  const proveedoresDataReverse = [];
  // Se realiza un bucle para ordenar de forma descendente.
  for (let i = proveedores.length - 1; i >= 0; i--) {
    proveedoresDataReverse.push(proveedores[i]);
  }

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Ultimos Proveedores Registrados</h4>
      </div>
      <div className="card-body" style={{ paddingTop: 0 }}>
        <div
          className="table-responsive recentOrderTable"
          style={{ padding: 0 }}
        >
          <table
            className="table verticle-middle table-responsive-md"
            style={{ padding: 0 }}
          >
            <thead>
              <tr>
                <th scope="col" style={{ textAlign: "left", paddingLeft: 0 }}>
                  ID
                </th>
                <th scope="col">Nombre</th>
                <th scope="col">Categoría</th>
                <th scope="col">Ubicación</th>
                <th scope="col">Creación</th>

                <th
                  scope="col"
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  Ver Detalle
                </th>
              </tr>
            </thead>
            <tbody>
              {proveedoresDataReverse.slice(0, 7).map((proveedor) => (
                <tr
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push(
                      `/operar-proveedor/${proveedor._id}/consultar-proveedor`
                    );
                  }}
                >
                  <td
                    style={{
                      textAlign: "left",
                      paddingLeft: 0,
                      color: "black",
                    }}
                  >
                    {proveedor.registro}
                  </td>
                  <td style={{ color: "black", fontWeight: "bold" }}>
                    {proveedor.razonSocial}
                  </td>
                  <td style={{ color: "black" }}>{proveedor.segmento}</td>
                  <td style={{ color: "black" }}>{proveedor.ciudad}</td>

                  <td style={{ color: "black" }}>
                    {new Date(proveedor.createdAt).toLocaleString()}
                  </td>
                  <td>
                    <Link
                      to={`/operar-proveedor/${proveedor._id}/consultar-proveedor`}
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <i
                        className="fa fa-eye mr-3"
                        style={{ fontSize: "1.5rem", color: "#526FCE" }}
                      ></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
