import { Card, Step, StepLabel, Stepper, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Row, Table, Col, Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router";
import { APICall } from "../../API";
import GlobalContext from "../../gContext";
import swal from "sweetalert";
import swalMessage from "@sweetalert/with-react";
import { toast } from "react-toastify";
import dropdown from "../../dropdownData";
/// React router dom
import { Link } from "react-router-dom";
import ViewDataTable from "../components/ViewDataTable";
import IMGBG from "../components/ViewDataTable/img.png";
/// images
import logo from "../../images/logoE.png";
import logoText from "../../images/logo-textE.png";
import Radios from "../components/Radios";
import Select from "react-select";
function RegisterProveedor(props) {
  const history = useHistory();
  const [Proveedor, setProveedor] = React.useState({
    email: "",
    telephone: "",
    pais: "Paraguay",
    tipoProveedor: "S.A.",
    registro: "",
    representanteLegal: "",
    documentoIdentidad: "",
    nombreEjecutivo: "",
    telefonoEjecutivo: "",
    emailEjecutivo: "",
    puesto: "",
    razonSocial: "",
    nombreFantasia: "",
    actividad: "Bienes y Servicios",

    ciudad: "",
    calle: "",
    website: "",
    segmento: "OTROS",
    rubro: "",
    especialidad:"",
    estado: ""
  });
  //CAMPOS OBLIGATORIOS
  const keysByStep = {
    0: ["pais", "registro", "tipoProveedor", "razonSocial", "actividad"],
    1: ["nombreEjecutivo", "telefonoEjecutivo", "emailEjecutivo","documentoIdentidad"],
    2: ["ciudad", "telephone", "calle"],
    3: ["segmento", "rubro"],
    4: [""]
  };
  //CAMPOS QUE TIENEN VALIDACION
  const keysByStepValidation = {
    0: ["capitalSocial", "cantidadEmpleados", "inicioActividad"],
    1: ["emailEjecutivo", "celularRepresentante", "telefonoEjecutivo"],
    2: ["telephone","telephone2","website", "email"],
    3: [],
    4: []
  };

  const mandatoryKeys = [
    "email",
    "telephone",
    "pais",
    "tipoProveedor",
    "registro",
    "razonSocial",
    "actividad",
    "ciudad",
    "calle",
    "segmento",
    "rubro",
    // "especialidad",
    "nombreEjecutivo",
    "telefonoEjecutivo",
    "emailEjecutivo"
  ];
  const [errors, setErrors] = useState({
    telefonoEjecutivo: "",
    emailEjecutivo: "",
    inicioActividad: "",
    capitalSocial: "",
    cantidadEmpleados: "",
    telephone: "",
    telephone2: "",
    website: "",
    email: ""
  });

  const validate = (id, value) => {
    const validators = {
      capitalSocial: x =>
        x < 1 && x != ""
          ? "El valor ingresado para el capital social no es válido."
          : "",
      cantidadEmpleados: x =>
        x < 1 && x != ""
          ? "El valor ingresado para la cantidad de empleados no es válido."
          : "",
      website: x =>
        (x.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        )||x=="")
          ? ""
          : "El sitio web ingresado no es válido",
      email: x =>
        x.match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/)||x==""
          ? ""
          : "El mail ingresado no es válido",
      emailEjecutivo: x =>
        x.match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/)
          ? ""
          : "El mail del ejecutivo de cuenta no es válido",
      inicioActividad: x =>
        new Date(x).getFullYear() > 1900 || x == ""
          ? ""
          : "La fecha de inicio de actividades no es válida.",
      telephone: x => (!isInt(x) ? "El telefono 1 no es válido" : ""),
      telephone2: x => ((!isInt(x)&&x!="") ? "El telefono 2 no es válido" : ""),
      celularRepresentante: x =>
        !isInt(x) ? "El celular del representante no es válido" : "",
      telefonoEjecutivo: x =>
        !isInt(x) ? "El telefono del ejecutivo no es válido" : ""
    };

    return validators[id] ? validators[id](value) : false;
  };

  function isInt(value) {
    var reg = /^\d+$/;
    return reg.test(value);
  }

  const [disableStep, setDisableStep] = useState(true);
  const [operacion] = useState("registrar-proveedor");
  const [toggle, setToggle] = useState(false);
  const [ProveedorOriginal, setProveedorOriginal] = React.useState("");
  const [rubroOptions, setRubroOptions] = useState([]);
  const [selectedRubros, setSelectedRubros] = useState([]);
  const gContext = React.useContext(GlobalContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = step => {
    return step === 1;
  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  useEffect(() => {
    let rubroOptionsAux = [];
    dropdown.rubro.map(s => {
      rubroOptionsAux.push({
        value: s,
        label: s
      });
    });
    setRubroOptions(rubroOptionsAux);
  }, []);

  const handleChangeText = e => {
    let proveedorAux = { ...Proveedor };
    let errorsAux = { ...errors };
    errorsAux[e.target.id] = validate(e.target.id, e.target.value);
   
    setErrors(errorsAux);
    proveedorAux[e.target.id] = e.target.value;
    checkStepCompleted(proveedorAux, activeStep, errorsAux);
    setProveedor(proveedorAux);
  };

  const handleChangeRubro = e => {
    let proveedorAux = { ...Proveedor };
    let errorsAux = { ...errors };
    let rubrosAux = [];
    if (e) {
      e.map(r => rubrosAux.push(r.value));
    }
    proveedorAux.rubro = rubrosAux;

    setProveedor(proveedorAux);
    setSelectedRubros(e);
    checkStepCompleted(proveedorAux, activeStep, errorsAux);
   
  };

  const checkStepCompleted = (p, step, err) => {
    let dis = false;
    
    for (let i = 0; i < keysByStep[step].length; i++) {
      if (p[keysByStep[step][i]] == "") {
        dis = true;
      }
      if (
        keysByStep[step][i] == "rubro" &&
        p[keysByStep[step][i]].length == 0
      ) {
        dis = true;
      }
    }
    for (let i = 0; i < keysByStepValidation[step].length; i++) {
     
      if (
        err[keysByStepValidation[step][i]] &&
        err[keysByStepValidation[step][i]] != ""
      ) {
    
        dis = true;
      }
    }
    setDisableStep(dis);
  };

  const registerProveedorService = p => {
    var reEmail = /\S+@\S+\.\S+/;
    var rePhone = /^[0-9]+$/;
    let completed = true;
    for (const key in Proveedor) {
      if (
        (Proveedor[key] == "" || !Proveedor[key]) && mandatoryKeys.includes(key) ) {
          completed = false;
          
        }
    }

    if (!completed) {
      toast.error("Debe completar todos los campos.");
    } else if (!reEmail.test(Proveedor.email))
      toast.error("El mail ingresado no es correcto.");
    else if (!rePhone.test(Proveedor.telephone))
      toast.error("El nro. de teléfono ingresado no es válido.");
    else if (Proveedor.telephone.length < 4)
      toast.error("El Telefono ingresado no es válido.");
    else {
      APICall.registerProveedor(p)
        .then(() => {
          swal({
            title: "Gracias por su información",
            text: "Te enviamos un correo de confirmación indicando que su registro ya está en nuestro sistema de compra.",
            icon: "success",

            close: "OK",

            dangerMode: false
          }).then(() => {
            window.location.replace("https://www.tecnoedil.com.py/");
          });
        })
        .catch(e => {
        
          toast.error("Hubo un error: " + e);
        });
    }
  };
  const prevStyle = { background: "#33c3f0" };
  const nextStyle = { background: "#33c3f0" };

  const ButtonNext = () => {
    if (activeStep == 4) {
      return (
        <div
          className="row"
          style={{ justifyContent: "space-around", width: "100%" }}
        >
          <div className="col-12  col-lg-6  mb-3" style={{ padding: 5 }}>
            <Button
              variant="outline-primary"
              size={"md"}
              onClick={handleBack}
              style={{ width: "100%" }}
            >
              <span style={{ padding: 5, paddingLeft: 10 }}>Volver</span>
            </Button>
          </div>
          <div className="col-12  col-lg-6  mb-3" style={{ padding: 5 }}>
            <Button
              variant="primary"
              size={"md"}
              onClick={() => registerProveedorService(Proveedor)}
              style={{ width: "100%" }}
              disabled={disableStep}
            >
              <i
                class="las la-plus-circle "
                style={{
                  fontSize: "1.2rem"
                }}
              ></i>
              <span style={{ padding: 5, paddingRight: 10 }}>
                Registrar Proveedor
              </span>
            </Button>
          </div>
        </div>
      );
    }
    if (activeStep == 0) {
      return (
        <div className="col-12  " style={{ justifyContent:"center",alignItems: "center",display: "flex",width: "100%"}}>
              
        <Button
          variant="primary"
          disabled={disableStep}
          size={"md"}
          onClick={handleNext}
          style={{ width: "100%" }}
        >
          <span style={{ paddingLeft: 10 }}>Siguiente</span>
        </Button>
        </div>
      );
    }
    return (
      <div
        className="row"
        style={{ display: "flex", justifyContent: "space-around" }}
      >
        <div className="col-12  col-lg-6  mb-3" style={{ padding: 5 }}>
          <Button
            variant="outline-primary"
            className="col-12"
            size={"md"}
            onClick={handleBack}
          >
            <span style={{ paddingLeft: 10 }}>Volver</span>
          </Button>
        </div>
        <div className="col-12  col-lg-6  mb-3" style={{ padding: 5 }}>
          <Button
            variant="primary"
            className="col-12"
            size={"md"}
            disabled={disableStep}
            onClick={handleNext}
          >
            <span style={{ paddingLeft: 10 }}>Siguiente</span>
          </Button>
        </div>
      </div>
    );
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    checkStepCompleted(Proveedor, activeStep + 1, errors);
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    checkStepCompleted(Proveedor, activeStep - 1, errors);
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };
  return (
    <div>
      <div
        className="header"
        style={{
          height: "125px",
          backgroundColor: "rgb(74 112 213)",
          paddingLeft: 0
        }}
      >
        <div
          className="nav-header"
          style={{
            backgroundColor: "rgb(74 112 213)",
            flexDirection: "row",
            display: "flex",
            height: "125px"
          }}
        >
          <a className="brand-logo" href="https://www.tecnoedil.com.py/">
            <img className="logo-abbr" src={logo} alt="" />
            <img className="logo-compact" src={logoText} alt="" />
            <img className="brand-title" src={logoText} alt="" />
          </a>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "border-bottom": "1px solid #EEEEEE"
            }}
          >
            <h4
              style={{ color: "white", minWidth: 300, cursor: "pointer" }}
              onClick={() =>
                window.location.replace("https://www.tecnoedil.com.py/")
              }
            >
              ← Volver al sitio web
            </h4>
          </div> */}
        </div>
      </div>
      <div
        className="pt-5 mt-5 pl-0 pr-0  "
        style={{
          width: "100%",
          display: "flex",
          paddingTop: "20vh",

          margin: "auto",
          paddingBottom: 400,
          backgroundColor: "#DDDDDD",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <div
          className="col-12 col-lg-10 row mt-3 mt-lg-5"
          style={{
            backgroundColor: "#DDDDDD",
            justifyContent: "center",

            alignItems: "flex",
            display: "flex",
            justifyContent: "flex-start"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: 15
            }}
          >
            <p
              className=" mt-2 pb-0 mb-0 "
              style={{ color: "#526FCE", fontSize: 22 }}
            >
              Formulario de
            </p>

            <h1
              className="mb-3 mt-0 pt-0"
              style={{ color: "#526FCE", fontSize: 42, fontWeight: "bold" }}
            >
              Inscripción de Proveedores.
            </h1>
          </div>
          <div className="col-lg-12">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="navpills-1"
                role="tabpanel"
              >
                <Table
                  responsive
                  style={{ overflow: "hidden" }}
                  className="table-responsive card-table rounded table-hover fs-14"
                >
                  <div
                    style={{ backgroundColor: "#526FCE", minHeight: 120 }}
                    className="d-none d-sm-block"
                  >
                    <Stepper
                      activeStep={activeStep}
                      alternativeLabel
                      style={{ backgroundColor: "#526FCE", color: "#FFFFFF" }}
                    >
                      {[
                        "Identificador del Proveedor",
                        "Datos del Representante Legal",

                        "Datos de Ubicación empresa",
                        "Productos y/o Servicios",
                        //"Datos Generales empresa",
                        "Confirmación de registro"
                      ].map(label => (
                        <Step key={label}>
                          <StepLabel
                            classes={{
                              alternativeLabel: props.classes.alternativeLabel,
                              labelContainer: props.classes.labelContainer
                            }}
                            StepIconProps={{
                              classes: {
                                root: props.classes.step,
                                completed: props.classes.completed,
                                active: props.classes.active,
                                disabled: props.classes.disabled
                              }
                            }}
                          >
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>

                  <div id="example_wrapper" className="dataTables_wrapper">
                    <div
                      id="example"
                      className="card card-body"
                      style={{
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0
                      }}
                    >
                      <Row className="pl-2 pr-2">
                        {activeStep == 4 ? (
                          <Col lg={12}>
                            <h3
                              className="mb-4 mt-3 ml-3"
                              style={{ textAlign: "center", width: "100%" }}
                            >
                              Confirmar Datos
                            </h3>
                          </Col>
                        ) : (
                          <Row>
                            <Col lg={12}>
                              <h3
                                className="mb-3 mt-2 ml-3"
                                style={{ textAlign: "center", width: "100%" }}
                              >
                                Registrar Nuevo Proveedor
                              </h3>
                            </Col>
                          </Row>
                        )}

                        <Col lg={12}>
                          <Row>
                            {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}
                            {activeStep == 0 && (
                              <Row>
                                <Col
                                  lg={6}
                                  style={{
                                    justifyContent: "space-around",
                                    display: "flex",
                                    flexDirection: "column"
                                  }}
                                >
                                  <div className="basic-form">
                                    <form action="#">
                                      <Row>
                                        <Col lg={12}>
                                          <h4 className="mb-3 mt-4">
                                            Identificación del Proveedor
                                          </h4>
                                          <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                País *
                                              </span>
                                            </div>

                                            <select
                                              className="form-control"
                                              value={Proveedor.pais}
                                              onChange={handleChangeText}
                                              id="pais"
                                            >
                                              {dropdown.paises.map((e, i) => (
                                                <option value={e}>{e}</option>
                                              ))}
                                            </select>
                                          </div>
                                        </Col>
                                        <Col lg={12}>
                                          <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                RUC / CNPJ / ID *
                                              </span>
                                            </div>
                                            <input
                                              value={Proveedor.registro}
                                              onChange={handleChangeText}
                                              id="registro"
                                              type="text"
                                              className="form-control"
                                            />
                                          </div>
                                        </Col>
                                      
                                      </Row>
                                    </form>
                                  </div>
                                </Col>
                                <Col
                                  lg={6}
                                  style={{
                                    justifyContent: "space-around",
                                    display: "flex",
                                    flexDirection: "column"
                                  }}
                                >
                                  <div className="basic-form">
                                    <form action="#">
                                      <Row>
                                    
                                        <Col lg={12}>
                                          <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                Tipo de Proveedor *
                                              </span>
                                            </div>

                                            <select
                                              className="form-control"
                                              value={Proveedor.tipoProveedor}
                                              onChange={handleChangeText}
                                              id="tipoProveedor"
                                            >
                                              {dropdown.tipoDeProveedor.map(
                                                (e, i) => (
                                                  <option value={e}>{e}</option>
                                                )
                                              )}
                                            </select>
                                          </div>
                                        </Col>
                                      </Row>
                                    </form>
                                  </div>
                                </Col>

                                <Col lg={5} className="d-none d-lg-block">
                                  <div
                                    style={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                      display: "flex"
                                    }}
                                  ></div>
                                </Col>

                                <Col lg={12}>
                                  <h4 className="mb-3 mt-4">
                                    Datos Generales del Proveedor
                                  </h4>
                                  <div className="basic-form">
                                    <form action="#">
                                      <Row>
                                        <Col lg={6}>
                                          <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                Razón Social / Nombre *
                                              </span>
                                            </div>
                                            <input
                                              value={Proveedor.razonSocial}
                                              onChange={handleChangeText}
                                              id="razonSocial"
                                              type="text"
                                              className="form-control"
                                            />
                                          </div>
                                        </Col>
                                        <Col lg={6}>
                                          <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                Nombre de Fantasía
                                              </span>
                                            </div>
                                            <input
                                              type="text"
                                              value={Proveedor.nombreFantasia}
                                              onChange={handleChangeText}
                                              id="nombreFantasia"
                                              className="form-control"
                                            />
                                          </div>
                                        </Col>
                                        <Col lg={6}>
                                          <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                Tipo de Actividad *
                                              </span>
                                            </div>

                                            <select
                                              className="form-control"
                                              value={Proveedor.actividad}
                                              onChange={handleChangeText}
                                              id="actividad"
                                            >
                                              {dropdown.tipoActividad.map(
                                                (e, i) => (
                                                  <option value={e}>{e}</option>
                                                )
                                              )}
                                            </select>
                                          </div>
                                        </Col>
                                        <Col lg={6}>
                                          <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                Inicio de Actividades
                                              </span>
                                            </div>
                                            <input
                                              type="date"
                                              value={Proveedor.inicioActividad}
                                              onChange={handleChangeText}
                                              id="inicioActividad"
                                              className="form-control"
                                              style={
                                                errors["inicioActividad"]
                                                  ? { borderColor: "red" }
                                                  : {}
                                              }
                                            />
                                          </div>
                                        </Col>
                                        <Col lg={6}>
                                          <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                Capital Social (USD)
                                              </span>
                                            </div>
                                            <input
                                              type="number"
                                              value={Proveedor.capitalSocial}
                                              onChange={handleChangeText}
                                              id="capitalSocial"
                                              style={
                                                errors["capitalSocial"]
                                                  ? { borderColor: "red" }
                                                  : {}
                                              }
                                              className="form-control"
                                            />
                                          </div>
                                        </Col>
                                        <Col lg={6}>
                                          <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                Nº de empleados
                                              </span>
                                            </div>
                                            <input
                                              type="number"
                                              value={
                                                Proveedor.cantidadEmpleados
                                              }
                                              onChange={handleChangeText}
                                              id="cantidadEmpleados"
                                              className="form-control"
                                              style={
                                                errors["cantidadEmpleados"]
                                                  ? { borderColor: "red" }
                                                  : {}
                                              }
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </form>
                                  </div>
                                </Col>
                                <ButtonNext></ButtonNext>
                              </Row>
                            )}

                            {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

                            {activeStep == 1 && (
                              <Col lg={12}>
                                <h4 className="mb-3 mt-4">
                                  Datos del Representante Legal
                                </h4>
                                <div className="basic-form">
                                  <form action="#">
                                    <Row>
                                      <Col lg={6}>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              Representante Legal
                                            </span>
                                          </div>
                                          <input
                                            value={Proveedor.representanteLegal}
                                            onChange={handleChangeText}
                                            id="representanteLegal"
                                            type="text"
                                            className="form-control"
                                          />
                                        </div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              Documento de Identidad*
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            value={Proveedor.documentoIdentidad}
                                            onChange={handleChangeText}
                                            id="documentoIdentidad"
                                            className="form-control"
                                          />
                                        </div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              Puesto
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            value={Proveedor.puesto}
                                            onChange={handleChangeText}
                                            id="puesto"
                                            className="form-control"
                                          />
                                        </div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              Celular
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            value={
                                              Proveedor.celularRepresentante
                                            }
                                            onChange={handleChangeText}
                                            id="celularRepresentante"
                                            className="form-control"
                                            style={
                                              errors["celularRepresentante"]
                                                ? { borderColor: "red" }
                                                : {}
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={12}>
                                        <h4 className="mb-3 mt-4">
                                          Datos del Ejecutivo de Cuenta de su empresa
                                        </h4>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={6}>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              Nombre *
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            value={Proveedor.nombreEjecutivo}
                                            onChange={handleChangeText}
                                            id="nombreEjecutivo"
                                            className="form-control"
                                          />
                                        </div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              Teléfono *
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            value={Proveedor.telefonoEjecutivo}
                                            onChange={handleChangeText}
                                            id="telefonoEjecutivo"
                                            className="form-control"
                                            style={
                                              errors["telefonoEjecutivo"]
                                                ? { borderColor: "red" }
                                                : {}
                                            }
                                          />
                                        </div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              Email *
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            value={Proveedor.emailEjecutivo}
                                            onChange={handleChangeText}
                                            id="emailEjecutivo"
                                            className="form-control"
                                            style={
                                              errors["emailEjecutivo"]
                                                ? { borderColor: "red" }
                                                : {}
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </form>
                                </div>
                                <ButtonNext></ButtonNext>
                              </Col>
                            )}

                            {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

                            {activeStep == 2 && (
                              <Col lg={12}>
                                <h4 className="mb-3 mt-4">
                                  Datos de Ubicación
                                </h4>
                                <div className="basic-form">
                                  <form action="#">
                                    <Row>
                                      <Col lg={6}>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              Departamento/Estado
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={Proveedor.estado}
                                            onChange={handleChangeText}
                                            id="estado"
                                          />
                                        </div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              Ciudad *
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            value={Proveedor.ciudad}
                                            onChange={handleChangeText}
                                            id="ciudad"
                                            className="form-control"
                                          />
                                        </div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              Teléfono 1 *
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            value={Proveedor.telephone}
                                            onChange={handleChangeText}
                                            id="telephone"
                                            className="form-control"
                                            style={
                                              errors["telephone"]
                                                ? { borderColor: "red" }
                                                : {}
                                            }
                                          />
                                        </div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              Teléfono 2
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            value={Proveedor.telephone2}
                                            onChange={handleChangeText}
                                            id="telephone2"
                                            className="form-control"
                                            style={
                                              errors["telephone2"]
                                                ? { borderColor: "red" }
                                                : {}
                                            }
                                          />
                                        </div>
                                      </Col>
                                      <Col lg={12}>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              Dirección *
                                            </span>
                                          </div>
                                          <input
                                            value={Proveedor.calle}
                                            onChange={handleChangeText}
                                            id="calle"
                                            type="text"
                                            className="form-control"
                                          />
                                        </div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              Sitio Web
                                            </span>
                                          </div>
                                          <input
                                            value={Proveedor.website}
                                            onChange={handleChangeText}
                                            id="website"
                                            type="text"
                                            className="form-control"
                                            style={
                                              errors["website"]
                                                ? { borderColor: "red" }
                                                : {}
                                            }
                                          />
                                        </div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              Email
                                            </span>
                                          </div>
                                          <input
                                            value={Proveedor.email}
                                            onChange={handleChangeText}
                                            id="email"
                                            type="mail"
                                            className="form-control"
                                            style={
                                              errors["email"]
                                                ? { borderColor: "red" }
                                                : {}
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </form>
                                </div>
                                <ButtonNext></ButtonNext>
                              </Col>
                            )}

                            {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

                            {activeStep == 3 && (
                              <Col lg={12}>
                                <h4 className="mb-3 mt-4">
                                  Productos y/o Servicios
                                </h4>
                                <div className="basic-form">
                                  <form action="#">
                                    <Row>
                                      {/* <Col lg={6}>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              Segmento *
                                            </span>
                                          </div>

                                          <select
                                            className="form-control"
                                            value={Proveedor.segmento}
                                            onChange={handleChangeText}
                                            id="segmento"
                                          >
                                            {dropdown.segmento.map((e, i) => (
                                              <option value={e}>{e}</option>
                                            ))}
                                          </select>
                                        </div>
                                      </Col> */}
                                      <Col lg={8}>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              Rubro *
                                            </span>
                                          </div>

                                          <Select
                                            isMulti={true}
                                            className="form-control"
                                            // value={Proveedor.rubro}
                                            //onChange={handleChangeText}
                                            placeholder="Seleccionar"
                                            id="segmento"
                                            disabled={operacion.includes(
                                              "consultar"
                                            )}
                                            value={selectedRubros}
                                            onChange={handleChangeRubro}
                                            options={rubroOptions}
                                          ></Select>
                                        </div>
                                      </Col>
                                      <Col lg={4}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Especialidad
                                        </span>
                                      </div>
                                      <input
                                        type="text"
                                        value={Proveedor.especialidad}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        onChange={handleChangeText}
                                        id="especialidad"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                      <Col lg={12}>
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              Detalle de Producto/ Servicio
                                            </span>
                                          </div>
                                          <input
                                            type="text"
                                            value={Proveedor.detalle}
                                            onChange={handleChangeText}
                                            id="detalle"
                                            className="form-control"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </form>
				<br></br>
				<br></br>
				<br></br>
				<br></br>
                                </div>
                                <ButtonNext></ButtonNext>
                              </Col>
                            )}

                            {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

                            {/*activeStep == 5 && (
                              <Col>
                                <Radios
                                  operacion={operacion}
                                  value={Proveedor.radioForm}
                                  onChange={handleChangeText}
                                ></Radios>
                                <ButtonNext></ButtonNext>
                              </Col>
                            )*/}

                            {/*SE ANULA STEP 5 (RADIOS) ORIGINAL*/}
                            {activeStep == 4 && (
                              <Col
                                sm={12}
                                className="mt-5"
                                style={{
                                  width: "100%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  flexDirection: "column"
                                }}
                              >
                                <ViewDataTable
                                  Proveedor={Proveedor}
                                ></ViewDataTable>

                                <ButtonNext></ButtonNext>
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = theme => ({
  labelContainer: {
    "& $alternativeLabel": {
      marginTop: 0
    }
  },
  step: {
    "& $completed": {
      color: "lightgreen"
    },
    "& $active": {
      color: "pink"
    },
    "& $disabled": {
      color: "red"
    }
  },
  alternativeLabel: { color: "#E9ECEF" },
  active: {
    "& $completed": {
      color: "lightgreen"
    },
    "& $active": {
      color: "pink"
    },
    "& $disabled": {
      color: "red"
    }
  }, //needed so that the &$active tag works
  completed: {},
  disabled: {},
  labelContainer: {
    "& $alternativeLabel": {
      marginTop: 0,
      color: "white"
    }
  }
});

export default withStyles(styles)(RegisterProveedor);
