import user4 from "../../../../images/users/4.png";
import user5 from "../../../../images/users/5.png";
import user6 from "../../../../images/users/6.png";
import user7 from "../../../../images/users/7.png";
import user8 from "../../../../images/users/8.png";
import user9 from "../../../../images/users/9.png";
import user13 from "../../../../images/users/13.png";
import user14 from "../../../../images/users/14.png";

const patientTable = {
  data: [
    {
      id: "#P-000441425",
      title: "Paciente Respondeu Diario da dor",
      dat: "12/12/2020",
      Cimg: user4,
      st: "Marcos Vinicius",
      act: "Pending",
      Pimg: user14,
      t6: ""
    },

    {
      id: "#P-000441425",
      title: "Paciente Respondeu Diario da dor",
      dat: "12/12/2020",
      Cimg: user4,
      st: "Marcos Vinicius",
      act: "Closed",
      Pimg: user14,
      t6: ""
    },
    {
      id: "#P-000441425",
      title: "Paciente Respondeu Diario da dor",
      dat: "12/12/2020",
      Cimg: user4,
      st: "Marcos Vinicius",
      act: "Closed",
      Pimg: user14,
      t6: ""
    },
    {
      id: "#P-000441425",
      title: "Paciente Respondeu Diario da dor",
      dat: "12/12/2020",
      Cimg: user4,
      st: "Marcos Vinicius",
      act: "Pending",
      Pimg: user14,
      t6: ""
    },
    {
      id: "#P-000441425",
      title: "Paciente Respondeu Diario da dor",
      dat: "12/12/2020",
      Cimg: user4,
      st: "Marcos Vinicius",
      act: "Pending",
      Pimg: user14,
      t6: ""
    },
    {
      id: "#P-000441425",
      title: "Paciente Respondeu Diario da dor",
      dat: "12/12/2020",
      Cimg: user4,
      st: "Marcos Vinicius",
      act: "Pending",
      Pimg: user14,
      t6: ""
    },
    {
      id: "#P-000441425",
      title: "Paciente Respondeu Diario da dor",
      dat: "12/12/2020",
      Cimg: user4,
      st: "Marcos Vinicius",
      act: "Pending",
      Pimg: user14,
      t6: ""
    },
    {
      id: "#P-000441425",
      title: "Paciente Respondeu Diario da dor",
      dat: "12/12/2020",
      Cimg: user4,
      st: "Marcos Vinicius",
      act: "Pending",
      Pimg: user14,
      t6: ""
    },
    {
      id: "#P-000441425",
      title: "Paciente Respondeu Diario da dor",
      dat: "12/12/2020",
      Cimg: user4,
      st: "Marcos Vinicius",
      act: "Pending",
      Pimg: user14,
      t6: ""
    },
    {
      id: "#P-000441425",
      title: "Paciente Respondeu Diario da dor",
      dat: "12/12/2020",
      Cimg: user4,
      st: "Marcos Vinicius",
      act: "Pending",
      Pimg: user14,
      t6: ""
    },
    {
      id: "#P-000441425",
      title: "Paciente Respondeu Diario da dor",
      dat: "12/12/2020",
      Cimg: user4,
      st: "Marcos Vinicius",
      act: "Pending",
      Pimg: user14,
      t6: ""
    }
  ],
  columns: ["Evento", "Recupero", "Paciente", "Responsavel", "Ação", ""]
};

const pendingPatient = [];
const processPatient = [];
const closePatient = [];
patientTable.data.map((d, i) =>
  d.act === "Pending"
    ? pendingPatient.push(d)
    : d.act === "Process"
    ? processPatient.push(d)
    : d.act === "Closed"
    ? closePatient.push(d)
    : ""
);

const data = {
  //profileTable,
  //pendingData,
  patientTable,
  pendingPatient,
  processPatient,
  closePatient
  //productData,
  //customers,
};

export default data;
