import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// modal
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";
import { APICall } from "../../../API";
import GlobalContext from "../../../gContext";
import { toast } from "react-toastify";
import RoutesAnalista from "./routesSidebar/analista";
import RoutesAdmin from "./routesSidebar/admin";
import RoutesProveedor from "./routesSidebar/Proveedor";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    //this.mm("dispose");
    this.mm.dispose();
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={el => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.handleChangeNovoMedico = this.handleChangeNovoMedico.bind(this);
    this.handleChangeNovoFisio = this.handleChangeNovoFisio.bind(this);
    this.handleChangeNovoPaciente = this.handleChangeNovoPaciente.bind(this);
    this.createPaciente = this.createPaciente.bind(this);
    this.createFisio = this.createFisio.bind(this);
    this.createMedico = this.createMedico.bind(this);
  }
  static contextType = GlobalContext;
  /// model box
  state = {
    // initial state
    showNovoPaciente: false,
    showNovoMedico: false,
    showNovoFisio: false,
    blancNovoMedico: {
      email: "",

      telephone: "",
      name: "",
      lastName: "",
      birthDate: "",
      sexo: "",
      especialidadPrincipal: "",
      especialidadSecundaria: "",
      role: "medico",
      location: {
        street: "",
        complement: "",
        number: "",
        state: "",
        city: "",
        postalCode: ""
      }
    },
    blancNovoPaciente: {
      email: "",
      telephone: "",
      name: "",
      lastName: "",
      birthDate: "",
      sexo: "",
      role: "paciente",
      location: {
        street: "",
        complement: "",
        number: "",
        state: "",
        city: "",
        postalCode: ""
      }
    },
    blancNovoFisio: {
      email: "",

      telephone: "",
      name: "",
      lastName: "",
      birthDate: "",
      sexo: "",
      especialidadPrincipal: "",
      especialidadSecundaria: "",
      role: "fisio",
      location: {
        street: "",
        complement: "",
        number: "",
        state: "",
        city: "",
        postalCode: ""
      }
    },
    novoMedico: {},
    novoPaciente: {},
    novoFisio: {},
    itemsSexo: {},
    itemsEspecialidades: {},
    loading: true
  };

  handleClose = modal => {
    this.setState({
      [modal]: false,
      novoMedico: { ...this.state.blancNovoMedico },
      novoPaciente: { ...this.state.blancNovoPaciente },
      novoFisio: { ...this.state.blancNovoFisio }
    });
  };
  handleShow = modal => {
    this.setState({ [modal]: true });
  };

  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    this.setState({
      novoMedico: {
        ...this.state.blancNovoMedico
      },
      novoPaciente: {
        ...this.state.blancNovoPaciente
      },
      novoFisio: {
        ...this.state.blancNovoFisio
      }
    });

    btn.addEventListener("click", toggleFunc);
  }



  handleChangeNovoMedico(e) {
    console.log(this);

    const novoM = this.state.novoMedico;
    if (e.target.id == "location") {
      novoM[e.target.id][e.target.name] = e.target.value;
    } else if (e.target.name == "role") {
      novoM[e.target.name] = e.target.checked ? "medico-admin" : "medico";
    } else {
      novoM[e.target.name] = e.target.value;
    }
    this.setState({ novoMedico: novoM });
  }
  handleChangeNovoFisio(e) {
    const novoF = this.state.novoFisio;
    if (e.target.id == "location") {
      novoF[e.target.id][e.target.name] = e.target.value;
    } else {
      novoF[e.target.name] = e.target.value;
    }
    this.setState({ novoFisio: novoF });
  }

  handleChangeNovoPaciente(e) {
    const novoP = this.state.novoPaciente;
    if (e.target.id == "location") {
      novoP[e.target.id][e.target.name] = e.target.value;
    } else {
      novoP[e.target.name] = e.target.value;
    }
    this.setState({ novoPaciente: novoP });
  }

  createMedico() {
    for (const key in this.state.novoMedico) {
      if (Object.hasOwnProperty.call(this.state.novoMedico, key)) {
        const element = this.state.novoMedico[key];
        if(typeof element =="string" && element=="")return alert("Deve completar campos")
      }
    }
    APICall.createUser(this.state.novoMedico)
      .then(res => {
        toast.success("Médico criado com sucesso!");
        swal({
          title: "Conta criada com sucesso!",
          text: "https://localhost:3000/page-completar-cadastro/" + res.activationKey,
          icon: "success",
          buttons: ["Copiar Link!", false]
        }).then(() => {
          window.navigator.clipboard.writeText(
            "https://localhost:3000/page-completar-cadastro/" + res.activationKey
          );
        });
      })
      .catch(() => {
        toast.error("Houve um erro quando tentamos criar o usuario...");
      })
      .finally(() => {
        this.setState({
          novoMedico: {
            ...this.state.blancNovoMedico
          },
          showNovoMedico: false
        });
      });
  }
  createPaciente() {
    for (const key in this.state.novoPaciente) {
      if (Object.hasOwnProperty.call(this.state.novoPaciente, key)) {
        const element = this.state.novoPaciente[key];
        if (typeof element == "string" && element == "")
          return alert("Deve completar campos");
      }
    }
    APICall.createUser(this.state.novoPaciente)
      .then(res => {
        toast.success("Paciente criado com sucesso!");
        swal({
          title: "Conta criada com sucesso!",
          text: "https://localhost:3000/page-completar-cadastro/" + res.activationKey,
          icon: "success",
          buttons: ["Copiar Link!", false]
        }).then(() => {
          window.navigator.clipboard.writeText(
            "https://localhost:3000/page-completar-cadastro/" + res.activationKey
          );
        });
      })
      .catch(() => {
        toast.error("Houve um erro quando tentamos criar o usuario...");
      })
      .finally(() => {
        this.setState({
          novoPaciente: {
            ...this.state.blancNovoPaciente
          },
          showNovoPaciente: false
        });
      });
  }
  createFisio() {
    for (const key in this.state.novoFisio) {
      if (Object.hasOwnProperty.call(this.state.novoFisio, key)) {
        const element = this.state.novoFisio[key];
        if (typeof element == "string" && element == "")
          return alert("Deve completar campos");
      }
    }
    APICall.createUser(this.state.novoFisio)
      .then(res => {
        toast.success("Fisioterapeuta criado com sucesso!");
        swal({
          title: "Conta criada com sucesso!",
          text:
            "https://localhost:3000/page-completar-cadastro/" + res.activationKey,
          icon: "success",
          buttons: ["Copiar Link!", false]
        }).then(() => {
          window.navigator.clipboard.writeText(
            "https://localhost:3000/page-completar-cadastro/" + res.activationKey
          );
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setState({
          novoFisio: {
            ...this.state.blancNovoFisio
          },
          showNovoFisio: false
        });
      });
  }

  render() {
    /// Path
    const { user, setUser } = this.context;
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    return (
      <div className="dlabnav">
        {user.role == "analista" ? (
          <RoutesAnalista
            onClick3={this.props.onClick3}
            handleShow={this.handleShow}
            onClick={this.props.onClick}
            path={path}
          ></RoutesAnalista>
        ) : user.role == "admin" || user.role == "gerente" ? (
          <RoutesAdmin
            onClick3={this.props.onClick3}
            handleShow={this.handleShow}
            onClick={this.props.onClick}
            path={path}
          ></RoutesAdmin>
        ) : (
          <RoutesProveedor
            onClick3={this.props.onClick3}
            handleShow={this.handleShow}
            onClick={this.props.onClick}
            path={path}
          ></RoutesProveedor>
        )}
    
      </div>
    );
  }
}

export default SideBar;
