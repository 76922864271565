import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Pagination, Dropdown } from "react-bootstrap";
import data from "../../../Dashboard/Projects/Tabldata.js";
import { APICall } from "../../../../../API/index";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Stateless from "../../../Dashboard/MeusPacientes/Stateless.js";
const Alldata = ({id}) => {
  const sort = 5;
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [dataTableFull, setDataTableFull] = useState([]);
  const [Filter, setFilter] = useState("");
  useEffect(() => {
    if(id){
      APICall.getCotizacionesByFornecedor(id).then(res => {
    
        setLoading(false);
        setDataTable(res.data);
        setDataTableFull(res.data);
      });
    }
   
  }, [id]);

  useEffect(() => {
    if (Filter == "") {
      setDataTable(dataTableFull);
    } else {
      let listToPush = [];
      for (let i = 0; i < dataTableFull.length; i++) {
        const element = dataTableFull[i];
        if (
          JSON.stringify(element).toLowerCase().includes(Filter.toLowerCase())
        ) {
          listToPush.push(element);
        }
      }
      setDataTable(listToPush);
    }
  }, [Filter]);
  const columns = [
    "",
    "Título del mail",
    "Cantidad de proveedores",
    "Autor del envio",
    "Fecha del envio",
    "Estado",
    "Detalles"
  ];

  useEffect(() => {
    onClick(0);
  }, [dataTable]);

  let jobPagination = Array(Math.ceil(dataTable.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const activePag = useRef(0);
  const jobData = useRef(
    dataTable.slice(activePag.current * sort, (activePag.current + 1) * sort)
  );
  const [demo, setdemo] = useState();
  const onClick = i => {
    activePag.current = i;

    jobData.current = dataTable.slice(
      activePag.current * sort,
      (activePag.current + 1) * sort
    );
    setdemo(
      dataTable.slice(activePag.current * sort, (activePag.current + 1) * sort)
    );
  };

  const handleDelete = id => {
    swal({
      title: "Dese borrar proveedor?",
      text: "Esta operación no podrá ser cancelada. Los datos serán borrados de la base!",
      icon: "warning",

      dangerMode: true,
      buttons: ["VOLVER", "BORRAR"]
    }).then(willDelete => {
      if (willDelete) {
        setLoading(true);
        APICall.deleteProveedor(id).then(() => {
           
            APICall.getProveedores().then(res => {
              console.log(res);
              setLoading(false);
              setDataTable(res.data);
              setDataTableFull(res.data);
            })
          })
          .catch(e => {
            toast.error("No pudimos borrar el proveedor");
          })
      }
    });
  };

  const handleSearchChange = e => {
    setFilter(e.target.value);
  };

  const chackbox = document.querySelectorAll(".check-input input");
  const motherChackBox = document.querySelector(".check-input-all input");
  const chackboxFun = type => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };
  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          {/* <Filters handleSearchChange={handleSearchChange}></Filters> */}
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="navpills-1"
              role="tabpanel"
            >
               {jobData.current.length == 0 ? (
                <Stateless
                  text={"No tiene cotizaciones!"}
                  icon={"flaticon-381-layer-1"}
                ></Stateless>):(
              <Table
                responsive
                className="table-responsive card-table rounded table-hover fs-14"
              >
                <div id="example_wrapper" className="dataTables_wrapper">
                  <table
                    id="example"
                    className="table border-no display mb-4 dataTablesCard table-responsive-xl project-bx"
                  >
                    <thead>
                      <tr role="row">
                        {columns.map((d, i) => (
                          <th key={i} className="check-input-all">
                            {false? (
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  onClick={() => chackboxFun("all")}
                                  className="custom-control-input"
                                  id="checkAll"
                                  required
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="checkAll"
                                />
                              </div>
                            ) : (
                              d
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {!loading &&
                        jobData.current.map((d, i) => (
                          <tr key={i}>
                            <td>
                             {i+1}
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div>
                                  <span className="text-black font-w600 text-nowrap">
                                    {d.title || "No Tiene"} 
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div>
                                  <span className="text-black font-w600 text-nowrap">
                                    {d?.fornecedores?.length }
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div>
                                  <span className="text-black font-w600 text-nowrap">
                                    {d?.author?.name || "ADMIN"}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div>
                                  <span className="text-black font-w600">
                                    {new Date(d.createdAt).toLocaleString() || "NO TIENE"}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div>
                                  <span className="text-black font-w600">
                                    {d.state||"Sin Estado"}
                                  </span>
                                </div>
                              </div>
                            </td>
                         
                            <td>
                              <Link to={`/cotizacion/${d._id}/ver-cotizacion`}
                              >
                                <i
                                  className="fa fa-eye mr-3"
                                  style={{ fontSize: "1.5rem" }}
                                ></i>
                              </Link>
                       
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {loading && (
                    <div
                      style={{
                        display: "flex",
                        flex: "1",
                        justifyContent: "center"
                      }}
                    >
                      <div
                        className="spinner-border"
                        style={{ height: "200px", width: "200px" }}
                      ></div>
                    </div>
                  )}
                  <div className="d-sm-flex justify-content-between  align-items-center mt-3">
                    <div className="dataTables_info">
                      {/* Total de {data.patientTable.data.length} elementos */}
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers">
                      <Pagination size="lg">
                        <li
                          className="page-item page-indicator "
                          onClick={() =>
                            activePag.current > 0 &&
                            onClick(activePag.current - 1)
                          }
                        >
                          {/* <Link className="page-link" to="#">  <i className="la la-angle-left" /></Link> */}
                          <Link className="paginate_button previous" to="#">
                            {" "}
                            Volver
                          </Link>
                        </li>
                        {jobPagination.map((number, i) => (
                          <Pagination.Item
                            className={activePag.current === i ? "active" : ""}
                            onClick={() => onClick(i)}
                          >
                            <span style={{ color: "black" }}>{number}</span>
                          </Pagination.Item>
                        ))}
                        <li
                          className="page-item page-indicator"
                          onClick={() =>
                            activePag.current + 1 < jobPagination.length &&
                            onClick(activePag.current + 1)
                          }
                        >
                          {/* <Link className="page-link" to="#"><i className="la la-angle-right" /></Link> */}
                          <Link className="paginate_button next" to="#">
                            {" "}
                            Siguiente
                          </Link>
                        </li>
                      </Pagination>
                    </div>
                  </div>
                </div>
              </Table>)}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Alldata;


