import React, { useEffect ,useState} from "react";
import { Pie } from "react-chartjs-2";

const ChartPie = ({ color1, color2, color3, color4, height, width,pieData }) => {

   const [colores,setColores]=useState([]);

   useEffect(()=>{
      let coloresAux=[];
      Object.values(pieData).forEach(()=>{
         coloresAux.push("#"+Math.floor(Math.random()*16777215).toString(16))
      })
      setColores(coloresAux)
    
   },[])
   const data = {
      datasets: [
         {
            data: Object.values(pieData),
            borderWidth: 0,
            backgroundColor: colores,
            hoverBackgroundColor: colores,
         },
      ],
      labels: Object.keys(pieData),
   };

   const options = {
      responsive: true,
      legend: false,
      maintainAspectRatio: false,
   };

   return (
      <>
         <Pie data={data} height={height ? height : 330} options={options} />
      </>
   );
};

export default ChartPie;
