import React, {Fragment,useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import {Tab,Nav} from "react-bootstrap";
import data from "../../../Dashboard/Projects/Tabldata.js";

import ProvedorTable from './ProvedorTable';
import WrapperHeader from '../../../../wrapperHeader.js';


const tabData = [
  { number: "2",	name: "", 	content: <ProvedorTable  />,},

];

const ConfirmCotizacion = () => {
	const sort = 10;
    const activePag = useRef(0);
    const jobData = useRef(
		data.patientTable.data.slice(
			activePag.current * sort,
			(activePag.current + 1) * sort
		)
    );
	
	const pendingPatient =  [];
	const processPatient =  [];
	const closePatient =  [];
	jobData.current.map((d, i) => (
		(d.act === 'Pending')? pendingPatient.push(d):(d.act === 'Process')?processPatient.push(d):(d.act === 'Closed')?closePatient.push(d):''
	)); 
	
	const [demo, setdemo] = useState();
	const onClick = (i) => {
		activePag.current = i;

		jobData.current = data.patientTable.data.slice(
			activePag.current * sort,
			(activePag.current + 1) * sort
		);
		setdemo(
			data.patientTable.data.slice(
				activePag.current * sort,
				(activePag.current + 1) * sort
			)
		);
    };
   return (
	   <WrapperHeader>
		<Fragment>
			<Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
	
				<Tab.Content >
					{tabData.map(
						(data, i) =>
						i !== tabData.length  && (
						<Tab.Pane
						   eventKey={data.name.toLowerCase()}
						   key={i}
						>
						   <div>{data.content}</div>
						   
						</Tab.Pane>
					))}
				</Tab.Content>
			</Tab.Container>		 
		</Fragment>  
		</WrapperHeader>
	)
}

export default ConfirmCotizacion;