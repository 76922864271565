import React, { Fragment, useState, useRef, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Table, Pagination, Dropdown } from "react-bootstrap";
import data from "../../../Dashboard/Projects/Tabldata.js";
import { APICall } from "../../../../../API/index";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Select from "react-select";
import { Button, Row } from "react-bootstrap";
import ModalCreateRubro from "./ModalCreateRubro.js";
import GlobalContext from "../../../../../gContext";
import ModalEditRubro from "./ModalEditRubro.js";
const RubroTable = () => {
  const sort = 10;
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [dataTableFull, setDataTableFull] = useState([]);

  const [canShowCreate, setCanShowCreate] = useState(false);

  const [canShowEdit, setCanShowEdit] = useState(false);
  const [editableRubro, setEditableRubro] = useState({});

  useEffect(() => {
    APICall.getRubros().then(res => {

      setLoading(false);
      setDataTable(res.data);
      setDataTableFull(res.data);

    }).catch((e) => console.log(e));
  }, []);



  const columns = [
    "Rubro",

    "Acciones"
  ];

  useEffect(() => {
    onClick(0);
  }, [dataTable]);

  let jobPagination = Array(Math.ceil(dataTable.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const activePag = useRef(0);
  const jobData = useRef(
    dataTable.slice(activePag.current * sort, (activePag.current + 1) * sort)
  );
  const [demo, setdemo] = useState();
  const onClick = i => {
    activePag.current = i;

    jobData.current = dataTable.slice(
      activePag.current * sort,
      (activePag.current + 1) * sort
    );
    setdemo(
      dataTable.slice(activePag.current * sort, (activePag.current + 1) * sort)
    );
  };

  const handleCreate = nombre => {

    setLoading(true);
    APICall.createRubro({ rubro: nombre })
      .then(() => {
        APICall.getRubros().then(res => {

          setLoading(false);
          setDataTable(res.data);
          setDataTableFull(res.data);
          toast.success("Rubro creado con exito");
        });
      })
      .catch(e => {
        toast.error("No pudimos crear el rubro");
      });
  }






  const handleDelete = id => {
    swal({
      title: "Desea borrar este rubro?",
      text: "Esta operación no podrá ser cancelada. Los datos serán borrados de la base!",
      icon: "warning",

      dangerMode: true,
      buttons: ["VOLVER", "BORRAR"]
    }).then(willDelete => {
      if (willDelete) {
        setLoading(true);
        APICall.deleteRubro(id)
          .then(() => {
            APICall.getRubros().then(res => {

              setLoading(false);
              setDataTable(res.data);
              setDataTableFull(res.data);
              toast.success("Rubro borrado con exito");
            });
          })
          .catch(e => {
            toast.error("No pudimos borrar el rubro");
          });
      }
    });
  };




  const handleEdit = rubro => {

    setLoading(true);
    APICall.editRubro(rubro)
      .then(() => {
        APICall.getRubros().then(res => {

          setLoading(false);
          setDataTable(res.data);
          setDataTableFull(res.data);
          toast.success("Rubro editado con exito");
        });
      })
      .catch(e => {
        toast.error("No pudimos editar el rubro");
        setLoading(false);

      });

  }






  return (
    <Fragment>





      <div className="row">
        <div className="col-lg-12">
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="navpills-1"
              role="tabpanel"
            >
              <Table
                responsive
                className="table-responsive card-table rounded table-hover fs-14"
              >
                <div
                  style={{
                    display: "flex",
                    padding: "15px",
                    backgroundColor: "white"
                  }}
                >
                  <div
                    className="col-8"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <h2 style={{ fontWeight: "bold", color: "black" }}>
                      {/* {jobData.current.length + "  "} */}
                      <strong style={{ fontWeight: "bold", color: "black" }}>
                        Productos y/o Servicios
                      </strong>
                    </h2>
                  </div>
                  <div
                    className="col-5"
                    style={{ display: "flex", alignItems: "center" }}
                  >

                    <Button
                      variant="primary"
                      style={{ display: "flex", alignItems: "center" }}
                      size={"sm"}
                      onClick={() => setCanShowCreate(true)}
                    >
                      <i
                        class="las la-plus"
                        style={{ fontSize: "1.2rem" }}
                      ></i>
                      <span style={{ paddingLeft: 10, color: "white" }}>
                        Agregar Rubro
                      </span>
                    </Button>

                  </div>

                </div>

                <div id="example_wrapper" className="dataTables_wrapper">
                  <table
                    id="example"
                    className="table border-no display mb-4 dataTablesCard table-responsive-xl project-bx"

                  >
                    <thead >
                      <tr role="row">
                        {columns.map((d, i) => (
                          <th key={i} className="check-input-all">
                            {d}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody >
                      {!loading &&
                        jobData.current.map((d, i) => (
                          <tr key={i} >

                            <td style={{ cursor: "pointer" }} >
                              <div className="d-flex align-items-center">
                                <div>
                                  <span className="text-black font-w600 text-nowrap">
                                    {d.rubro || "No Tiene"}
                                  </span>
                                </div>
                              </div>
                            </td>





                            <td >

                              <Link

                              >
                                <i
                                  className="fa fa-edit mr-3"
                                  onClick={() => { setEditableRubro(d); setCanShowEdit(true) }}
                                  style={{ fontSize: "1.5rem" }}
                                ></i>
                              </Link>
                              <Link>
                                <i
                                  className="fa fa-trash-o"
                                  onClick={() => handleDelete(d._id)}
                                  style={{ fontSize: "1.5rem" }}
                                >

                                </i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {loading && (
                    <div
                      style={{
                        display: "flex",
                        flex: "1",
                        justifyContent: "center"
                      }}
                    >
                      <div
                        className="spinner-border"
                        style={{ height: "200px", width: "200px" }}
                      ></div>
                    </div>
                  )}
                  <div className="d-sm-flex justify-content-between  align-items-center mt-3">
                    <div className="dataTables_info">
                      {/* Total de {data.patientTable.data.length} elementos */}
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers">
                      <Pagination size="lg">
                        <li
                          className="page-item page-indicator "
                          onClick={() =>
                            activePag.current > 0 &&
                            onClick(activePag.current - 1)
                          }
                        >
                          {/* <Link className="page-link" to="#">  <i className="la la-angle-left" /></Link> */}
                          <Link className="paginate_button previous" to="#">
                            {" "}
                            Volver
                          </Link>
                        </li>
                        {jobPagination.map((number, i) => (
                          <Pagination.Item
                            className={activePag.current === i ? "active" : ""}
                            onClick={() => onClick(i)}
                          >
                            <span style={{ color: "black" }}>{number}</span>
                          </Pagination.Item>
                        ))}
                        <li
                          className="page-item page-indicator"
                          onClick={() =>
                            activePag.current + 1 < jobPagination.length &&
                            onClick(activePag.current + 1)
                          }
                        >
                          {/* <Link className="page-link" to="#"><i className="la la-angle-right" /></Link> */}
                          <Link className="paginate_button next" to="#">
                            {" "}
                            Siguiente
                          </Link>
                        </li>
                      </Pagination>
                    </div>
                  </div>
                </div>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <ModalCreateRubro
        showRubro={canShowCreate}
        isEdit={true}
        handleClose={() => setCanShowCreate(false)}
        createRubro={(nombreRubro) => handleCreate(nombreRubro)}
      ></ModalCreateRubro>
      <ModalEditRubro
        showRubro={canShowEdit}
        isEdit={true}
        handleClose={() => setCanShowEdit(false)}
        rubro={editableRubro}
        editRubro={(rubro) => handleEdit(rubro)}
      ></ModalEditRubro>
    </Fragment>
  );
};

export default RubroTable;

const Filters = ({ handleSearchChange, filter }) => {
  return (
    <input
      type="text"
      value={filter}
      onChange={e => handleSearchChange(e)}
      className="form-control"
      style={{ height: "55%", borderRadius: 4, borderColor: "#CCCCCC" }}
      placeholder="Busqueda Rapida"
    />
  );
};
