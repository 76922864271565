import { Card } from "@material-ui/core";
import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Rte from "./Rte";

function ModalEditRubro({ showRubro, handleClose, editRubro, rubro}) {
  const [editableRubro,setEditableRubro]= React.useState("");
  useEffect(() => {
    setEditableRubro("")
  }, [handleClose])

  useEffect(() => {
    console.log("SETTING EDITABLE")
    setEditableRubro(rubro.rubro)
    console.log("SETTING EDITABLE")
    console.log(rubro);
  }, [rubro])


  return (
    <Modal
      show={showRubro}
      onHide={() => handleClose()}
      className="modal fade"
      id="addOrderModalside"

      size="xl"
      scrollable={false}
    >
      <div role="document" className="m-5">
        <h3 className="pb-2">Ingrese el nombre del rubro</h3>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text">Rubro:</span>
          </div>
          <input
            value={editableRubro}
            onChange={t => {

              setEditableRubro(t.target.value);
            }}
            id="title"
            type="text"
            className="form-control"
          />
        </div>
        <div style={{display:"flex",justifyContent:"flex-end"}}>
          <Button
            variant="outline-primary"
            style={{ display: "flex", alignItems: "center",marginRight:10 }}
            size={"md"}
            onClick={() => handleClose()}
          >
           
            <span style={{ paddingLeft: 10 }}>
              Descartar
            </span>
          </Button>
          <Button
            variant="primary"
            style={{ display: "flex", alignItems: "center" }}
            size={"md"}
            disabled={editableRubro==""}
            onClick={() => {editRubro({_id:rubro._id,rubro:editableRubro});handleClose()}}
          >
            <i class="las la-plus-circle" style={{ fontSize: "1.2rem" }}></i>
            <span style={{ paddingLeft: 10, color: "white" }}>
              Guardar Cambios
            </span>
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalEditRubro;
