import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Table, Pagination, Dropdown } from "react-bootstrap";
import data from "../../../Dashboard/Projects/Tabldata.js";
import { APICall } from "../../../../../API/index";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Select from "react-select";
import Rte from "./Rte";
import ListaProveedor from "./ListProveedor"
const Alldata = () => {
  const sort = 10;
  const { id } = useParams();
const history = useHistory()
  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [dataTableFull, setDataTableFull] = useState([]);
  const [Filter, setFilter] = useState("");
  const [filterCombos, setFilterCombos] = useState({});
  const [pais, setPais] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [departamento, setDepartamento] = useState("");
  const [rubro, setRubro] = useState("");
  const [segmento, setSegmento] = useState("");
  const [dataCotizacion, setDataCotizacion] = useState({
    count: 0,
    status: false
  });
  const [filterOptions, setFilterOptions] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  const [Cotizacion, setCotizacion] = useState({ title: "", descripcion: "" });
  useEffect(() => {
    // APICall.getCotizacionById(id).then(res => {
    //   console.log(res);
    //   setLoading(false);
    //   setDataTable(res.data.fornecedores);
    //   setDataTableFull(res.data.fornecedores);
    //   setCotizacion(res.data);
    // });
    APICall.getFilters().then(res => {
      let filterOptionsAux = {
        paises: [],
        ciudades: [],
        departamentos: [],
        rubros: [],
        segmentos: []
      };
      res.data.paises.forEach(pais => {
        filterOptionsAux.paises.push({
          value: pais,
          label: pais
        });
      });
      res.data.ciudades.forEach(ciudad => {
        filterOptionsAux.ciudades.push({
          value: ciudad,
          label: ciudad
        });
      });
      res.data.estados.forEach(estado => {
        filterOptionsAux.departamentos.push({
          value: estado,
          label: estado
        });
      });
      res.data.rubros.forEach(rubro => {
        filterOptionsAux.rubros.push({
          value: rubro,
          label: rubro
        });
      });
      res.data.segmentos.forEach(segmento => {
        filterOptionsAux.segmentos.push({
          value: segmento,
          label: segmento
        });
      });
      setFilterOptions(filterOptionsAux);
    });
  }, []);

  useEffect(() => {
    if (
      Filter == "" &&
      pais == "" &&
      ciudad == "" &&
      departamento == "" &&
      rubro == "" &&
      segmento == ""
    ) {
      setDataTable(dataTableFull);
      let countChecked = 0;
      let activateEnviarCotizacion = false;
      dataTableFull.forEach(e => {
        if (e.checked) {
          countChecked++;
          activateEnviarCotizacion = true;
        }
      });
      setDataCotizacion({
        count: countChecked,
        status: activateEnviarCotizacion
      });
    } else {
      let listToPush = [];
      for (let i = 0; i < dataTableFull.length; i++) {
        const element = dataTableFull[i];

        if (
          JSON.stringify(element)
            .toLowerCase()
            .includes(Filter.toLowerCase()) &&
          (element.pais == pais.value || !pais.value) &&
          (element.ciudad == ciudad.value || !ciudad.value) &&
          (element.departamento == departamento.value || !departamento.value) &&
          (element.rubro.find(r => r == rubro.value) || !rubro.value) &&
          (element.segmento == segmento.value || !segmento.value)
        ) {
          listToPush.push(element);
        }
      }
      let countChecked = 0;
      let activateEnviarCotizacion = false;
      listToPush.forEach(e => {
        if (e.checked) {
          countChecked++;
          activateEnviarCotizacion = true;
        }
      });
      setDataCotizacion({
        count: countChecked,
        status: activateEnviarCotizacion
      });
      setDataTable(listToPush);
    }
  }, [Filter, pais, ciudad, departamento, rubro, segmento]);

  const columns = [
    "",
    "Nombre Real",
    "RUC / CNPJ /  ID",
    "Tipo de actividad",

    "País",

    "Correo"
  ];

  useEffect(() => {
    onClick(0);
  }, [dataTable]);

  let jobPagination = Array(Math.ceil(dataTable.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const activePag = useRef(0);
  const jobData = useRef(
    dataTable.slice(activePag.current * sort, (activePag.current + 1) * sort)
  );
  const [demo, setdemo] = useState();
  const onClick = i => {
    activePag.current = i;

    jobData.current = dataTable.slice(
      activePag.current * sort,
      (activePag.current + 1) * sort
    );
    setdemo(
      dataTable.slice(activePag.current * sort, (activePag.current + 1) * sort)
    );
  };

  const handleSearchChange = e => {
    setFilter(e.target.value);
  };

  const chackbox = document.querySelectorAll(".check-input input");
  const motherChackBox = document.querySelector(".check-input-all input");

  const chackboxFun = type => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  const checkBoxTypeElement = (i, type, e) => {
    let dataTableAux = [...dataTable];
    let activateEnviarCotizacion = false;
    let countChecked = 0;
    if (type === "all") {
      setCheckedAll(e.target.checked);
      for (let i = 0; i < dataTableAux.length; i++) {
        dataTableAux[i].checked = e.target.checked;
      }
    } else {
      dataTableAux[i].checked = !dataTableAux[i].checked;
    }
    dataTableAux.forEach(e => {
      if (e.checked) {
        countChecked++;
        activateEnviarCotizacion = true;
      }
    });
    setDataCotizacion({
      count: countChecked,
      status: activateEnviarCotizacion
    });
    setDataTable(dataTableAux);
  };

  const filterChange = (e, type) => {
    let filterCombosAux = { ...filterCombos };

    switch (type) {
      case "rubro":
        e ? setRubro(e) : setRubro("");
        break;
      case "segmento":
        e ? setSegmento(e) : setSegmento("");
        break;
      case "pais":
        e ? setPais(e) : setPais("");
        break;
      case "departamento":
        e ? setDepartamento(e) : setDepartamento("");
        break;
      case "ciudad":
        e ? setCiudad(e) : setCiudad("");
        break;
    }

    /*console.log(type);
    if (e) {
      filterCombosAux[type] = e.value;
      setFilterCombos(filterCombosAux);
      console.log(filterCombosAux);
    }
    else {
      filterCombosAux[type] = "";
      setFilterCombos(filterCombosAux);
    } */
  };

  const handleChangeCot = (cot, type) => {
    let aux = Cotizacion;
    aux[type] = cot;
    setCotizacion(aux);
  };

  const cleanFilters = () => {
    setPais("");
    setCiudad("");
    setDepartamento("");
    setRubro("");
    setSegmento("");
    setFilter("");
  };
const checkCot =(cot)=>{
  if(!Cotizacion.title || !Cotizacion.descripcion){
    return toast.error("Debes completar los campos del mail")
  }
  let cotobj = Cotizacion;
  cotobj.fornecedores = cot;
  
  history.push("/cotizacion-confirmar/?cotizacion="+encodeURIComponent(JSON.stringify(cotobj)))
  
//   swal({
//     title: "Desea enviar pedido de Cotización?",
//     text:cot.length+
//        " Proveedores recibirán el mail!",
//     icon: "info",
//     buttons: true,
//     dangerMode: false,
//  }).then((willDelete) => {
//     if (willDelete) {
//         APICall.crearCotizacion(cotobj)
//     .then(() => {
//       toast.success("Cotización enviada con exito");
    
//       history.push("/cotizaciones")
//     })
//     .catch(e => {
//       toast.error("Hubo un error mientras intentamos enviar la cotización");
//     });
//     }})


}
  return (
    <Fragment>
      <div>
        <h3 className="pt-2 pb-3">Nueva Cotización</h3>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text">Línea de asunto</span>
          </div>
          <input
 
            onChange={e => {
              handleChangeCot(e.target.value, "title");
            }}
            id="title"
            type="text"
            className="form-control"
          />
        </div>
        <Rte handleChange={t => handleChangeCot(t, "descripcion")}></Rte>
       
      </div>
      <div style={{width: "100%", height: 2,backgroundColor:"#CCCCCC",paddingLeft:30,paddingRight:50,marginTop:50,marginBottom:30}}></div>
      <div className="row">
        <div className="col-lg-12">
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="navpills-1"
              role="tabpanel"
            >
             <ListaProveedor checkCot={checkCot}></ListaProveedor>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Alldata;

const Filters = ({ handleSearchChange, filter }) => {
  return (
    <input
      type="text"
      value={filter}
      onChange={e => handleSearchChange(e)}
      className="form-control"
      placeholder="Busqueda Rapida"
    />
  );
};
