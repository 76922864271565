//import React from "react";

import React, { useContext, useState } from "react";

/// React router dom
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import RegisterProveedor from "./pages/Register-Proveedor";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import ForgotPassword from "./pages/ForgotPassword";
/// Widget
import Widget from "./pages/Widget";

/// Deshboard
import Home from "./components/Dashboard/Home/Home";


/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiMediaObject from "./components/bootstrap/MediaObject";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";







/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import SummerNote from "./components/Forms/Summernote/SummerNote";
import Pickers from "./components/Forms/Pickers/Pickers";
import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";

/// Plugin
import Select2 from "./components/PluginsMenu/Select2/Select2";
import Nestable from "./components/PluginsMenu/Nestable/Nestable";
import MainNouiSlider from "./components/PluginsMenu/Noui Slider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/Sweet Alert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/Jqv Map/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

import CompleteRegister from "./pages/Complete-register";
import { APICall } from "../API";
import GlobalContext from "../gContext";
import MeusPacientes from "./components/Dashboard/MeusPacientes/MeusPacientes";

import { Spinner } from "react-bootstrap";

import { toast } from "react-toastify";
import { io } from "socket.io-client";
import ProveedorList from "./components/AppsMenu/Shop/Proveedores/ProveedorList";
import CrudProveedor from "./components/AppsMenu/CrudProveedor";
import CotizacionList from "./components/AppsMenu/Shop/Cotizaciones/CotizacionList";
import CrudUsuario from "./components/AppsMenu/CrudUsuario";
import Recuperar from "./pages/Recuperar";
import ViewCotizacion from "./components/AppsMenu/Shop/ViewCotizacion/ViewCOtizacion";
import RenderEditProveedor from "./components/AppsMenu/PublicProveedorEditar/RenderEditProveedor";
import CreateCotizacion from "./components/AppsMenu/Shop/CreateCotizacion/CreateCotizacion";
import ConfirmCotizacion from "./components/AppsMenu/Shop/ConfirmarCotizacion/ConfirmCotizacion";
import RubroList from "./components/AppsMenu/Shop/Rubros/RubroList";

const Markup = () => {
  const gContext = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const socket = React.useRef(null);

  React.useEffect(() => {
    //Notification.requestPermission();
    APICall.verify()
      .then(res => {
       
        gContext.setUser(res.user);


     
      })
      .catch(() => {
        setLoading(false);
        gContext.setIsPublic(true);
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {};
  }, []);
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");

  const [activeEvent, setActiveEvent] = useState(!path);
  if (window.location.pathname.includes("page")) pagePath = true;

  const routes = [
    { url: "page-login", component: Login, IsPublic: true },
    { url: "recuperar-clave", component: Recuperar, IsPublic: true },
    {
      url: "page-completar-cadastro/:id",
      component: CompleteRegister,
      IsPublic: true
    },

    /// Deshborad
    { url: "", component: Home },
 

    { url: "usuarios", component: MeusPacientes },

   
    { url: "proveedores", component: ProveedorList },
    { url: "perfil-proveedor", component: RenderEditProveedor },
    
    { url: "cotizaciones", component: CotizacionList },
    { url: "cotizacion/:id/ver-cotizacion", component: ViewCotizacion },
    { url: "cotizacion/:id/nueva-cotizacion", component: CreateCotizacion },

    { url: "cotizacion-confirmar", component: ConfirmCotizacion },

    { url: "operar-proveedor/:id/:operacion", component: CrudProveedor },
    { url: "operar-usuario/:id/:operacion", component: CrudUsuario },


    { url: "erro-404", component: Error404 },
    { url: "register-proveedor", component: RegisterProveedor,IsPublic:true },
    { url: "rubros",component: RubroList}
  ];

  return (
    <>
      {loading ||
        (gContext.IsLoading && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,.7)",
              zIndex: 9999,
              justifyContent: "center",
              alignItems: "flex-start",

              display: "flex"
            }}
          >
            <Spinner
              animation="grow"
              variant="danger"
              style={{ marginTop: "45vh", position: "absolute", zIndex: 9999 }}
            />
          </div>
        ))}

      <Router basename="/">
        <Switch>
          {gContext.IsPublic &&
            routes
              .filter(x => x.IsPublic)
              .map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
          {!gContext.IsPublic &&
            routes.map((data, i) => (
              <Route
                key={i}
                exact
                path={`/${data.url}`}
                component={data.component}
              />
            ))}
          <Redirect to={gContext.IsPublic ? "/page-login" : "/erro-404"} />
        </Switch>
      </Router>
    </>
  );
};

export default Markup;
