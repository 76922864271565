import React, { useState } from "react";

/// React router dom
import { Link } from "react-router-dom";

/// images
import logo from "../../../images/logoE.png";
import logoText from "../../../images/logo-textE.png";

const NavHader = () => {
   const [toggle, setToggle] = useState(false);

   return (
      <div className="nav-header" style={{backgroundColor:"#526FCE",zIndex:99}}>
         <Link to="/" className="brand-logo" >
            <img className="logo-abbr" style={{position:"relative",right:"5vw"}} src={logo} alt="" />
            <img className="logo-compact" style={{position:"relative",right:"5"}} src={logoText} alt="" />
            <img className="brand-title" style={{position:"relative",right:"5vw"}} src={logoText} alt=""  />
         </Link>

         <div className="nav-control" style={{zIndex:99}} onClick={() => setToggle(!toggle)}>
            <div className={`hamburger ${toggle ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>
      </div>
   );
};

export default NavHader;
