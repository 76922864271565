import React from "react";
import { Redirect, useLocation } from "react-router";
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import GlobalContext from "../gContext";
export default function WrapperHeader(props) {
  const path = useLocation();
  const gContext = React.useContext(GlobalContext);
  const [activeEvent, setActiveEvent] = React.useState(!path.pathname);
  var pagePath = false
 
  if(props.roles){
    if(!props.roles.includes(gContext.user.role))
    return( <Redirect to="/"></Redirect>)
  }
  return (
    <div
      id={`${!pagePath ? "main-wrapper" : ""}`}
      className={`${!pagePath ? "show" : "mh100vh"}`}
    >
      {!pagePath && (
        <Nav
          onClick={() => setActiveEvent(!activeEvent)}
          activeEvent={activeEvent}
          onClick2={() => setActiveEvent(false)}
          onClick3={() => setActiveEvent(true)}
        />
      )}

      <div
        className={` ${!path && activeEvent ? "rightside-event" : ""} ${
          !pagePath ? "content-body" : ""
        }`}
      >
        <div className={`${!pagePath ? "container-fluid" : ""}`}>
          {props.children}
        </div>
      </div>
      {!pagePath && <Footer />}
    </div>
  );
}
