import React, { useContext } from "react";
import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/profile/17.jpg";
import avatar from "../../../images/avatar/1.jpg";
import GlobalContext from "../../../gContext";

const Header = ({ onNote, toggle, onProfile, onActivity, onNotification }) => {
  const gContext = useContext(GlobalContext);



  const logout = () => {
 
    gContext.logOut();
  };
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter(f => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter(f => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter(f => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter(f => f !== "basic")
    : filterName.includes("form")
    ? filterName.filter(f => f !== "form")
    : filterName.includes("table")
    ? filterName.filter(f => f !== "table")
    : filterName.includes("page")
    ? filterName.filter(f => f !== "page")
    : filterName.includes("email")
    ? filterName.filter(f => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter(f => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter(f => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter(f => f !== "editor")
    : filterName;

  var page_name =
    finalName.join(" ") === ""
      ? "Dashboard"
      : finalName.join(" ") == "messages"
      ? "Mensagens"
      : finalName.join(" ");

  return (
    <div className="header" style={{ backgroundColor: "#526FCE" }}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left"
                style={{ textTransform: "capitalize", color: "white",zIndex:999 }}
                >
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize", color: "white",zIndex:999 }}
              >
                {page_name}
              </div>
            </div>

            <ul className="navbar-nav header-right">
    
              <li
                className={`nav-item dropdown header-profile ${
                  toggle === "profile" ? "show" : "" }`}
                onClick={() => onProfile()}
              >
                <Link
                  to={"#"}
                  className="nav-link"
                  role="button"
                  data-toggle="dropdown"
                >
                  <img
                    src={
                      gContext.user?.picture ||
                      "https://i.ibb.co/pZ6QztG/21528471-mujer-avatar-foto-de-perfil-vector.jpg"
                    }
                    width="200"
                    alt=""
                  />
                  <div className="header-info">
                    <span className="text-white">{gContext.user?.name||gContext.user?.nombreFantasia}</span>
                    <p className="fs-12 mb-0 text-white">
                    
                    </p>
                    <p className="fs-12 mb-0 text-white">
                      Role: {gContext.user?.role}{" "}
                    </p>
                  </div>
                </Link>
                <div
                  className={`dropdown-menu dropdown-menu-right ${
                    toggle === "profile" ? "show" : ""
                  }`}
                >
                  {/* <Link
                              to="/app-profile"
                              className="dropdown-item ai-icon"
                           >
                              <svg
                                 id="icon-user1"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="text-primary"
                                 width="18"
                                 height="18"
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 stroke="currentColor"
                                 strokeWidth="2"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              >
                                 <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                 <circle cx="12" cy="7" r="4"></circle>
                              </svg>
                              <span className="ml-2">Profile </span>
                           </Link>
                           <Link
                              to="/email-inbox"
                              className="dropdown-item ai-icon"
                           >
                              <svg
                                 id="icon-inbox"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="text-success"
                                 width="18"
                                 height="18"
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 stroke="currentColor"
                                 strokeWidth="2"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              >
                                 <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                 <polyline points="22,6 12,13 2,6"></polyline>
                              </svg>
                              <span className="ml-2">Inbox </span>
                            </Link> */}
                  <Link
                    to="/page-login"
                    className="dropdown-item ai-icon"
                    onClick={() => logout()}
                    >
                    <svg
                      id="icon-logout"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-danger"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                      <polyline points="16 17 21 12 16 7"></polyline>
                      <line x1="21" y1="12" x2="9" y2="12"></line>
                    </svg>
                    <span className="ml-2">Cerrar Session </span>
                  </Link>
                </div>
                
               </li>
        
                      </ul>
                    <p></p>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
