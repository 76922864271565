import { id } from "date-fns/locale";
import {
  serverCallDelete,
  serverCallGet,
  serverCallPost,
  serverCallURL,
  serverCallPut
} from "./serverCall";

export const APICall = {
  getBucketImage: id => {
    return serverCallURL(id);
  },
  usuarios: id => {
    return serverCallGet("/api/auth/usuarios/");
  },
  crearCotizacion:(cotizacion)=>{
    return serverCallPost("/api/cotizacion/",cotizacion);
  },
  getCotizacionById: id => {
    return serverCallGet("/api/cotizacion/view/"+id);
  },
  getCotizacionesByFornecedor: id => {
    return serverCallGet("/api/cotizacion/proveedor/"+id);
  },
  getGerentes: id => {
    return serverCallGet("/api/auth/gerente/");
  },
  getAnalista: id => {
    return serverCallGet("/api/auth/analista/");
  },
  getProveedores: id => {
    return serverCallGet("/api/auth/proveedores/");
  },
  getCotizaciones: ()=> {
  
    return  serverCallGet("/api/cotizacion/");
  },

  getProvedorById: (id) => {
    return serverCallGet("/api/auth/proveedor/"+id);
  },
  getUserById: (id) => {
    return serverCallGet("/api/auth/user/"+id);
  },


  getFilters:()=>{
    return serverCallGet("/api/filters/all");
  },

  deleteProveedor: id => {
    return serverCallDelete("/api/auth/proveedor/" + id);
  },

  registerProveedor:(proveedor)=>{
    return serverCallPost("/api/auth/proveedor/",proveedor);
  },
  registerUsuario:(proveedor)=>{
    return serverCallPost("/api/auth/user/",proveedor);
  },
  resetPass:(email)=>{
    return serverCallPost("/api/auth/recover-pass/",{email});
  },
  editProveedor:(proveedor)=>{
    console.log(proveedor);
    return serverCallPut("/api/auth/proveedor/"+proveedor._id,proveedor);
  },
  editUser:(proveedor)=>{
    return serverCallPut("/api/auth/user/"+proveedor._id,proveedor);
  },
  deleteUser:id=>{
    return serverCallPost("/api/auth/users/"+id);
  },
 
  getPaciente: id => {
    return serverCallGet("/api/auth/paciente/"+id);
  },
  deleteEventtimeline: (id) => {
    return serverCallDelete("/api/eventtimeline/"+id);
  },
  deleteContenido: id => {
    return serverCallDelete("/api/contenido/delete/" + id);
  },
  getUserDataByActivationKey: id => {
    return serverCallGet("/api/auth/user-validation-key/" + id);
  },
  getChats: () => {
    return serverCallGet("/api/chat/mis-chats");
  },
  getMessages: id => {
    return serverCallGet("/api/mensajes/chat/" + id);
  },
  addContent: obj => {
    return serverCallPost("/api/contenido", obj);
  },
  sendMessages: obj => {
    return serverCallPost("/api/mensajes", obj);
  },
  editContent: (obj, id) => {
    return serverCallPut("/api/contenido/edit/" + id, obj);
  },
  getConteudos: () => {
    return serverCallGet("/api/contenido/");
  },




  preLogin: telephone => {
    return serverCallPost("/api/auth/pre-login", { telephone });
  },
  preLoginMail: (email, password) => {
    return serverCallPost("/api/auth/pre-login-email", { email, password });
  },
  preLoginMailSms: (code, oAuthSms) => {
    return serverCallPost("/api/auth/pre-login-email-sms", { code, oAuthSms });
  },
  loginGmail: tokenId => {
    return serverCallPost("/api/auth/login-gmail", { tokenId });
  },
  login: telephone => {
    return serverCallPost("/api/auth/login", telephone);
  },
  registerUser: (obj, id) => {
    return serverCallPut("/api/auth/complete-registration/" + id, obj);
  },

  verify: () => {
    return serverCallGet("/api/auth/verify");
  },

  getRubros: () => {
    return serverCallGet("/api/rubro");
  },

  createRubro:(rubro)=>{
    return serverCallPost("/api/rubro",rubro);
  },

  deleteRubro: id => {
    return serverCallDelete("/api/rubro/" + id);
  },


  editRubro: (rubro) => {
    return serverCallPut("/api/rubro",rubro);
  },

  createItemDescripcion: (obj) => {
    return serverCallPost('/api/itemDescripcion', obj)
  },

 


  //USERS
  createUser: obj => {
    return serverCallPost("/api/auth/invite-user", obj);
  },





};
