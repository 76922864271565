import * as React from "react";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import Radios from "../Radios";
import dropdownData from "../../../dropdownData";
import { APICall } from "../../../API";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9)
];

export default function ViewDataTable({ Proveedor }) {
  const [rubroOptions, setRubroOptions] = React.useState([]);

  React.useEffect(() => {

    let rubroOptionsAux = []

    APICall.getRubros().then((r) => {
      r.data.map((s) => {
        rubroOptionsAux.push(
          s['rubro']
        )
      });
      setRubroOptions(rubroOptionsAux);
    })
  }, []);
  return (
    <Col lg={12} >
      <Row>
        {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

        <Col lg={12}>
          <h4 className="mb-3">Identificación mi cuenta proveedor</h4>
          <div className="basic-form">
            <form action="#">
              <Row>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">País</span>
                    </div>
                    <select
                      className="form-control"
                      defaultValue={Proveedor.pais}
                      disabled={true}
                      value={Proveedor.pais}
                      id="pais"
                    >
                      {dropdownData.paises.map((e, i) => (
                        <option value={e}>{e}</option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">RUC / CNPJ / ID</span>
                    </div>
                    <input
                      defaultValue={Proveedor.registro}
                      disabled={true}
                      value={Proveedor.registro}
                      id="registro"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        Tipo de Proveedor
                      </span>
                    </div>

                    <select
                      className="form-control"
                      value={Proveedor.tipoProveedor}
                      id="tipoProveedor"
                      disabled={true}
                    >
                      {dropdownData.tipoDeProveedor.map((e, i) => (
                        <option value={e}>{e}</option>
                      ))}
                    </select>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Col>

        {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

        <Col lg={12}>
          <h4 className="mb-3 mt-4">Datos del Representante Legal</h4>
          <div className="basic-form">
            <form action="#">
              <Row>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        Representante Legal
                      </span>
                    </div>
                    <input
                      value={Proveedor.representanteLegal}
                      defaultValue={Proveedor.representanteLegal}
                      disabled={true}
                      id="representanteLegal"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        Documento de Identidad
                      </span>
                    </div>
                    <input
                      type="text"
                      value={Proveedor.documentoIdentidad}
                      disabled={true}
                      id="documentoIdentidad"
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Puesto</span>
                    </div>
                    <input
                      type="text"
                      value={Proveedor.puesto}
                      disabled={true}
                      id="puesto"
                      className="form-control"
                    />
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Col>
        {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}
        <Col lg={12}>
          <h4 className="mb-3 mt-4">Datos del Ejecutivo de Cuenta</h4>
          <div className="basic-form">
            <form action="#">
              <Row>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Nombre</span>
                    </div>
                    <input
                      value={Proveedor.nombreEjecutivo}
                      disabled={true}
                      id="nombreEjecutivo"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Teléfono</span>
                    </div>
                    <input
                      type="text"
                      value={Proveedor.telefonoEjecutivo}
                      disabled={true}
                      id="telefonoEjecutivo"
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Email</span>
                    </div>
                    <input
                      type="text"
                      value={Proveedor.emailEjecutivo}
                      disabled={true}
                      id="emailEjecutivo"
                      className="form-control"
                    />
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Col>
        {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

        <Col lg={12}>
          <h4 className="mb-3 mt-4">Datos del Proveedor</h4>
          <div className="basic-form">
            <form action="#">
              <Row>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        Razón Social / Nombre
                      </span>
                    </div>
                    <input
                      value={Proveedor.razonSocial}
                      disabled={true}
                      id="razonSocial"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        Nombre de Fantasía
                      </span>
                    </div>
                    <input
                      type="text"
                      value={Proveedor.nombreFantasia}
                      disabled={true}
                      id="nombreFantasia"
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        Tipo de Actividad
                      </span>
                    </div>
                    <select
                      className="form-control"
                      value={Proveedor.actividad}
                      id="actividad"
                      disabled={true}
                    >
                      {dropdownData.tipoActividad.map((e, i) => (
                        <option value={e}>{e}</option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        Inicio de Actividades
                      </span>
                    </div>
                    <input
                      type="date"
                      value={Proveedor.inicioActividad}
                      disabled={true}
                      id="inicioActividad"
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        Capital Social (USD)
                      </span>
                    </div>
                    <input
                      type="number"
                      value={Proveedor.capitalSocial}
                      disabled={true}
                      id="capitalSocial"
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Nº de empleados</span>
                    </div>
                    <input
                      type="number"
                      value={Proveedor.cantidadEmpleados}
                      disabled={true}
                      id="cantidadEmpleados"
                      className="form-control"
                    />
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Col>

        {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

        <Col lg={12}>
          <h4 className="mb-3 mt-4">Datos de Ubicación</h4>
          <div className="basic-form">
            <form action="#">
              <Row>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        Departamento/Estado
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={Proveedor.estado}
                      disabled={true}
                      id="estado"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Ciudad</span>
                    </div>
                    <input
                      type="text"
                      value={Proveedor.ciudad}
                      disabled={true}
                      id="ciudad"
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        Teléfono (Característica)
                      </span>
                    </div>
                    <input
                      type="text"
                      value={Proveedor.tipoTelephone}
                      disabled={true}
                      id="tipoTelephone"
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        Teléfono (Número)
                      </span>
                    </div>
                    <input
                      type="text"
                      value={Proveedor.telephone}
                      disabled={true}
                      id="telephone"
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Dirección</span>
                    </div>
                    <input
                      value={Proveedor.calle}
                      disabled={true}
                      id="calle"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Sitio Web</span>
                    </div>
                    <input
                      value={Proveedor.website}
                      disabled={true}
                      id="website"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Email</span>
                    </div>
                    <input
                      value={Proveedor.email}
                      disabled={true}
                      id="email"
                      type="mail"
                      className="form-control"
                    />
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Col>

        {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

        <Col lg={12}>
          <h4 className="mb-3 mt-4">Productos y/o Servicios</h4>
          <div className="basic-form">
            <form action="#">
              <Row>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Segmento</span>
                    </div>
                    <select
                      className="form-control"
                      value={Proveedor.segmento}
                      id="segmento"
                      disabled={true}
                    >
                      {dropdownData.segmento.map((e, i) => (
                        <option value={e}>{e}</option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Rubro</span>
                    </div>

                    <select
                      className="form-control"
                      value={Proveedor.rubro}
                      id="rubro"
                      disabled={true}
                    >
                      {rubroOptions.map((e, i) => (
                        <option value={e}>{e}</option>
                      ))}
                    </select>
                  </div>
                </Col>

                <Col lg={12}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        Detalle de Producto/ Servicio
                      </span>
                    </div>
                    <input
                      type="text"
                      value={Proveedor.detalle}
                      disabled={true}
                      id="detalle"
                      className="form-control"
                    />
                  </div>
                </Col>

              </Row>
            </form>
          </div>
        </Col>


      </Row>
    </Col>
  );
}
