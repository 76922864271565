import { Card } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Row, Table, Col, Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router";
import { APICall } from "../../../../API";
import GlobalContext from "../../../../gContext";
import { Link } from "react-router-dom";
import roles from "../../../../rolesTableData";
export default function RenderForm({
  proveedorBack,
  registerProveedor,
  guardarEditProveedor,
  editarProveedor,
  parentProveedor,
  deleteUserA
}) {
  const { id, operacion } = useParams();
  const history = useHistory();
  const [Proveedor, setProveedor] = React.useState(proveedorBack);
  const [openedRoleDetail, setOpenedRoleDetail] = React.useState(false);
  const [ProveedorOriginal, setProveedorOriginal] =
    React.useState(proveedorBack);

  const gContext = React.useContext(GlobalContext);


  useEffect(() => {
    if (Object.keys(proveedorBack).length == 0) {
      setProveedor({ role: "analista" });
      parentProveedor({ role: "analista" });
      setProveedorOriginal({ role: "analista" });
    } else {
      setProveedor(proveedorBack);
      parentProveedor(proveedorBack);
      setProveedorOriginal(proveedorBack);
    }
  }, [proveedorBack]);

  const handleChangeText = e => {
    let proveedorAux = { ...Proveedor };
    proveedorAux[e.target.id] = e.target.value;
    parentProveedor(proveedorAux);
    setProveedor(proveedorAux);
  };

  const buttonSubmit = () => {
    const iconStyle = {
      fontSize: "1.2rem"
    };
    const buttonLabel = {
      "registrar-usuario": "Registrar usuario",
      "consultar-usuario": "Editar usuario",
      "editar-usuario": "Guardar Cambios",
      "eliminar-usuario": "Eliminar Usuario",
    };
    const buttonIcon = {
      "registrar-usuario": (
        <i class="las la-plus-circle " style={iconStyle}></i>
      ),
      "consultar-usuario": <i class="las la-edit" style={iconStyle}></i>,
      "editar-usuario": <i class="las la-check" style={iconStyle}></i>,
      "eliminar-usuario": <i class="las la-trash" style={iconStyle}></i>
    };

    const method = {
      "registrar-usuario": () => registerProveedor(Proveedor),
      "consultar-usuario": () => editarProveedor(),
      "editar-usuario": () => guardarEditProveedor(Proveedor),
      "eliminar-usuario": () => deleteUserA(Proveedor._id)
    };
    return (
      <Button variant="primary" size={"sm"} onClick={method[operacion]}>
        {buttonIcon[operacion]}
        <span style={{ paddingLeft: 10 }}>{buttonLabel[operacion]}</span>
      </Button>
    );
  };
 
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="navpills-1"
              role="tabpanel"
            >
              <Table
                responsive
                className="table-responsive card-table rounded table-hover fs-14"
              >
                <div id="example_wrapper" className="dataTables_wrapper">
                  <div id="example" className="card card-body">
                    <Row className="pl-2 pr-2">
                      <Col lg={12}>
                        <Row>
                          <Col lg={2} md={5} sm={6} xs={12}>
                            <Button
                              size={"sm"}
                              variant="primary"
                              style={{
                                borderRadius: 5,
                                width: "100%",
                                marginBottom: 10,
                                cursor: "default"

                              }}
                            >
                              Datos del usuario
                            </Button>
                          </Col>

                        </Row>
                      </Col>
                      <Col lg={12}>
                        <Row>
                          {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

                          <Col lg={12}>
                            <h4 className="mb-3 mt-5">
                              Identificación del usuario
                            </h4>
                            <div className="basic-form">
                              <form action="#">
                                <Row>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Nombre
                                        </span>
                                      </div>
                                      <input
                                        defaultValue={Proveedor.name}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        value={Proveedor.name}
                                        onChange={handleChangeText}
                                        id="name"
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Apellido
                                        </span>
                                      </div>
                                      <input
                                        defaultValue={Proveedor.lastName}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        value={Proveedor.lastName}
                                        onChange={handleChangeText}
                                        id="lastName"
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Correo Electronico{" "}
                                        </span>
                                      </div>
                                      <input
                                        defaultValue={Proveedor.email}
                                        disabled={!operacion.includes(
                                          "registrar"
                                        )}
                                        value={Proveedor.email}
                                        onChange={handleChangeText}
                                        id="email"
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <label className="input-group-text">
                                          Perfil
                                        </label>
                                      </div>
                                      <select className="form-control"
                                        value={Proveedor.role}
                                        onChange={handleChangeText}
                                        id="role"
                                      >
                                        <option
                                          selected
                                          value="analista"
                                        >
                                          Analista
                                        </option>
                                        <option value="gerente">
                                          Gerente
                                        </option>
                                      </select>
                                    </div>
                                  </Col>
                                </Row>
                              </form>
                            </div>
                          </Col>

                          {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

                          <Col lg={12}>
                            <h4 className="mb-3 mt-4">
                              Detalles del usuario
                            </h4>
                            <div className="basic-form">
                              <form action="#">
                                <Row>
                                  <Col lg={12}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Descripción
                                        </span>
                                      </div>
                                      <textarea
                                        value={Proveedor.detalle}
                                        defaultValue={
                                          Proveedor.detalle
                                        }
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        rows={4}
                                        onChange={handleChangeText}
                                        id="detalle"
                                        type="textarea"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>

                                </Row>
                              </form>
                            </div>
                          </Col>
                          <Col lg={12}>


                            <div className="input-group mb-3" onClick={() => setOpenedRoleDetail(!openedRoleDetail)}>

                              <h4 style={{ userSelect: "none", MozUserSelect: "none" }} className="mb-3 mt-5">
                                <i className={openedRoleDetail ? "fa fa-chevron-down mr-2" : "fa fa-chevron-right mr-2"}></i>
                                Ver detalle de permisos para el perfil {Proveedor.role}
                              </h4>

                            </div>
                          </Col>
                          {openedRoleDetail &&
                            <Col lg={6} >
                              <table
                                id="example"
                                className="table border-no display mb-4 dataTablesCard table-responsive-xl project-bx"

                              >
                                <thead >
                                  <tr role="row">

                                    <th >
                                      Funcionalidad
                                    </th>
                                    <th >
                                      Permiso
                                    </th>


                                  </tr>
                                </thead>
                                <tbody >
                                  {

                                   roles.roles[Proveedor.role].map((p) => (
                                      <tr role="row">
                                        <td >
                                          {p.permission}
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <span className="bolt-icon mr-sm-3 mr-2"><i style={p.value?{}:{color:"red"}}className={p.value?"fa fa-check":"fa fa-times"} aria-hidden="true"></i></span>
                                            {}
                                          </div>
                                        </td>
                                      </tr>
                                    ))}

                                </tbody >
                              </table>
                            </Col>

                          }

                          {/* BOTOES DE ABAJO */}

                          <Col sm={12} className="">
                            <Row className="mt-5 ">
                              <Col lg={6} sm={12} className="mb-2">
                                <Row style={{ justifyContent: "flex-start" }}>
                                  {
                                    (operacion.includes("consultar") ||   operacion.includes("eliminar") ||
                                      operacion.includes("editar")) && (
                                      <div></div>
                                    )
                                    // <Button variant="danger" onClick={() => history.push("/usuarios")} size={"sm"}>
                                    //   <svg
                                    //     width="14"
                                    //     height="14"
                                    //     viewBox="0 0 14 14"
                                    //     fill="none"
                                    //     xmlns="http://www.w3.org/2000/svg"
                                    //   >
                                    //     <path
                                    //       fill-rule="evenodd"
                                    //       clip-rule="evenodd"
                                    //       d="M13.3178 0.875026C12.8345 0.391758 12.051 0.391712 11.5678 0.874923L7.19204 5.25L2.8175 0.875078C2.33427 0.391799 1.55074 0.391798 1.0675 0.875077C0.584326 1.3583 0.584327 2.1417 1.0675 2.62492L5.44219 7L1.0675 11.3751C0.584327 11.8583 0.584326 12.6417 1.0675 13.1249C1.55074 13.6082 2.33427 13.6082 2.8175 13.1249L7.19204 8.75L11.5678 13.1251C12.051 13.6083 12.8345 13.6082 13.3178 13.125C13.801 12.6417 13.801 11.8583 13.3178 11.375L8.94312 7L13.3178 2.62497C13.801 2.14173 13.801 1.35827 13.3178 0.875026Z"
                                    //       fill="white"
                                    //     />
                                    //   </svg>

                                    //   <span style={{ paddingLeft: 10 }}>
                                    //     Eliminar Proveedor
                                    //   </span>
                                    // </Button>
                                  }
                                </Row>
                              </Col>
                              <Col lg={6} sm={12}>
                                <Row style={{ justifyContent: "flex-end" }}>
                                  <Button
                                    variant="outline-primary badge-rounded pr-4"
                                    size={"sm"}
                                    onClick={() => history.push("/usuarios")}
                                    style={{
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      display: "flex",
                                      marginRight: 10
                                    }}
                                  >
                                    <svg
                                      width="12"
                                      height="15"
                                      viewBox="0 0 12 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M11.0807 1.03909C10.9785 0.985891 10.8672 0.959291 10.7559 0.959291C10.6159 0.959291 10.4773 1.00129 10.3576 1.08389L1.25757 7.38389C1.06857 7.51479 0.955872 7.72969 0.955872 7.95929C0.955872 8.18889 1.06857 8.40379 1.25757 8.53469L10.3576 14.8347C10.5711 14.9824 10.8504 14.9999 11.0807 14.8795C11.3117 14.7584 11.4559 14.5197 11.4559 14.2593L11.4559 1.65929C11.4559 1.39889 11.3117 1.16019 11.0807 1.03909Z"
                                        fill="#526FCE"
                                      />
                                    </svg>
                                    <span style={{ paddingLeft: 10 }}>
                                      Volver para la lista
                                    </span>
                                  </Button>
                                  {buttonSubmit()}
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Table>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
}
