const paises = [
  "Afganistán",
  "Alemania",
  "Arabia Saudita",
  "Argentina",
  "Australia",
  "Bélgica",
  "Bolivia",
  "Brasil",
  "Camboya",
  "Canadá",
  "Chile",
  "China",
  "Colombia",
  "Corea",
  "Costa Rica",
  "Cuba",
  "Dinamarca",
  "Ecuador",
  "Egipto",
  "El Salvador",
  "Escocia",
  "España",
  "Estados Unidos",
  "Estonia",
  "Etiopia",
  "Filipinas",
  "Finlandia",
  "Francia",
  "Gales",
  "Grecia",
  "Guatemala",
  "Haití",
  "Holanda",
  "Honduras",
  "Indonesia",
  "Inglaterra",
  "Irak",
  "Irán",
  "Irlanda",
  "Israel",
  "Italia",
  "Japón",
  "Jordania",
  "Laos",
  "Letonia",
  "Lituania",
  "Malasia",
  "Marruecos",
  "México",
  "Nicaragua",
  "Noruega",
  "Nueva Zelanda",
  "Nueva Zelandia",
  "Panamá",
  "Paraguay",
  "Perú",
  "Polonia",
  "Portugal",
  "Puerto Rico",
  "Republica Dominicana",
  "Rumania",
  "Rusia",
  "Suecia",
  "Suiza",
  "Tailandia",
  "Taiwán",
  "Turquía",
  "Ucrania",
  "Uruguay",
  "Venezuela",
  "Vietnam"
];

const tipoDeProveedor = [
  "ADES",
  "C.E.I.S.A.",
  "C.I.S.A.",
  "COASEGURO",
  "CONSORCIO",
  "COOPERATIVAS",
  "E.I.R.L.",
  "EXTRANJERAS - PERSONA FÍSICA NO RESIDENTE EN EL PAÍS",
  "EXTRANJERAS - PERSONA JURÍDICA SIN SUCURSAL EN EL PAÍS",
  "GRUPO COASEGURADOR",
  "PERSONA FÍSICA - BIENES Y SERVICIOS",
  "PERSONA FÍSICA - SERVICIOS PERSONALES",
  "S.A.",
  "S.A.C",
  "S.A.C.I.",
  "S.A.C.I.G.",
  "S.A.E.",
  "S.A.E.C.A.",
  "S.A.I.C.",
  "OTROS"
];

const tipoActividad = ["Bienes", "Servicios", "Bienes y Servicios","Subcontratista"];

const segmento = [
  "CATÁLOGO DE PIEZAS",
  "SERVICIOS DE CONSULTORÍA",
  "CONSTRUCCIÓN CIVIL",
  "MATERIA PRIMA",
  "SERVICIOS",
  "MONTAJE",
  "EQUIPOS DE PROYECTO",
  "SERVICIOS FORESTALES",
  "OTROS"
];
const rubro = [
  'ACCESORIOS ELECTRICOS',
  'ACERO INOXIDABLE',
  'ACEROS',
  'ACONDICIONADORES DE AIRE',
  'ADITIVOS',
  'AISLACIONES',
  'AISLAPANELES',
  'ALAMBRES',
  'ALFOMBRAS',
  'ALQUILER',
  'ARIDOS',
  'ARTEFACTOS ELECTRICOS', 
  'ASCENSORES',
  'ASFALTOS Y EMULASIONES',
  'AUTOMOVILES',
  'BASCULAS Y BALANZAS',
  'BOMBAS Y MOTOBOMBAS DE AGUA',
  'BULONRIA',
  'CABLES',
  'CABOS, ESLINGAS Y ACCESORIOS',
  'CAMIONES',
  'CARTELERIA',
  'CCTV',
  'CEMENTOS',
  'CERAMICOS',
  'CIELORRASO Y MAMPARAS',
  "COMBUSTIBLES (GASOIL, NAFTA, FUEL OIL, ETC.)",
  'DEFENSAS METALICAS',
  'ELECTRICIDAD',
  'ELECTRODOMESTICOS',
  'EQUIPAMIENTO DE VEHICULOS',
  'EQUIPOS INFORMATICOS',
  'EXPLOSIVOS Y ACCESORIOS',
  'FARMACIA',
  'FERRETERIA',
  'FILTROS PARA MAQUINAS',
  'FLETE',
  'GAVIONES',
  'GENERADORES',
  'GRIFERIAS',
  'HERRAJES',
  'HERRAMIENTAS MENORES',
  'HIERROS',
  'HORMIGON',
  'IMPERMEABILIZANTES',
  'INSTRUMENTOS DE MEDICION',
  'JUNTAS',
  'LABORATORIO DE SUELO, ASFALTO Y HORMIGON',
  'LIBRERIA',
  "LIGANTES",
  'LIMPIEZA',
  'LUBRICANTES (ACEITES, FLUIDOS, ETC.)',
  'MADERAS (PUNTALES, FENOLICA, TABLAS, VIGAS, ETC.)',
  'MANGUERAS HIDRAULICAS',
  'MAQUINARIAS',
  'MATERIAL PETREO',
  'MOTOCICLETAS',
  'MUEBLES',
  'MUELLES',
  'NEOPRENO',
  'NEUMATICOS',
  'PINTURAS',
  'PISOS Y REVESTIDOS',
  'POZOS ARTESIANOS',
  'PREFABRICADOS',
  'PRETENSADOS',
  'PRODUCTOS QUIMICOS (ACLARAR)',
  'PROTECCION CONTRA INCENDIOS ACTIVA & PASIVA (PCI)',
  'PVC (ACLARAR MATERIAL)',
  'REPUESTOS (ACLARAR MARCAS)',
  'RULEMAN',
  'SANITARIOS (ACLARAR MATERIALES)',
  'SEÑALES DE TRANSITO (ACLARAR QUE TIPOS)',
  'SEÑALES DEBILES',
  'SERVICIOS (ACLARAR RUBROS)',
  'SOFTWARE (ACLARAR FUNCIONES)',
  'SOLDADURA Y OXICORTE',
  'TANQUES (ACLARAR QUE TIPO, DE QUE MATERIAL SE FABRICA Y QUE CONTENDRA)',
  'TERMOCALEFONES',
  'TIERRA',
  'TRANSFORMADORES',
  'TUBOS Y ACCESORIOS PEAD',
  'TUBOS Y ACCESIRIOS TERMO FUSION',
  'VARILLAS DE HIERRO',
  'VEHICULOS LIVIANOS (AUTOS, CAMIONETAS)',
  'VIDRIOS',
  'EXTINTORES'
  // "MADERA",
  // "METALES",
  // "MANTENIMIENTOS DE MAQUINAS Y VEHICULOS",
  // "AISLACION",
  // "PLOMERIA",
  // "ARTEFACTOS SANITARIOS",
  // "ARTEFACTOS ELECTRICOS",
  // "ARTICULOS ELECTRICOS",
  // "PINTURA",
  // "VIDRIOS",
  // "GRIFERIAS",
  // "GENERADORES",
  // "TRANSFORMADORES",
  // "EXPLOSIVOS Y ACCESORIOS",
  // "HERRAJES",
  // "MUEBLES",
  // "MAQUINARIA MAYOR",
  // "MAQUINARIA MENOR",
  // "ELECTRODOMESTICOS",
  // "HERRAMIENTAS",
  // "BOMBAS",
  // "PREFABRICADOS DE HORMIGON",
  // "DEFENSAS METALICAS",
  // "ABERTURAS",
  // "TANQUES DE AGUA",
  // "EPI",
  // "CEMENTOS ASFALTICO",
  // "EMULSIONES ASFALTICAS",
  // "EQUIPOS DE LABORATORIO",
  // "EQUIPOS DE TOPOGRAFIA",
  // "INFORMATICA",
  // "ARTICULOS DE LIBRERÍA",
  // "IMPRENTA",
  // "REPUESTOS",
  // "ARTICULOS DE LIMPIEZA",
  // "SEÑALIZACION" ,
  // "FLETES",
  // "SUBCONTRATOS",
  // "SERVICIOS",
  // "ALQUILERES",
  // "OTROS"
];

let forms = [
  {
    question: "¿La empresa tiene habilitación legal en el país?",
    options: ["Sí", "No", "No Aplicable"],
    respuesta: "Sí"
  },
  {
    question:
      "¿Tiene la empresa alguna certificación (Calidad, Preservación del Medio Ambiente, Seguridad, Responsabilidad Social, etc.)?",
    options: ["Sí", "No", "No Aplicable"],
    respuesta: "Sí"
  },
  {
    question:
      "¿La empresa y los empleados tienen suficiente experiencia y trayectoria para cumplir con los servicios y / o productos ofrecidos?",
    options: ["Sí", "No", "No Aplicable"],
    respuesta: "Sí"
  },
  {
    question:
      "¿Cuenta la empresa con un responsable de seguridad y salud ocupacional, registrado en el MTESS u otro específico de su país?",
    options: ["Sí", "No", "No Aplicable"],
    respuesta: "Sí"
  },
  {
    question:
      "¿Los empleados están capacitados en sus tareas específicas y en temas de seguridad y salud ocupacional? ¿Tiene la empresa estos registros de formación?",
    options: ["Sí", "No", "No Aplicable"],
    respuesta: "Sí"
  },
  {
    question:
      "¿La Compañía cumple con las leyes laborales en cuanto a salarios, horas de trabajo y beneficios (salud y jubilaciones)?",
    options: ["Sí", "No", "No Aplicable"],
    respuesta: "Sí"
  },
  // {
  //   question:
  //     "¿Se respeta la libertad sindical y sindical de los trabajadores?",
  //   options: ["Sí", "No", "No Aplicable"],
  //   respuesta: "Sí"
  // },
  {
    question:
      "¿Cuenta la empresa con condiciones adecuadas para el buen desempeño y calidad de vida de sus empleados, como: equipos, muebles, baños, acomodaciones, etc.?",
    options: ["Sí", "No", "No Aplicable"],
    respuesta: "Sí"
  },
  {
    question:
      "¿La empresa cuenta con una Comisión Interna de Prevención de Accidentes?",
    options: ["Sí", "No", "No Aplicable"],
    respuesta: "Sí"
  },
  {
    question:
      "¿La empresa cuenta con una persona / departamento responsable del medio ambiente?",
    options: ["Sí", "No", "No Aplicable"],
    respuesta: "Sí"
  },
  {
    question: "¿Tiene la empresa un sistema de gestión ambiental documentado?",
    options: ["Sí", "No", "No Aplicable"],
    respuesta: "Sí"
  },
  {
    question:
      "¿La empresa desarrolla prácticas de uso racional de energía y agua?",
    options: ["Sí", "No", "No Aplicable"],
    respuesta: "Sí"
  },
  {
    question:
      "¿Tiene la empresa un sistema de gestión de productos químicos adecuado?",
    options: ["Sí", "No", "No Aplicable"],
    respuesta: "Sí"
  },
  {
    question: "¿Tiene la empresa un sistema de gestión de residuos adecuado?",
    options: ["Sí", "No", "No Aplicable"],
    respuesta: "Sí"
  },
  {
    question: "¿está dispuesto a seguir nuestro código de conducta ?",
    options: ["Sí", "No"],
    respuesta: "Sí"
  },

  {
    question:
      "¿está dispuesto a seguir el cumplimiento del manual para servicios compartido y gestión de personas de Tecnoedil?",
    options: ["Sí", "No"],
    respuesta: "Sí"
  },
  {
    question:
      "¿está dispuesto a aceptar nuestros términos y condiciones generales?",
    options: ["Sí", "No"],
    respuesta: "Sí"
  },
  {
    question:
      "¿está dispuesto a que Tecnoedil o algún representante de Tecnoedil verifique su empresa constantemente?",
    options: ["Sí", "No"],
    respuesta: "Sí"
  }
];
const calificacion=["No evaluado","Rechazado","Bueno","Muy bueno","Excelente"]




export default {
  paises,
  tipoDeProveedor,
  rubro,
  segmento,
  tipoActividad,
  calificacion,
  forms
};
