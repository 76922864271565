import React, { useState } from "react";
import { ContentState } from "react-draft-wysiwyg";
import { toast } from "react-toastify";

const GlobalContext = React.createContext();

const GlobalProvider = ({ children }) => {
  const [IsLoading, setIsLoading] = useState(false);
  const [IsPublic, setIsPublic] = useState(false);

  const [user, saveUser] = useState({
    picture: "",
    name: "",
    role: "",
 
  });
  const [changeUpdateList, setChangeUpdateList] = useState(false);

  let parsedUserName = `${translateRole(user.role)} ${
    user.especialidadPrincipal || ""
  }`;
  const logOut = () => {
    saveUser({ picture: "", name: "", role: "", especialidadPrincipal: "" });
    window.localStorage.clear();
    toast.success("Logout Realizado com sucesso !", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };
  const reHydrate = async () => {

  };

  React.useEffect(() => {
    reHydrate();
  }, []);

  const setUser = async user => {
    saveUser(user);
  };

 
  return (
    <GlobalContext.Provider
      value={{
        setIsLoading,
        IsLoading,
        setUser,
        changeUpdateList,
        IsPublic,
        setIsPublic,
        user,
        logOut,
      
        parsedUserName,
      
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalProvider };

const translateRole = role => {
  switch (role) {

    case "admin":
      return "ADMIN";
    case "analista":
    return "analista";
    default:
      return "";
      break;
  }
};
