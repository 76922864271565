import React, { Fragment, useState, useRef, useEffect, useContext } from "react";
import GlobalContext from "../../gContext";
function useAuthRole(role=[]) {
    const gContext = useContext(GlobalContext);
    console.log(gContext.user)
  if(gContext.user?.role){
      if(role.includes(gContext.user?.role)){
          return true
      }
  }
    return false;
  }

  export default useAuthRole