import React, { useContext } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";
import { Link } from "react-router-dom";
import { Component } from "react";
import GlobalContext from "../../../../gContext";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    //this.mm("dispose");
    this.mm.dispose();
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={el => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}
function RoutesAdmin({ onClick, onClick3, path = "", handleShow }) {
  const gContext = useContext(GlobalContext);
 
  return (
    <PerfectScrollbar className="dlabnav-scroll">
      <MM className="metismenu" id="menu">
        <li
          className={`${[""].includes(path.toLowerCase()) ? "mm-active" : ""}`}
        >
          <Link className="ai-icon" to="/">
           <i className="flaticon-381-notification"></i>

            <span className="nav-text">Dashboard</span>
          </Link>
        </li>
        <li
          className={`${
            ["proveedores", "registrar-proveedor","operar-proveedor","editar-proveedor","consultar-proveedor"].includes(path.toLowerCase())
              ? "mm-active"
              : ""
          }`}
        >
          <Link className="has-arrow ai-icon" to="#">
           <i className="flaticon-381-id-card-2"> </i>

            <span
              className="nav-text"
              style={{
                color: ["proveedores", "registrar-proveedor"].includes(
                  path.toLowerCase()
                )
                  ? "#526FCE"
                  : "#797979"
              }}
            >
              Proveedores
            </span>
          </Link>
          <ul>
            <li>
              <Link
                className={`${path === "proveedores" ? "mm-active" : ""}`}
                onClick={() => onClick()}
                to="/proveedores"
              >
                Todos los proveedores
              </Link>
            </li>
            <li>
              <Link
                className={`${path === "registrar-proveedor" ? "mm-active" : ""}`}
                onClick={() => onClick()}
                to="/operar-proveedor/new/registrar-proveedor"
              >
                Registrar proveedor
              </Link>
            </li>
            
          </ul>
        </li>

        <li
          className={`${
            ["cotizaciones", "nueva-cotizacion"].includes(path.toLowerCase())
              ? "mm-active"
              : ""
          }`}
        >
          <Link className="has-arrow ai-icon" to="#">
          <i className="flaticon-381-folder-17"></i>

            <span className="nav-text">Cotizaciones</span>
          </Link>
          <ul>
            <li>
              <Link
                className={`${path === "cotizaciones" ? "mm-active" : ""}`}
                onClick={() => onClick()}
             to="/cotizaciones"
              >
                Ver Cotizaciones
              </Link>
            </li>
            <li>
              <Link
                className={`${path === "nueva-cotizacion" ? "mm-active" : ""}`}
                onClick={() => onClick()}
             to="/cotizacion/new/nueva-cotizacion"
              >
                Nueva Cotización
              </Link>
            </li>
           
          </ul>
        </li>

        <li
          className={`${
            ["usuarios","registrar-usuario"].includes(path.toLowerCase()) ? "mm-active" : ""
          }`}
        >
          <Link className="has-arrow ai-icon" to="#">
          <i className="flaticon-381-user"></i>

            <span className="nav-text">Usuarios</span>
          </Link>
          <ul>
            <li>
              <Link
                className={`${path === "usuarios" ? "mm-active" : ""}`}
                onClick={() => onClick()}
                to={"/usuarios"}
              >
                Ver Usuarios
              </Link>
            </li>
            <li>
              <Link
                className={`${path === "registrar-usuario" ? "mm-active" : ""}`}
                onClick={() => onClick()}
                to={"/operar-usuario/new/registrar-usuario"}
              >
                Registrar Usuario
              </Link>
            </li>
          </ul>
        </li>
      </MM>
      <div className="">
        <Link
          to={"/operar-proveedor/new/registrar-proveedor"}
          className="add-menu-sidebar d-block"
       
          data-toggle="modal"
          data-target="#addOrderModalside"
        >
          + Registrar <bold>Proveedor</bold>
        </Link>
      </div>
      {/* <div className="">
        <Link
          to={"/operar-usuario/new/registrar-usuario"}
          className="add-menu-sidebar d-block"
          data-toggle="modal"
          data-target="#addOrderModalside"
        >
          + Registrar Usuario
        </Link>
      </div> */}
   

   <div className="">
        <Link
          to={"/cotizacion/new/nueva-cotizacion"}
          className="add-menu-sidebar d-block"
          data-toggle="modal"
          data-target="#addOrderModalside"
        >
          + Nueva Cotización
        </Link>
        <Link
          to={"/rubros"}
          className="add-menu-sidebar d-block"
          data-toggle="modal"
          data-target="#addOrderModalside"
        >
          + Registrar Rubro
        </Link>
      </div>

      <div className="copyright">
        <p>
          <strong>Tecnoedil</strong> © 2021 Todos los derechos reservados
        </p>
        <p>Powered by InfiniteDev</p>
      </div>
    </PerfectScrollbar>
  );
}

export default RoutesAdmin;
