import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../images/imagem-banner.png";
import logoSol from "../../images/tecnoedil.png";
import backGround from "../../images/banner-2.jpg";
import { GoogleLogin } from "react-google-login";
import Invitacion from "../components/registerCompleteFOrm/Invitacion";

const CompleteRegister = () => {
  const [CompleteRegisterData, setCompleteRegisterData] = useState({});
  const history = useHistory();

  const responseGoogle = async response => {
    //
   
    const res = await fetch(
      "https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=" +
        response.tokenId
    );
    console.log(res, 'apiiii')
    let { name, picture, email } = await res.json();
  
    window.localStorage.setItem("email", email);
    window.localStorage.setItem("name", name);

    window.localStorage.setItem("picture", picture);
    history.push("/");
  };
  return (
    <div className="vh-100" style={{ height: "100%" }}>
      <div classNameName="container-fluid h-custom">
      <div
          className="row d-flex justify-content-start align-items-center h-30"
          style={{
            backgroundColor: "#526FCE",
            boxShadow: "0px 5px 5px -2px rgba(0,0,0,0.21)"
          }}
        >
          <img
            src={logoSol}
            className="img-fluid"
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              marginLeft: "10%"
            }}
            width={"280vw"}
            alt="Logo"
          />
        </div>

        <div className="row d-flex justify-content-center align-items-center h-100">
        <div
            className="col-md-9 col-lg-6 col-xl-5 mt-5"
            style={{
              backgroundColor: "#FF064C",
              borderRadius: "40px",
              backgroundImage: `url(${backGround})`,
              backgroundSize: "auto",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right top"
            }}
          >
            <img src={logo} className="img-fluid " alt="Sample image" />
          </div>
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            <form>
              <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                {/* <p className="lead fw-normal mb-0 me-3 mr-5">
                  <strong>Entrar com:</strong>
                </p> */}
                {/* <button type="button" className="btn btn-primary btn-floating mx-3">
                  <i className="fa fa-facebook-f"></i>
                </button> */}

                <h2 classNameName="mb-5 " style={{ textAlign: "center" }}>
                  Bem-Vindo à plataforma de Tecnoedil!
                </h2>
              </div>

              <div className="form-outline mb-4">
                <label className="form-label" for="form3Example3">
                  E-mail
                </label>
                <input
                  type="email"
                  id="form3Example3"
                  className="form-control form-control-lg"
                  placeholder="Digite um endereço de e-mail válido"
                />
              </div>

              <div className="form-outline mb-3">
                <label className="form-label" for="form3Example4">
                  Senha
                </label>
                <input
                  type="password"
                  id="form3Example4"
                  className="form-control form-control-lg"
                  placeholder="Digite a senha"
                />
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div className="form-check mb-0">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    value=""
                    id="form2Example3"
                  />
                  <label className="form-check-label" for="form2Example3">
                    Mantenha-me conectado
                  </label>
                </div>
                <a href="#!" className="text-body">
                  Esqueceu a senha?
                </a>
              </div>

              <div className="text-center text-lg-start mt-4 pt-2">
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  style={{
                    paddingLeft: "2.5rem",
                    paddingRight: "2.5rem",
                    width: "100%"
                  }}
                >
                  Entrar
                </button>

                <div className="divider d-flex align-items-center my-4">
                  <p className="text-center fw-bold mx-3 mb-0">Ou</p>
                </div>

              
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          width: "100%",
       top:50,
       height: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          zIndex: 99,
       

        }}
      >
        <div className="col-md-8 col-lg-6 col-xl-6 mt-5" style={{ }}>
          <Invitacion></Invitacion>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "black",
          position: "fixed",
          opacity: 0.8,
          zIndex: 90,
          width: "100%",
          height: "100%",
          top: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      ></div>
    </div>
  );
};

export default CompleteRegister;
