import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../images/imagem-banner.png";
import logoSol from "../../images/tecnoedil.png";
import backGround from "../../images/banner-2.jpg";
import { GoogleLogin } from "react-google-login";
import { APICall } from "../../API";
import GlobalContext from "../../gContext";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import WrapperHeader from "../wrapperHeader";
import ReactCodeInput from "react-verification-code-input";

const Login = () => {
  const [IsLoaded, setIsLoaded] = useState(false);
  const [OAuthSms, setOAuthSms] = useState("");
  const [Tel, setTel] = useState("");
  const gContext = useContext(GlobalContext);

  const history = useHistory();

  const login = (password, email) => {
    setIsLoaded(false);
    APICall.preLoginMail(password, email)
      .then(res => {
        window.localStorage.setItem("jwt", res.token);
        
        gContext.setIsPublic(false);
     
        gContext.setUser(res.user);

        toast.success("✅ Conectado a Tecnoedil!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        if (res.user.role == "proveedor") {
          history.push("/perfil-proveedor");
        } else {
          history.push("/");
        }
      })
      .catch(() => {
        toast.error("❌ No se pudo conectar!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });
  };

  const sendUser = code => {
    setIsLoaded(true);
    APICall.preLoginMailSms(code, OAuthSms).then(res => {
      window.localStorage.setItem("jwt", res.token);

      gContext.setIsPublic(false);
     
      gContext.setUser(res.user);

      toast.success("✅ Conectado a Tecnoedil!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
   
        history.push("/");
     
    });
  };
  const responseGoogle = async response => {
    //
   

    APICall.loginGmail(response.tokenId)
      .then(res => {
        window.localStorage.setItem("jwt", res.token);
   
        gContext.setIsPublic(false);
      
        gContext.setUser(res.user);
        setIsLoaded(true);

        toast.success("✅ Conectado a Tecnoedil!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });

        if (res.user.role == "paciente") {
          history.push("/perfil-paciente");
        } else {
          history.push("/");
        }
      })
      .catch(() => {
        toast.error("❌ No se pudo conectar!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });
  };

  const handleLogin = e => {
    e.preventDefault();

    let email = e.target.email.value;
    let password = e.target.pass.value;

    login(email, password);
  };
  return (
    <div class="">
      <div class="container-fluid h-custom">
        <div
          class="row d-flex justify-content-start align-items-center h-30"
          style={{
            backgroundColor: "#526FCE",
            boxShadow: "0px 5px 5px -2px rgba(0,0,0,0.21)"
          }}
        >
          <img
            src={logoSol}
            class="img-fluid"
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              marginLeft: "10%"
            }}
            width={"280vw"}
            alt="Logo"
          />
        </div>

        <div class="row d-flex flex-row-reverse justify-content-center align-items-center h-100">
          <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1 mt-5 pt-3">
            <form onSubmit={handleLogin}>
              <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                <h2
                  className="mb-5 "
                  style={{ textAlign: "center", width: "100%" }}
                >
                  Bienvenido a Tecnoedil <br />
                  ¡Para empezar registrate o inicia sesión!
                </h2>
                <div>
                  <ToastContainer />
                </div>
              </div>

              {!Tel && !OAuthSms && (
                <>
                  <div class="form-outline mb-4">
                    <label class="form-label" for="email">
                      Correo Electrónico.
                    </label>
                    <input
                      type="text"
                      id="email"
                      class="form-control form-control-lg"
                      placeholder="Escribí tu mail"
                    />
                  </div>

                  <div class="form-outline mb-3">
                    <label class="form-label" for="pass">
                      Contraseña
                    </label>
                    <input
                      type="password"
                      id="pass"
                      class="form-control form-control-lg"
                      placeholder="Escribí tu clave"
                    />
                  </div>

                  <div class="d-flex justify-content-between align-items-center">
                    <p class="text-body">
                      Te olvidaste la clave?{" "}
                      <span
                        onClick={e => {
                          history.push("/recuperar-clave");
                        }}
                        style={{ color: "#526FCE", cursor: "pointer" }}
                      >
                        Hacé click acá
                      </span>
                    </p>
                  </div>

                  <div class="text-center text-lg-start mt-4 pt-2">
                    <button
                      type="submit"
                      class="btn btn-primary btn-lg"
                      style={{
                        paddingLeft: "2.5rem",
                        paddingRight: "2.5rem",
                        width: "100%"
                      }}
                    >
                      Iniciar Sesión
                    </button>
                    
                    <div class="divider d-flex align-items-center my-4">
                      
                    </div>

                    <button
                    onClick={e => {
                      history.push("/register-proveedor");
                    }}
                      class="btn btn-primary btn-lg"
                      style={{
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        width: "100%"
                      }}
                    >
                      Registrarse
                    </button>
                    
                    
                 
                  </div>
              
                    
                </>
              )}
              {Tel && OAuthSms && (
                <div style={{ justifyContent: "center", alignItems: "center" }}>
                  <p className="mb-5 " style={{ textAlign: "center" }}>
                    Confirmá el código que enviamos para: {Tel}
                  </p>
                  <div
                    style={{
                      margin: "auto",
                      width: 250
                    }}
                  >
                    <ReactCodeInput
                      width={200}
                      style={{ maxWidth: 200 }}
                      fields={4}
                      onComplete={sendUser}
                    />
                  </div>
                </div>
              )}
            </form>
          </div>
          <div
            class="col-md-9 col-lg-6 col-xl-5 mt-5 m-3"
            style={{
              backgroundColor: "#81CFFA",
              borderRadius: "40px",
              backgroundImage: `url(${backGround})`,
              backgroundSize: "auto",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right top"
            }}
          >
            <img src={logo} class="img-fluid " alt="Sample image" />
          </div>
        </div>
      </div>

      <div
        style={{
          bottom: "0",
          position: "fixed",
          height: "40px",
          backgroundColor: "white",
          display: ""
        }}
      ></div>
    </div>
  );
};

export default Login;
