import React from "react";
import "react-dropzone-uploader/dist/styles.css";
import Drop from "react-dropzone-uploader";

const DropFile = ({
  onLoadImageB64 = () => {
    return;
  },
  handleClear
}) => {
  // specify upload params and url for your files
  // const getUploadParams = ({ meta }) => {
  //    return { url: "https://httpbin.org/post" };
  // };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status, arch) => {
    if (status == "done") {
      for (let i = 0; i < arch.length; i++) {
        const { file } = arch[i];
      
        // encode the file using the FileReader API
        const reader = new FileReader();
        
        reader.onloadend = u => {
          
          onLoadImageB64(reader.result, file.name);
        };
  

          reader.readAsDataURL(file);
       
     
      }
    }
    if (status == "removed") {
      setTimeout(() => {

        window.location.reload(false);
      }, 500);
     
      onLoadImageB64("");
      

    }
  };

  // receives array of files that are done uploading when submit button is clicked 

  return (
    <Drop
      inputWithFilesContent="Adicionar Arquivos"
 
      autoUpload
      SubmitButtonComponent={null}
   //   inputWithFilesContent
      onChangeStatus={handleChangeStatus}
      inputContent="Arrastar y soltar aqui"
      styles={{
        dropzone: {
          minHeight: 200,
          maxHeight: 250,
          width: "100%",
          backgroundColor: "#a5bcf0",
          border: "1px dashed #DDDFE1",
          overflow: "hidden"
        },
        inputLabel: {
          color: "#7e7e7e",
          fontSize: "18px",
          fontWeight: "normal",
          backgroundColor: "#f2f4fa"
        }
      }}
    />
  );
};

export default DropFile;
