import React, { useEffect } from "react";
import { Button, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { Col } from "reactstrap";
import { APICall } from "../../../../API";
import GlobalContext from "../../../../gContext";
import WrapperHeader from "../../../wrapperHeader";
import RenderForm from "./RenderForm";

export default function CrudProveedor() {
  const history = useHistory();
  const { id, operacion } = useParams();
  const gContext = React.useContext(GlobalContext);
  const [ProveedorOriginal, setProveedorOriginal] = React.useState({});
  const [parentProveedor,setParentProveedor]=React.useState({});
  useEffect(() => {

    if (id != "new") {
      gContext.setIsLoading(true);
      APICall.getProvedorById(id)
        .then(res => {

          setProveedorOriginal(res);
        
        })
        .finally(() => {
          gContext.setIsLoading(false);
        });
    }
  }, []);

  const registerProveedor=((p)=>{
    const keysArray=[
      // "email",
      "telephone",
      "pais",
      "tipoProveedor",
      "registro",
      "razonSocial",
      "actividad",
      "ciudad",
      "calle",
      "rubro",
      "nombreEjecutivo",
      "telefonoEjecutivo",
      "emailEjecutivo",
    ]
     
    var reEmail = /\S+@\S+\.\S+/;
    var rePhone = /^[0-9]+$/;
    let completed = true;
    for (const key in p) {
    
        if ((p[key] == "" || !p[key])&& keysArray.includes(key)) {
    
            completed = false;
        }
    }
    if (!completed) { toast.error("Debe completar todos los campos obligatorios."); }
    // else if (!reEmail.test(p.email)) toast.error("El mail ingresado no es correcto.");
    else if (!rePhone.test(p.telephone)) toast.error("El nro. de teléfono ingresado no es válido.");
    else {
    APICall.registerProveedor(p).then(()=>{
      toast.success("Proveedor creado con exito")
      history.push("/proveedores");

    }).catch(e=>{
     
      toast.error("Hubo un error mientras intentamos crear el proveedor "+ e)

    })}
  });

  const guardarEditProveedor=((p)=>{
    const keysArray=[
      // "email",
      "telephone",
      "pais",
      "tipoProveedor",
      "registro",
      "razonSocial",
      "actividad",
      "ciudad",
      "calle",
      "segmento",
      "rubro",
      "nombreEjecutivo",
      "telefonoEjecutivo",
      "emailEjecutivo",
    ]
 
    var reEmail = /\S+@\S+\.\S+/;
    var rePhone = /^[0-9]+$/;
    let completed = true;
    for (const key in p) {
        if ((p[key] == "" || !p[key] )&& keysArray.includes(key)) {
          
            completed = false;
        }
    }
    if (!completed) { toast.error("Debe completar todos los campos obligatorios."); }
    // else if (!reEmail.test(p.email)) toast.error("El mail ingresado no es correcto.");
    else if (!rePhone.test(p.telephone)) toast.error("El nro. de teléfono ingresado no es válido.");
    else {
    delete(p.invitedBy);
    APICall.editProveedor(p).then(()=>{
      toast.success("Proveedor editado con exito")
      history.push("/proveedores")

    }).catch(e=>{
      toast.error("Hubo un error mientras intentamos editar el proveedor "+ e)

    })}
  })
  
  const editarProveedor=(()=>{
    history.push("/operar-proveedor/"+ProveedorOriginal._id+"/editar-proveedor");
  })



  return (
    <WrapperHeader roles={["admin","gerente","analista"]}>
      <Row className="m-lg-5 m-sm-0 m-1">
        <Col lg={12}>
          <Row>
            <Col lg={8} md={6} sm={12}>
              <h2 style={{ margin: 0 }}>{operacion.includes("editar") ? "Editando: " : ""} {ProveedorOriginal.nombreFantasia}</h2>
              <h2 style={{ margin: 0 }}>{operacion.includes("registrar") ? "Creando Proveedor " : ""} </h2>

              <p>{ProveedorOriginal.razonSocial}</p>
            </Col>
            <Col lg={12} md={12} sm={12}>
              <Row style={{ justifyContent: "flex-end" }}>
                <Button
                  variant="outline-primary badge-rounded pr-4"
                  onClick={() => history.push("/proveedores")}
                  size={"sm"}
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    display: "flex",
                    marginRight: 10
                  }}
                >
                  <svg
                    width="12"
                    height="15"
                    viewBox="0 0 12 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.0807 1.03909C10.9785 0.985891 10.8672 0.959291 10.7559 0.959291C10.6159 0.959291 10.4773 1.00129 10.3576 1.08389L1.25757 7.38389C1.06857 7.51479 0.955872 7.72969 0.955872 7.95929C0.955872 8.18889 1.06857 8.40379 1.25757 8.53469L10.3576 14.8347C10.5711 14.9824 10.8504 14.9999 11.0807 14.8795C11.3117 14.7584 11.4559 14.5197 11.4559 14.2593L11.4559 1.65929C11.4559 1.39889 11.3117 1.16019 11.0807 1.03909Z"
                      fill="#526FCE"
                    />
                  </svg>
                  <span style={{ paddingLeft: 10 }}>Volver para la lista</span>
                </Button >

                {operacion.includes("consultar") && gContext.user.role!="analista"&&
                
                <Button variant="primary" size={"sm"} onClick={()=>{editarProveedor()}}>
                  <i class="las la-edit" style={{ fontSize: "1.2rem" }}></i>
                  <span style={{ paddingLeft: 10 }}>Editar Proveedor</span>
                </Button>
                }
                {
                  operacion.includes("editar") && 
                  <Button variant="primary" size={"sm"} onClick={()=>guardarEditProveedor(parentProveedor)}>
                    <svg
                      width="15"
                      height="10"
                      viewBox="0 0 15 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.76446 7.45706L1.56604 4.496L0.192657 5.76747L4.76446 10L14.1927 1.27147L12.8193 0L4.76446 7.45706Z"
                        fill="white"
                      />
                    </svg>
                    <span style={{ paddingLeft: 10 }}>Guardar Cambios</span>
                  </Button>
                }
              </Row>
            </Col>
          </Row>
        </Col>
        <Col lg={12} style={{ padding: 0, marginTop: 10 }}>
          <RenderForm proveedorBack={ProveedorOriginal} parentProveedor={setParentProveedor} registerProveedor={registerProveedor} guardarEditProveedor={guardarEditProveedor} editarProveedor={editarProveedor}></RenderForm>
        </Col>
      </Row>
    </WrapperHeader>
  );
}
