import React from "react";
import { Col, Row } from "react-bootstrap";
import dropdownData from "../../../dropdownData";

export default function Radios({ operacion, value, onChange }) {
  const [stateForm, setStateForm] = React.useState(value|| dropdownData.forms);
  const handleChange = e => {
    console.log(e.target.id, e.target.value);


    let updatedList=stateForm.map(i=>{
        if(i.question==e.target.id){
            i.respuesta=e.target.value
        }
        return i
    })
    setStateForm(updatedList)
    onChange({
      target: {
        id: "radioForm",
        value: updatedList
      }
    });
  };



  return (
    <Col lg={12}>
      <h4 className="mb-3 mt-4">General</h4>
      <div className="basic-form">
        <form action="#">
          <Row>
            {stateForm.map(e => (
              <Col lg={12}>
                <form className="form-group mt-4">
                  <div className="row">
                    <p className="col-form-label col-12 pt-0 ">{e.question}</p>
                    <div className="col-12 row mt-1">
                      {e.options.map((f, i) => (
                        <div className="form-check col-2">
                          <input
                            className=""
                            id={e.question}
                            type="radio"
                            name={e.question}
                            defaultValue={f}
                            disabled={operacion.includes("consultar")}
                            value={f}
                            checked={f == e.respuesta}
                            onChange={handleChange}
                          />
                          <label className="form-check-label">{f}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </form>
              </Col>
            ))}
          </Row>
        </form>
      </div>
    </Col>
  );
}
