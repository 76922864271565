import React from "react";
import { Modal } from "react-bootstrap";
import Alldata from "./ProvedorTable";
function ModalCotizacion({ showModalVerCot, handleClose }) {
  return (
    <Modal
      show={showModalVerCot}
      onHide={() => handleClose("showModalVerCot")}
      className="modal fade"
      id="addOrderModalside"
      size="xl"
    >
      <div>
        <div className="modal-header">
          <h4 className="modal-title">Cotizaciones enviadas </h4>
          <button
            type="button"
            onClick={() => handleClose()}
            className="close"
            data-dismiss="modal"
          >
            <span>&times;</span>{" "}
          </button>
        </div>
        <Alldata id={showModalVerCot}></Alldata>
      </div>
    </Modal>
  );
}

export default ModalCotizacion;
