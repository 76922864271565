import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext
} from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Table, Pagination, Dropdown } from "react-bootstrap";
import data from "../../../Dashboard/Projects/Tabldata.js";
import { APICall } from "../../../../../API/index";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Select from "react-select";
import Rte from "./Rte";
import { Button, Row } from "react-bootstrap";
import DropFile from "./DropFile";
import ListaProveedor from "./ListProveedor";
import GlobalContext from "../../../../../gContext.js";
const Alldata = () => {
  const sort = 10;
  const gContext = useContext(GlobalContext);
  const { id } = useParams();
  const history = useHistory();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const COTIZACIONQUERY = query.get("cotizacion");
  const EDITABLE = query.get("editable");
  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [dataTableFull, setDataTableFull] = useState([]);
  const [Filter, setFilter] = useState("");
  const [filterCombos, setFilterCombos] = useState({});
  const [pais, setPais] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [departamento, setDepartamento] = useState("");
  const [rubro, setRubro] = useState("");
  const [segmento, setSegmento] = useState("");
  const [dataCotizacion, setDataCotizacion] = useState({
    count: 0,
    status: false
  });
  const [filterOptions, setFilterOptions] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [Cotizacion, setCotizacion] = useState({
    title: "",
    descripcion: "",
    fornecedores: [],
    file: [],
    filename: []
  });

 
  useEffect(() => {
    if (COTIZACIONQUERY) {
   
      setDataTableFull(JSON.parse(COTIZACIONQUERY).fornecedores);

      setDataTable(JSON.parse(COTIZACIONQUERY).fornecedores);
      setCotizacion(JSON.parse(COTIZACIONQUERY));
      onClick(0);
      setLoading(false);
    }
    onClick(0);

    if (EDITABLE) {
      setIsEditable(JSON.parse(EDITABLE).isEditable);
    }
  }, [COTIZACIONQUERY, search, EDITABLE]);
  useEffect(() => {
    APICall.getFilters().then(res => {
      let filterOptionsAux = {
        paises: [],
        ciudades: [],
        departamentos: [],
        rubros: [],
        segmentos: []
      };
      res.data.paises.forEach(pais => {
        filterOptionsAux.paises.push({
          value: pais,
          label: pais
        });
      });
      res.data.ciudades.forEach(ciudad => {
        filterOptionsAux.ciudades.push({
          value: ciudad,
          label: ciudad
        });
      });
      res.data.estados.forEach(estado => {
        filterOptionsAux.departamentos.push({
          value: estado,
          label: estado
        });
      });
      res.data.rubros.forEach(rubro => {
        filterOptionsAux.rubros.push({
          value: rubro,
          label: rubro
        });
      });
      res.data.segmentos.forEach(segmento => {
        filterOptionsAux.segmentos.push({
          value: segmento,
          label: segmento
        });
      });
      setFilterOptions(filterOptionsAux);
    });
  }, []);

  useEffect(() => {
    if (
      Filter == "" &&
      pais == "" &&
      ciudad == "" &&
      departamento == "" &&
      rubro == "" &&
      segmento == ""
    ) {
      setDataTable(JSON.parse(COTIZACIONQUERY).fornecedores);

      let countChecked = 0;
      let activateEnviarCotizacion = false;
      dataTableFull.forEach(e => {
        if (e.checked) {
          countChecked++;
          activateEnviarCotizacion = true;
        }
      });
      setDataCotizacion({
        count: countChecked,
        status: activateEnviarCotizacion
      });
    } else {
      let listToPush = [];
      for (let i = 0; i < dataTableFull.length; i++) {
        const element = dataTableFull[i];

        if (
          JSON.stringify(element)
            .toLowerCase()
            .includes(Filter.toLowerCase()) &&
          (element.pais == pais.value || !pais.value) &&
          (element.ciudad == ciudad.value || !ciudad.value) &&
          (element.departamento == departamento.value || !departamento.value) &&
          (element.rubro.find(r => r == rubro.value) || !rubro.value) &&
          (element.segmento == segmento.value || !segmento.value)
        ) {
          listToPush.push(element);
        }
      }
      let countChecked = 0;
      let activateEnviarCotizacion = false;
      listToPush.forEach(e => {
        if (e.checked) {
          countChecked++;
          activateEnviarCotizacion = true;
        }
      });
      setDataCotizacion({
        count: countChecked,
        status: activateEnviarCotizacion
      });
      setDataTable(listToPush);
    }
  }, [Filter, pais, ciudad, departamento, rubro, segmento]);

  const columns = [
    "",
    "Nombre Real",
    "RUC / CNPJ /  ID",
    "Tipo de actividad",

    "País",

    "Correo"
  ];

  useEffect(() => {
    onClick(0);
  }, [dataTable]);

  let jobPagination = Array(Math.ceil(dataTable.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const activePag = useRef(0);
  const jobData = useRef(
    dataTable.slice(activePag.current * sort, (activePag.current + 1) * sort)
  );
  const [demo, setdemo] = useState();
  const onClick = i => {
    activePag.current = i;

    jobData.current = dataTable.slice(
      activePag.current * sort,
      (activePag.current + 1) * sort
    );
    setdemo(
      dataTable.slice(activePag.current * sort, (activePag.current + 1) * sort)
    );
  };

  const handleSearchChange = e => {
    setFilter(e.target.value);
  };

  const chackbox = document.querySelectorAll(".check-input input");
  const motherChackBox = document.querySelector(".check-input-all input");

  const sendCot = cot => {
    if (
      !Cotizacion.title ||
      !Cotizacion.descripcion ||
      !Cotizacion.fornecedores
    ) {
      return toast.error("Debes completar los campos del mail");
    }
    const refreshPage=(v)=> {
      setTimeout(() => {

        window.location.reload(v);
      }, 2000);
    }
    
    let dataRecibida = {
      title: Cotizacion.title,
      descripcion: Cotizacion.descripcion,
      fornecedores: Cotizacion.fornecedores,
      file: Cotizacion.file,
      filename: Cotizacion.filename
    };

    const resolve=(data)=>{
      let vec = data;
      if(vec){
       
        return 'Con Archivos Adjuntos'
      }else{
    
        return 'Sin Archivos Adjuntos'
      }
       
    }

  //   const resolveCi =(data)=>{
  //     let v = userListData.filter((v)=>v._id === data)
  //      return v[0].ci;
     
  //  }
   

    swal({
      title: "Desea enviar pedido de Cotización? ",
      text: Cotizacion.fornecedores.length + " Proveedores recibirán el mail! " + resolve(dataRecibida.file),
      icon: "info",
      buttons: true,
      dangerMode: false
    }).then(willDelete => {
      if (willDelete) {
 
        gContext.setIsLoading(true);
        let obj_tmp = {
          title: Cotizacion.title,
          descripcion: Cotizacion.descripcion,
          fornecedores: Cotizacion.fornecedores,
          file: Cotizacion.file,
          filename: Cotizacion.filename
        };
        if(obj_tmp.file !=null){ 

          
        APICall.crearCotizacion(obj_tmp)
          .then(() => {
            toast.success("Enviando Cotizacion con adjuntos aguarde...");
            
            setTimeout(()=>{
              const ver= APICall.getCotizaciones().then(
                function(v){
               //   console.log('reso', v.data[0]?.state)
                  if(v.data[0]?.state==="Enviando.."){
                
                    setTimeout(() => {
                      toast.success("Cotización enviada con exito");
                      history.push("/cotizaciones");
                      
                    }, 6500);
                  }else{
                    toast.success("Cotización enviada con exito");
                     history.push("/cotizaciones");
                  }
                },
                function(r){
                  console.log(r,'errorrrr')
                  toast.success("Cotización enviada con exito,Actualizar Pagina");
                  history.push("/cotizaciones");
                }
                )
            
            },3000)
            
           
              })
          .catch(e => {
            toast.error(
              "Hubo un error mientras intentamos enviar la cotización"
            );
          })
          .finally(() => {
            gContext.setIsLoading(false);
          });
 
        
        }else{
          let obj_tmpp = {
            title: Cotizacion.title,
            descripcion: Cotizacion.descripcion,
            fornecedores: Cotizacion.fornecedores,
            file: '',
            filename: ''
          };
          
          APICall.crearCotizacion(obj_tmpp)
          .then(() => {
            toast.success("Enviando Cotizacion...");
            setTimeout(()=>{
              const ver= APICall.getCotizaciones().then(
                function(v){
               //   console.log('reso', v.data[0]?.state)
                  if(v.data[0]?.state==="Enviando.."){
                    setTimeout(() => {
                      toast.success("Cotización enviada con exito");
                      history.push("/cotizaciones");
                      
                    }, 1500);
                  }else{
                    toast.success("Cotización enviada con exito");
                     history.push("/cotizaciones");
                  }
                },
                function(r){
                  console.log(r,'errorrrr')
                  toast.success("Cotización enviada con exito,Actualizar Pagina");
                  history.push("/cotizaciones");
                }
                )
              console.log(ver, 'ou ina')
            },3000)
            
          })
          .catch(e => {
            toast.error(
              "Hubo un error mientras intentamos enviar la cotización"
            );
          })
          .finally(() => {
            gContext.setIsLoading(false);
          });
 
        }


      }
    });
  };

  const handleChangeCot = (cot, type, filename) => {
    let aux = Cotizacion;

    if (type.includes("file")) {
      if (aux[type]) {
        !aux[type].some(e => e.filename == filename) &&
          aux[type].push({ file: cot, filename });
      } else {
        aux[type] = [{ file: cot, filename }];
      }
    } else {
      aux[type] = cot;
    }
    setCotizacion(aux);
    
  };

  return (
    <Fragment>
      <div>
        <h3 className="pt-2 pb-3">Confirmar Cotización</h3>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text">Línea de asunto</span>
          </div>
          {!isEditable && (
            <input
              value={Cotizacion.title}
              id="title"
              type="text"
              className="form-control"
            />
          )}
          {isEditable && (
            <input
              onChange={e => {
                handleChangeCot(e.target.value, "title");
              }}
              id="title"
              type="text"
              className="form-control"
              defaultValue={Cotizacion.title}
            />
          )}
        </div>
        {!isEditable && Cotizacion.descripcion && (
          <Rte
            handleChange={e => {
            
            }}
            readOnly={true}
            initialState={Cotizacion.descripcion}
          ></Rte>
        )}

        {isEditable && (
          <Rte
            handleChange={t => handleChangeCot(t, "descripcion")}
            initialState={Cotizacion.descripcion}
          ></Rte>
        )}
        <DropFile
          onLoadImageB64={(e, fname) => {
            handleChangeCot(e, "file", fname);
          }}
        ></DropFile>
      </div>
      <div
        style={{
          width: "100%",
          height: 2,
          backgroundColor: "#CCCCCC",
          paddingLeft: 30,
          paddingRight: 50,
          marginTop: 50,
          marginBottom: 30
        }}
      ></div>
      <div className="row">
        <div className="col-lg-12">
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="navpills-1"
              role="tabpanel"
            >
              <Table
                responsive
                className="table-responsive card-table rounded table-hover fs-14"
              >
                <div
                  style={{
                    display: "flex",
                    padding: "15px",
                    backgroundColor: "white"
                  }}
                >
                  <div
                    className="col-5"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <h4 style={{ fontWeight: "bold", color: "black" }}>
                      Proveedores
                    </h4>
                  </div>
                  <div
                    className="col-3"
                    style={{ display: "flex", alignItems: "center" }}
                  ></div>
                  <div className="col-4">
                    {
                      <Filters
                        handleSearchChange={handleSearchChange}
                        filter={Filter}
                      ></Filters>
                    }
                  </div>
                </div>

                <div id="example_wrapper" className="dataTables_wrapper">
                  <table
                    id="example"
                    className="table border-no display mb-4 dataTablesCard table-responsive-xl project-bx"
                  >
                    <thead>
                      <tr role="row">
                        {columns.map((d, i) => (
                          <th key={i} className="check-input-all">
                            {i === 0 ? (
                              <div
                                className="custom-control custom-checkbox"
                                style={{ zIndex: 0 }}
                              ></div>
                            ) : (
                              d
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {!loading &&
                        jobData.current.map((d, i) => {
                         

                          return (
                            <tr key={i}>
                              <td>
                                <div
                                  className="custom-control custom-checkbox "
                                  style={{ zIndex: 0 }}
                                ></div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <span className="text-black font-w600 text-nowrap">
                                      {d.nombreFantasia || "No Tiene"}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <span className="text-black font-w600 text-nowrap">
                                      {d.registro || "NO TIENE"}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <span className="text-black font-w600 text-nowrap">
                                      {d.actividad || "NO TIENE"}
                                    </span>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <span className="text-black font-w600 text-nowrap">
                                      {d.pais + ", " + d.ciudad || "NO TIENE"}
                                    </span>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <span className="text-black font-w600 text-nowrap">
                                      {d.emailEjecutivo[0] || d.email}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <Link
                                  to={`/operar-proveedor/${d._id}/consultar-proveedor`}
                                >
                                  <i
                                    className="fa fa-eye mr-3"
                                    style={{ fontSize: "1.5rem" }}
                                  ></i>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {loading && (
                    <div
                      style={{
                        display: "flex",
                        flex: "1",
                        justifyContent: "center"
                      }}
                    >
                      <div
                        className="spinner-border"
                        style={{ height: "200px", width: "200px" }}
                      ></div>
                    </div>
                  )}

                  <div className="d-sm-flex justify-content-between  align-items-center mt-3">
                    <div className="dataTables_info">
                      {/* Total de {data.patientTable.data.length} elementos */}
                    </div>
              
                    <Button
                    variant="primary"
                    style={{ display: "flex", alignItems: "center" }}
                    size={"md"}
                    onClick={() => sendCot()}
                    >
                 
                      <i
                        class="las la-paper-plane"
                        style={{ fontSize: "1.2rem" }}
                        ></i>
                      <span style={{ paddingLeft: 10, color: "white" }}>
                        Enviar cotización: {Cotizacion.fornecedores.length}{" "}
                        Proveedor
                        {Cotizacion.fornecedores.length == 1 ? "" : "es"}
                      </span>
                    </Button>
                
                    <div className="dataTables_paginate paging_simple_numbers">
                      <Pagination size="lg">
                        <li
                          className="page-item page-indicator "
                          onClick={() =>
                            activePag.current > 0 &&
                            onClick(activePag.current - 1)
                          }
                        >
                          {/* <Link className="page-link" to="#">  <i className="la la-angle-left" /></Link> */}
                          <Link className="paginate_button previous" to="#">
                            {" "}
                            Volver
                          </Link>
                        </li>
                        {jobPagination.map((number, i) => (
                          <Pagination.Item
                            className={activePag.current === i ? "active" : ""}
                            onClick={() => onClick(i)}
                          >
                            <span style={{ color: "black" }}>{number}</span>
                          </Pagination.Item>
                        ))}
                        <li
                          className="page-item page-indicator"
                          onClick={() =>
                            activePag.current + 1 < jobPagination.length &&
                            onClick(activePag.current + 1)
                          }
                        >
                          {/* <Link className="page-link" to="#"><i className="la la-angle-right" /></Link> */}
                          <Link className="paginate_button next" to="#">
                            {" "}
                            Siguiente
                          </Link>
                        </li>
                      </Pagination>
                    </div>
                  </div>
                </div>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Alldata;

const Filters = ({ handleSearchChange, filter }) => {
  return (
    <input
      type="text"
      value={filter}
      onChange={e => handleSearchChange(e)}
      className="form-control"
      placeholder="Busqueda Rapida"
    />
  );
};
