import { Card } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Row, Table, Col, Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router";
import { APICall } from "../../../../API";
import GlobalContext from "../../../../gContext";
import { Link } from "react-router-dom";
import dropdownData from "../../../../dropdownData";
import Radios from "../../Radios";
import ModalCotizacion from "../Shop/ModalCotizacion.js";
import Select from 'react-select';
import { toast } from "react-toastify";
export default function RenderForm({
  proveedorBack,
  registerProveedor,
  guardarEditProveedor,
  editarProveedor,
  parentProveedor
}) {
  const { id, operacion } = useParams();
  const history = useHistory();
  const [Proveedor, setProveedor] = React.useState(proveedorBack);
  const [ProveedorOriginal, setProveedorOriginal] =
    React.useState(proveedorBack);
  const [showModal, setShowModal] = React.useState(false)
  const gContext = React.useContext(GlobalContext);
  const [rubroOptions, setRubroOptions] = useState([]);
  const [selectedRubros, setSelectedRubros] = useState([]);
  const [errors, setErrors] = useState({});
  const showCotizaciones = idUser => {
    setShowModal(idUser)
  }
  useEffect(() => {

    let blancProv = {
      email: "",
      telephone: "",
      telephone2: "",
      pais: "Paraguay",
      tipoProveedor: "S.A.",
      registro: "",
      representanteLegal: "",
      documentoIdentidad: "",
      puesto: "",
      razonSocial: "",
      nombreFantasia: "",
      actividad: "Bienes y Servicios",
      inicioActividad: "",
      capitalSocial: "",
      cantidadEmpleados: "",
      ciudad: "",
      calle: "",
      website: "",
      // segmento: "OTROS",
      rubro: "Otros",
      especialidad: "",
      radioForm: dropdownData.forms,
      nombreEjecutivo: "",
      telefonoEjecutivo: "",
      celularRepresentante: "",
      emailEjecutivo: "",
    };
    if (operacion == "registrar-proveedor") {
      setProveedor(blancProv);
      parentProveedor(blancProv);
      setProveedorOriginal(blancProv);
    } else {
      setProveedor(proveedorBack);
      parentProveedor(proveedorBack);
      setProveedorOriginal(proveedorBack);
      let selectedRubrosAux = []
      if (proveedorBack.rubro) {

        proveedorBack.rubro.map((r) => {
          selectedRubrosAux.push({
            value: r,
            label: r
          })
        })
      }
      setSelectedRubros(selectedRubrosAux)
    }
    let rubroOptionsAux = []
    APICall.getRubros().then((r) => {
      r.data.map((s) => {
        rubroOptionsAux.push({
          value: s['rubro'],
          label: s['rubro']
        })
      });
      setRubroOptions(rubroOptionsAux);
    }
    )



  }, [proveedorBack]);

  const handleChangeText = e => {
    let proveedorAux = { ...Proveedor };
    proveedorAux[e.target.id] = e.target.value;
    let errorsAux = { ...errors };

    errorsAux[e.target.id] = validate(e.target.id, e.target.value);

    setErrors(errorsAux);
    parentProveedor(proveedorAux);
    setProveedor(proveedorAux);
  };

  const validate = (id, value) => {

    const validators = {
      capitalSocial: (x) => ((x < 1) && (x != "") ? "El valor ingresado para el capital social no es válido." : ""),
      cantidadEmpleados: (x) => ((x < 1) && (x != "") ? "El valor ingresado para la cantidad de empleados no es válido." : ""),
      website: (x) => ((x != "") && !x.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) ? "El sitio web ingresado no es válido" : ""),
      email: (x) => (x.match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/) || x == "" ? "" : "El mail ingresado no es válido"),
      emailEjecutivo: (x) => (x.match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/) ? "" : "El mail del ejecutivo de cuenta no es válido"),
      inicioActividad: (x) => (new Date(x).getFullYear() > 1900 ? "" : "La fecha de inicio de actividades no es válida."),
      telephone: (x) => (!isInt(x) ? "El telefono 1 no es válido" : ""),
      telephone2: (x) => (!isInt(x) ? "El telefono 2 no es válido" : ""),
      celularRepresentante: (x) => ((x != "") && !isInt(x) ? "El celular del representante no es válido" : ""),
      telefonoEjecutivo: (x) => (!isInt(x) ? "El telefono del ejecutivo no es válido" : ""),
    };

    return validators[id] ? validators[id](value) : false;
  }

  function isInt(value) {
    var reg = /^\d+$/;
    return reg.test(value)
  }


  const checkErrors = () => {
    let save = true;
    Object.keys(errors).forEach((errorMessage) => {
      if (errors[errorMessage] != "") {
        toast.error(errors[errorMessage])
        save = false
      };
    })

    return save;
  }


  const handleChangeRubro = e => {
    let proveedorAux = { ...Proveedor };
    let rubrosAux = [];
    if (e) {
      e.map((r) =>
        rubrosAux.push(r.value)
      );
    }
    proveedorAux.rubro = rubrosAux;
    parentProveedor(proveedorAux);
    setProveedor(proveedorAux);
    setSelectedRubros(e);

  }

  const buttonSubmit = () => {
    const iconStyle = {
      fontSize: "1.2rem"
    };
    const buttonLabel = {
      "registrar-proveedor": "Registrar Proveedor",
      "consultar-proveedor": "Editar Proveedor",
      "editar-proveedor": "Guardar Cambios"
    };
    const buttonIcon = {
      "registrar-proveedor": (
        <i class="las la-plus-circle " style={iconStyle}></i>
      ),
      "consultar-proveedor": <i class="las la-edit" style={iconStyle}></i>,
      "editar-proveedor": <i class="las la-check" style={iconStyle}></i>
    };

    const method = {
      "registrar-proveedor": () => { if (checkErrors()) registerProveedor(Proveedor) },
      "consultar-proveedor": () => { if (checkErrors()) editarProveedor() },
      "editar-proveedor": () => { if (checkErrors()) guardarEditProveedor(Proveedor) },
    };
    if (
      gContext.user.role == "analista" &&
      (operacion == "consultar-proveedor" || "editar-proveedor")
    )
      return "";
    else {
      return (
        <Button variant="primary" size={"sm"} onClick={method[operacion]}>
          {buttonIcon[operacion]}
          <span style={{ paddingLeft: 10 }}>{buttonLabel[operacion]}</span>
        </Button>
      );
    }
  };


  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="navpills-1"
              role="tabpanel"
            >
              <Table
                responsive
                className="table-responsive card-table rounded table-hover fs-14"
              >
                <div id="example_wrapper" className="dataTables_wrapper">
                  <div id="example" className="card card-body">
                    <Row className="pl-2 pr-2">
                      <Col lg={12}>
                        <Row>
                          <Col lg={2} md={5} sm={6} xs={12}>
                            {!operacion.includes("registrar") && <Button
                              size={"sm"}
                              variant="primary"
                              style={{
                                borderRadius: 5,
                                width: "100%",
                                marginBottom: 10
                              }}
                            >
                              Datos Del Proveedor
                            </Button>}
                          </Col>
                          <Col lg={2} md={5} sm={6} xs={12}>
                            {!operacion.includes("registrar") &&
                              <Button
                                onClick={() => showCotizaciones(id)}
                                size={"sm"}
                                variant="outline-primary"
                                style={{
                                  borderRadius: 5,
                                  width: "100%",
                                  marginBottom: 10
                                }}
                              >
                                Ver Cotizaciones
                              </Button>}
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={12}>
                        <Row>
                          {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

                          <Col lg={12}>
                            <h4 className="mb-3 mt-5">
                              Identificación del proveedorres
                            </h4>
                            <div className="basic-form">
                              <form action="#">
                                <Row>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          País*
                                        </span>
                                      </div>
                                      <select
                                        className="form-control"
                                        defaultValue={Proveedor.pais}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        value={Proveedor.pais}
                                        onChange={handleChangeText}
                                        id="pais"
                                      >
                                        {dropdownData.paises.map((e, i) => (
                                          <option value={e}>{e}</option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          RUC / CNPJ /  ID *
                                        </span>
                                      </div>
                                      <input
                                        defaultValue={Proveedor.registro}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        value={Proveedor.registro}
                                        onChange={handleChangeText}
                                        id="registro"
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Tipo de Proveedor*
                                        </span>
                                      </div>
                                      <select
                                        className="form-control"
                                        value={Proveedor.tipoProveedor}
                                        onChange={handleChangeText}
                                        id="tipoProveedor"
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                      >
                                        {dropdownData.tipoDeProveedor.map(
                                          (e, i) => (
                                            <option value={e}>{e}</option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  </Col>
                                </Row>
                              </form>
                            </div>
                          </Col>

                          {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

                          <Col lg={12}>
                            <h4 className="mb-3 mt-4">
                              Datos del Representante Legal
                            </h4>
                            <div className="basic-form">
                              <form action="#">
                                <Row>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Representante Legal
                                        </span>
                                      </div>
                                      <input
                                        value={Proveedor.representanteLegal}
                                        defaultValue={
                                          Proveedor.representanteLegal
                                        }
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        onChange={handleChangeText}
                                        id="representanteLegal"
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Documento de Identidad*
                                        </span>
                                      </div>
                                      <input
                                        type="text"
                                        value={Proveedor.documentoIdentidad}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        onChange={handleChangeText}
                                        id="documentoIdentidad"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Puesto
                                        </span>
                                      </div>
                                      <input
                                        type="text"
                                        value={Proveedor.puesto}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        onChange={handleChangeText}
                                        id="puesto"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Celular
                                        </span>
                                      </div>
                                      <input
                                        style={errors["celularRepresentante"] ? { borderColor: "red" } : {}}
                                        type="text"
                                        value={Proveedor.celularRepresentante}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        onChange={handleChangeText}
                                        id="celularRepresentante"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </form>
                            </div>
                          </Col>

                          {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}
                          <Col lg={12}>
                            <h4 className="mb-3 mt-4">Datos del Ejecutivo de Cuenta de su empresa</h4>
                            <div className="basic-form">
                              <form action="#">
                                <Row>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Nombre*
                                        </span>
                                      </div>
                                      <input
                                        value={Proveedor.nombreEjecutivo}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        onChange={handleChangeText}
                                        id="nombreEjecutivo"
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Teléfono*
                                        </span>
                                      </div>
                                      <input
                                        type="number"
                                        value={Proveedor.telefonoEjecutivo}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        style={errors["telefonoEjecutivo"] ? { borderColor: "red" } : {}}

                                        onChange={handleChangeText}
                                        id="telefonoEjecutivo"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Email*
                                        </span>
                                      </div>
                                      <input
                                        type="text"
                                        value={Proveedor.emailEjecutivo}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        style={errors["emailEjecutivo"] ? { borderColor: "red" } : {}}
                                        onChange={handleChangeText}
                                        id="emailEjecutivo"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>

                                </Row>
                              </form>
                            </div>
                          </Col>
                          {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}
                          <Col lg={12}>
                            <h4 className="mb-3 mt-4">Datos del Proveedor</h4>
                            <div className="basic-form">
                              <form action="#">
                                <Row>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Razón Social / Nombre*
                                        </span>
                                      </div>
                                      <input
                                        value={Proveedor.razonSocial}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        onChange={handleChangeText}
                                        id="razonSocial"
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Nombre de Fantasía
                                        </span>
                                      </div>
                                      <input
                                        type="text"
                                        value={Proveedor.nombreFantasia}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        onChange={handleChangeText}
                                        id="nombreFantasia"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Tipo de Actividad*
                                        </span>
                                      </div>
                                      <select
                                        className="form-control"
                                        value={Proveedor.actividad}
                                        onChange={handleChangeText}
                                        id="actividad"
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                      >
                                        {dropdownData.tipoActividad.map(
                                          (e, i) => (
                                            <option value={e}>{e}</option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Inicio de Actividades
                                        </span>
                                      </div>
                                      <input
                                        style={errors["inicioActividad"] ? { borderColor: "red" } : {}}
                                        type="date"
                                        value={Proveedor.inicioActividad}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        onChange={handleChangeText}
                                        id="inicioActividad"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Capital Social (USD)
                                        </span>
                                      </div>
                                      <input

                                        min="1"
                                        type="number"
                                        value={Proveedor.capitalSocial}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        style={errors["capitalSocial"] ? { borderColor: "red" } : {}}
                                        onChange={handleChangeText}
                                        id="capitalSocial"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Nº de empleados
                                        </span>
                                      </div>
                                      <input
                                        type="number"
                                        value={Proveedor.cantidadEmpleados}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        style={errors["cantidadEmpleados"] ? { borderColor: "red" } : {}}
                                        onChange={handleChangeText}
                                        id="cantidadEmpleados"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </form>
                            </div>
                          </Col>

                          {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

                          <Col lg={12}>
                            <h4 className="mb-3 mt-4">Datos de Ubicación</h4>
                            <div className="basic-form">
                              <form action="#">
                                <Row>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Departamento/Estado
                                        </span>
                                      </div>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={Proveedor.estado}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        onChange={handleChangeText}
                                        id="estado"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Ciudad*
                                        </span>
                                      </div>
                                      <input
                                        type="text"
                                        value={Proveedor.ciudad}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        onChange={handleChangeText}
                                        id="ciudad"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Teléfono 1*
                                        </span>
                                      </div>
                                      <input
                                        type="number"
                                        value={Proveedor.telephone}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        style={errors["telephone"] ? { borderColor: "red" } : {}}
                                        onChange={handleChangeText}
                                        id="telephone"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Teléfono 2
                                        </span>
                                      </div>
                                      <input
                                        type="number"
                                        value={Proveedor.telephone2}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        style={errors["telephone2"] ? { borderColor: "red" } : {}}
                                        onChange={handleChangeText}
                                        id="telephone2"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={12}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Dirección*
                                        </span>
                                      </div>
                                      <input
                                        value={Proveedor.calle}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        onChange={handleChangeText}
                                        id="calle"
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Sitio Web
                                        </span>
                                      </div>
                                      <input
                                        value={Proveedor.website}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        style={errors["website"] ? { borderColor: "red" } : {}}
                                        onChange={handleChangeText}
                                        id="website"
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Email
                                        </span>
                                      </div>
                                      <input
                                        value={Proveedor.email}
                                        disabled={
                                          !operacion.includes("registrar")
                                        }
                                        style={errors["email"] ? { borderColor: "red" } : {}}
                                        onChange={handleChangeText}
                                        id="email"
                                        type="mail"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </form>
                            </div>
                          </Col>

                          {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

                          <Col lg={12}>
                            <h4 className="mb-3 mt-4">
                              Productos y/o Servicios
                            </h4>
                            <div className="basic-form">
                              <form action="#">
                                <Row>
                                  {/* <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Segmento *
                                        </span>
                                      </div>
                                      <select
                                        className="form-control"
                                        value={Proveedor.actividad}
                                        onChange={handleChangeText}
                                        id="actividad"
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                      >
                                        {dropdownData.tipoActividad.map((e, i) => (
                                          <option value={e}>{e}</option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col> */}
                                  <Col lg={8}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Rubros *
                                        </span>
                                      </div>


                                      <Select
                                        isMulti={true}
                                        className="form-control"
                                        // value={Proveedor.rubro}
                                        //onChange={handleChangeText}
                                        id="segmento"
                                        isDisabled={operacion.includes(
                                          "consultar"
                                        )}
                                        placeholder="Seleccionar"

                                        value={selectedRubros}
                                        onChange={handleChangeRubro}
                                        options={rubroOptions}
                                      >

                                      </Select>
                                    </div>
                                  </Col>
                                  <Col lg={4}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Especialidad
                                        </span>
                                      </div>
                                      <input
                                        type="text"
                                        value={Proveedor.especialidad}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        onChange={handleChangeText}
                                        id="especialidad"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={12}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Detalle de Producto/ Servicio
                                        </span>
                                      </div>
                                      <input
                                        type="text"
                                        value={Proveedor.detalle}
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                        onChange={handleChangeText}
                                        id="detalle"
                                        className="form-control"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="input-group mb-3">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          Calificación
                                        </span>
                                      </div>
                                      <select
                                        className="form-control"
                                        value={Proveedor.calificacion}
                                        onChange={handleChangeText}
                                        id="calificacion"
                                        disabled={operacion.includes(
                                          "consultar"
                                        )}
                                      >
                                        {dropdownData.calificacion.map((e, i) => (
                                          <option value={e}>{e}</option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                </Row>
                              </form>
                            </div>
                          </Col>

                          {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

                          {/* <Radios
                            operacion={operacion}
                            value={Proveedor.radioForm}
                            onChange={handleChangeText}
                          ></Radios>
                         */}
                          {/* BOTOES DE ABAJO */}

                          <Col sm={12} className="mt-5">
                            <Row className="mt-5 ">
                              <Col lg={6} sm={12} className="mb-2">
                                <Row style={{ justifyContent: "flex-start" }}>
                                  {
                                    (operacion.includes("consultar") ||
                                      operacion.includes("editar")) && (
                                      <div></div>
                                    )
                                    // <Button variant="danger" onClick={() => history.push("/proveedores")} size={"sm"}>
                                    //   <svg
                                    //     width="14"
                                    //     height="14"
                                    //     viewBox="0 0 14 14"
                                    //     fill="none"
                                    //     xmlns="http://www.w3.org/2000/svg"
                                    //   >
                                    //     <path
                                    //       fill-rule="evenodd"
                                    //       clip-rule="evenodd"
                                    //       d="M13.3178 0.875026C12.8345 0.391758 12.051 0.391712 11.5678 0.874923L7.19204 5.25L2.8175 0.875078C2.33427 0.391799 1.55074 0.391798 1.0675 0.875077C0.584326 1.3583 0.584327 2.1417 1.0675 2.62492L5.44219 7L1.0675 11.3751C0.584327 11.8583 0.584326 12.6417 1.0675 13.1249C1.55074 13.6082 2.33427 13.6082 2.8175 13.1249L7.19204 8.75L11.5678 13.1251C12.051 13.6083 12.8345 13.6082 13.3178 13.125C13.801 12.6417 13.801 11.8583 13.3178 11.375L8.94312 7L13.3178 2.62497C13.801 2.14173 13.801 1.35827 13.3178 0.875026Z"
                                    //       fill="white"
                                    //     />
                                    //   </svg>

                                    //   <span style={{ paddingLeft: 10 }}>
                                    //     Eliminar Proveedor
                                    //   </span>
                                    // </Button>
                                  }
                                </Row>
                              </Col>
                              <Col lg={6} sm={12}>
                                <Row style={{ justifyContent: "flex-end" }}>
                                  <Button
                                    variant="outline-primary badge-rounded pr-4"
                                    size={"sm"}
                                    onClick={() => history.push("/proveedores")}
                                    style={{
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      display: "flex",
                                      marginRight: 10
                                    }}
                                  >
                                    <svg
                                      width="12"
                                      height="15"
                                      viewBox="0 0 12 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M11.0807 1.03909C10.9785 0.985891 10.8672 0.959291 10.7559 0.959291C10.6159 0.959291 10.4773 1.00129 10.3576 1.08389L1.25757 7.38389C1.06857 7.51479 0.955872 7.72969 0.955872 7.95929C0.955872 8.18889 1.06857 8.40379 1.25757 8.53469L10.3576 14.8347C10.5711 14.9824 10.8504 14.9999 11.0807 14.8795C11.3117 14.7584 11.4559 14.5197 11.4559 14.2593L11.4559 1.65929C11.4559 1.39889 11.3117 1.16019 11.0807 1.03909Z"
                                        fill="#526FCE"
                                      />
                                    </svg>
                                    <span style={{ paddingLeft: 10 }}>
                                      Volver para la lista
                                    </span>
                                  </Button>

                                  {buttonSubmit()}
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
                <ModalCotizacion
                  showModalVerCot={showModal} handleClose={() => setShowModal(false)}
                ></ModalCotizacion>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
