import React, { useContext, useState } from "react";
import { APICall } from "../../../API";
import { GoogleLogin } from "react-google-login";
import swal from "sweetalert";
import swalMessage from "@sweetalert/with-react";
import { withRouter } from "react-router";
import GlobalContext from "../../../gContext";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";

function Invitacion(props) {
  const gContext = useContext(GlobalContext);

  const [UserData, setUserData] = useState({});
  const [IsLoaded, setIsLoaded] = useState(false);
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [mobileCounter, setMobileCounter] = useState(0);

  const handleChangeNovoMedico = e => {};
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  React.useEffect(() => {
    APICall.getUserDataByActivationKey(path)
      .then(res => {
        setUserData(res);
        console.log(res);
        setIsLoaded(true);
      })
      .catch(e => console.log(e));
  }, []);

  const criarConta = (password, tokenId) => {
    setIsLoaded(false);
    let obj = tokenId ? { tokenId } : { password };
    APICall.registerUser(obj, path)
      .then(res => {
        window.localStorage.setItem("jwt",res.token)
       
      gContext.setIsPublic(false);
        gContext.setUser(res.user);
        setIsLoaded(true);

        swal({
          title: "Cuenta creada con exito!",
          text: "Ahora podes hacer login!",
          icon: "success"
        }).then(willDelete => {
          props.history.push("/");
        });
      })
      .catch(console.log);
  };
  const responseGoogle = async response => {
    //
    
    criarConta("", response.tokenId);
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (e.target.confirmPass.value != e.target.pass.value) {
      return toast.error("las claves son distintas");
    }
    if (e.target.pass.value.length < 8) {
      return toast.error("Contraseña debe tener minimo 8 caracteres");
    }
    criarConta(e.target.pass.value);
  };
  if (!IsLoaded) return null;
  return (
    <div>
      <div className="modal-content">
        <div className="modal-body">
          <div>
            {IsLoaded && !hasConfirmed ? (
              <div
                className="form-group "
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "column",
                  display: "flex"
                }}
              >
                <h2 className="modal-title">
                  Bienvenido{" "}
                  <b>
                    {UserData.name} {UserData.lastName}
                  </b>
                  !
                </h2>
                <h4
                  className="modal-title"
                  style={{ textAlign: "center", lineHeight: 2.4 }}
                >
                  Te invitamos a Tecnoedil para que participes como: <br />
                  <b style={{ fontSize: 24 }}>
                    {translateRole(UserData.role)}{" "}
                    {UserData.role}
                  </b>
                  
                </h4>
                <button
                  type="button"
                  class="btn btn-primary btn-lg mt-3"
                  onClick={() => {
                    setHasConfirmed(true);
                  }}
                  style={{
                    paddingLeft: "2.5rem",
                    paddingRight: "2.5rem",
                    width: "100%"
                  }}
                >
                  Aceptar y crear cuenta
                </button>
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                  <h3 className="mb-5 " style={{ textAlign: "center" }}>
                    Crear nueva contraseña
                  </h3>
                </div>

                <div class="form-outline mb-3">
                  <label class="form-label" for="pass">
                    Email
                  </label>
                  <input
                    type="text"
                    disabled
                    id="email"
                    value={UserData.email}
                    class="form-control form-control-lg"
                    placeholder="Email"
                  />
                </div>

                <div class="form-outline mb-3">
                  <label class="form-label" for="pass">
                    Contraseña
                  </label>
                  <input
                    type="password"
                    id="pass"
                    class="form-control form-control-lg"
                    placeholder="escribi una contraseña"
                  />
                </div>

                <div class="form-outline mb-3">
                  <label class="form-label" for="pass">
                    Confirmar tu Contraseña
                  </label>
                  <input
                    type="password"
                    id="confirmPass"
                    class="form-control form-control-lg"
                    placeholder="Confirmar tu Contraseña"
                  />
                </div>

                <div class="text-center text-lg-start mt-4 pt-2">
                  <button
                    type="submit"
                    class="btn btn-primary btn-lg"
                    style={{
                      paddingLeft: "2.5rem",
                      paddingRight: "2.5rem",
                      width: "100%"
                    }}
                  >
Registrase                  </button>
{/* 
                  <div class="divider d-flex align-items-center my-4">
                    <p class="text-center fw-bold mx-3 mb-0">Ou</p>
                  </div> */}

                  {/* <GoogleLogin
                    responseType={"permission"}
                    clientId="906329086825-v28d77s9nd38caj06lt82n1k1qf8vr01.apps.googleusercontent.com"
                    buttonText="Criar conta com Google"
                    cookiePolicy={"single_host_origin"}
                    fetchBasicProfile={true}
                    onSuccess={responseGoogle}
                    onFailure={e => console.log("ERRO", e)}
                  /> */}
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const translateRole = role => {
  switch (role) {
    case "medico":
      return "Médico";

    case "fisio":
      return " Fisioterapeuta";

    case "paciente":
      return "Paciente";

    case "medico-admin":
      return "Médico & ADMIN";

      break;

    default:
      break;
  }
};

export default withRouter(Invitacion);
