import React, { Fragment } from "react";
const Stateless = ({icon,text}) => {
   return (
      <Fragment>
       
         <div style={{height:"80vh", justifyContent:"flex-start",display:"flex",flexDirection:"column",alignContent:"center",alignItems:"center"}}>
         <i className={icon} style={{fontSize:"20rem",color:"#969BA0"}}></i>
         <h3 style={{color:"#969BA0"}} >{text}</h3>
         </div>
      </Fragment>
   );
};

export default Stateless;
