import { Card } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Row, Table, Col, Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router";
import { APICall } from "../../../../API";
import GlobalContext from "../../../../gContext";
import { Link } from "react-router-dom";
import WrapperHeader from "../../../wrapperHeader";
import dropdownData from "../../../../dropdownData";
import Radios from "../../Radios";
import { toast } from "react-toastify";
export default function RenderEditProveedor({ }) {
  // const { id, operacion } = useParams();

  const history = useHistory();
  const [Proveedor, setProveedor] = React.useState({});
  const [operacion, setOperacion] = React.useState("consultar-proveedor");
  const [rubroOptions, setRubroOptions] = React.useState([]);

  const gContext = React.useContext(GlobalContext);

  const showCotizaciones = idUser => {
    alert(idUser + Proveedor._id);
  };

  useEffect(() => {
    APICall.verify().then(res => {
      setProveedor(res.user);
    });
    let rubroOptionsAux = []

    APICall.getRubros().then((r) => {
      r.data.map((s) => {
        rubroOptionsAux.push(
          s['rubro']
        )
      });
      setRubroOptions(rubroOptionsAux);
    })
  }, []);

  const handleChangeText = e => {
    let proveedorAux = { ...Proveedor };
    proveedorAux[e.target.id] = e.target.value;
    setProveedor(proveedorAux);
  };

  const guardarEditProveedor = () => {
    let p = Proveedor;

    const keysArray = [
      "email",
      "telephone",
      "pais",
      "tipoProveedor",
      "registro",
      "representanteLegal",
      "documentoIdentidad",
      "puesto",
      "razonSocial",
      "nombreFantasia",
      "actividad",
      "inicioActividad",
      "capitalSocial",
      "cantidadEmpleados",
      "ciudad",
      "calle",
      "website",
      "segmento",
      "rubro"
    ];

    var reEmail = /\S+@\S+\.\S+/;
    var rePhone = /^[0-9]+$/;
    let completed = true;
    for (const key in p) {
      if ((p[key] == "" || !p[key]) && keysArray.includes(key)) {
        completed = false;
      }
    }
    if (!completed) {
      toast.error("Debe completar todos los campos.");
    } else if (!reEmail.test(p.email))
      toast.error("El mail ingresado no es correcto.");
    else if (!rePhone.test(p.telephone))
      toast.error("El nro. de teléfono ingresado no es válido.");
    else if (!rePhone.test(p.tipoTelephone))
      toast.error("La característica telefónica ingresada no es válida.");
    else {
      delete p.invitedBy;
      p._id = p.id;
      APICall.editProveedor(p)
        .then(() => {
          toast.success("Proveedor editado con exito");

          setOperacion("consultar-proveedor");
        })
        .catch(e => {
          toast.error("Hubo un error mientras intentamos editar el proveedor");
        });
    }
  };

  const buttonSubmit = () => {
    const iconStyle = {
      fontSize: "1.2rem"
    };
    const buttonLabel = {
      "registrar-proveedor": "Registrar Proveedor",
      "consultar-proveedor": "Editar Proveedor",
      "editar-proveedor": "Guardar Cambios"
    };
    const buttonIcon = {
      "registrar-proveedor": (
        <i class="las la-plus-circle " style={iconStyle}></i>
      ),
      "consultar-proveedor": <i class="las la-edit" style={iconStyle}></i>,
      "editar-proveedor": <i class="las la-check" style={iconStyle}></i>
    };

    const method = {
      // "registrar-proveedor" :()=>registerProveedor(Proveedor),
      "consultar-proveedor": () => setOperacion("editar-proveedor"),
      "editar-proveedor": () => guardarEditProveedor()
    };
    return (
      <Button variant="primary" size={"sm"} onClick={method[operacion]}>
        {buttonIcon[operacion]}
        <span style={{ paddingLeft: 10 }}>{buttonLabel[operacion]}</span>
      </Button>
    );
  };

  return (
    <WrapperHeader>
      <div className="m-2">
        <div className="row">
          <div className="col-lg-12">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="navpills-1"
                role="tabpanel"
              >
                <Table
                  responsive
                  className="table-responsive card-table rounded table-hover fs-14"
                >
                  <div id="example_wrapper" className="dataTables_wrapper">
                    <div id="example" className="card card-body">
                      <Row className="pl-2 pr-2">
                        <Col lg={12}>
                          <Row>
                            <Col lg={2} md={5} sm={6} xs={12}>
                              <Button
                                size={"sm"}
                                variant="primary"
                                style={{
                                  borderRadius: 5,
                                  width: "100%",
                                  marginBottom: 10
                                }}
                              >
                                Mis Datos
                              </Button>
                            </Col>
                            <Col lg={8} md={0} sm={0} xs={0}></Col>
                            <Col lg={2} md={5} sm={6} xs={12}>
                              {buttonSubmit()}
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={12}>
                          <Row>
                            {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

                            <Col lg={12}>
                              <h4 className="mb-3 mt-5">
                                Identificación mi cuenta proveedor
                              </h4>
                              <div className="basic-form">
                                <form action="#">
                                  <Row>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            País
                                          </span>
                                        </div>
                                        <select
                                          className="form-control"
                                          defaultValue={Proveedor.pais}
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          value={Proveedor.pais}
                                          onChange={handleChangeText}
                                          id="pais"
                                        >
                                          {dropdownData.paises.map((e, i) => (
                                            <option value={e}>{e}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            RUC / CNPJ /  ID
                                          </span>
                                        </div>
                                        <input
                                          defaultValue={Proveedor.registro}
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          value={Proveedor.registro}
                                          onChange={handleChangeText}
                                          id="registro"
                                          type="number"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Tipo de Proveedor
                                          </span>
                                        </div>

                                        <select
                                          className="form-control"
                                          value={Proveedor.tipoProveedor}
                                          onChange={handleChangeText}
                                          id="tipoProveedor"
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                        >
                                          {dropdownData.tipoDeProveedor.map(
                                            (e, i) => (
                                              <option value={e}>{e}</option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </Col>
                                  </Row>
                                </form>
                              </div>
                            </Col>

                            {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

                            <Col lg={12}>
                              <h4 className="mb-3 mt-4">
                                Datos del Representante Legal
                              </h4>
                              <div className="basic-form">
                                <form action="#">
                                  <Row>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Representante Legal
                                          </span>
                                        </div>
                                        <input
                                          value={Proveedor.representanteLegal}
                                          defaultValue={
                                            Proveedor.representanteLegal
                                          }
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          onChange={handleChangeText}
                                          id="representanteLegal"
                                          type="text"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Documento de Identidad
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          value={Proveedor.documentoIdentidad}
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          onChange={handleChangeText}
                                          id="documentoIdentidad"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Puesto
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          value={Proveedor.puesto}
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          onChange={handleChangeText}
                                          id="puesto"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </form>
                              </div>
                            </Col>
                            {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}
                            <Col lg={12}>
                              <h4 className="mb-3 mt-4">Datos del Ejecutivo de Cuenta</h4>
                              <div className="basic-form">
                                <form action="#">
                                  <Row>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Nombre
                                          </span>
                                        </div>
                                        <input
                                          value={Proveedor.nombreEjecutivo}
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          onChange={handleChangeText}
                                          id="nombreEjecutivo"
                                          type="text"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Teléfono
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          value={Proveedor.telefonoEjecutivo}
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          onChange={handleChangeText}
                                          id="telefonoEjecutivo"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Email
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          value={Proveedor.emailEjecutivo}
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          onChange={handleChangeText}
                                          id="emailEjecutivo"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>

                                  </Row>
                                </form>
                              </div>
                            </Col>
                            {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

                            <Col lg={12}>
                              <h4 className="mb-3 mt-4">Datos del Proveedor</h4>
                              <div className="basic-form">
                                <form action="#">
                                  <Row>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Razón Social / Nombre
                                          </span>
                                        </div>
                                        <input
                                          value={Proveedor.razonSocial}
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          onChange={handleChangeText}
                                          id="razonSocial"
                                          type="text"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Nombre de Fantasía
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          value={Proveedor.nombreFantasia}
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          onChange={handleChangeText}
                                          id="nombreFantasia"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Tipo de Actividad
                                          </span>
                                        </div>
                                        <select
                                          className="form-control"
                                          value={Proveedor.actividad}
                                          onChange={handleChangeText}
                                          id="actividad"
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                        >
                                          {dropdownData.tipoActividad.map(
                                            (e, i) => (
                                              <option value={e}>{e}</option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Inicio de Actividades
                                          </span>
                                        </div>
                                        <input
                                          type="date"
                                          value={Proveedor.inicioActividad}
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          onChange={handleChangeText}
                                          id="inicioActividad"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Capital Social (USD)
                                          </span>
                                        </div>
                                        <input
                                          type="number"
                                          value={Proveedor.capitalSocial}
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          onChange={handleChangeText}
                                          id="capitalSocial"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Nº de empleados
                                          </span>
                                        </div>
                                        <input
                                          type="number"
                                          value={Proveedor.cantidadEmpleados}
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          onChange={handleChangeText}
                                          id="cantidadEmpleados"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </form>
                              </div>
                            </Col>

                            {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

                            <Col lg={12}>
                              <h4 className="mb-3 mt-4">Datos de Ubicación</h4>
                              <div className="basic-form">
                                <form action="#">
                                  <Row>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Departamento/Estado
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={Proveedor.estado}
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          onChange={handleChangeText}
                                          id="estado"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Ciudad
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          value={Proveedor.ciudad}
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          onChange={handleChangeText}
                                          id="ciudad"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Teléfono (Característica)
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          value={Proveedor.tipoTelephone}
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          onChange={handleChangeText}
                                          id="tipoTelephone"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Teléfono (Número)
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          value={Proveedor.telephone}
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          onChange={handleChangeText}
                                          id="telephone"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={12}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Dirección
                                          </span>
                                        </div>
                                        <input
                                          value={Proveedor.calle}
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          onChange={handleChangeText}
                                          id="calle"
                                          type="text"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Sitio Web
                                          </span>
                                        </div>
                                        <input
                                          value={Proveedor.website}
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          onChange={handleChangeText}
                                          id="website"
                                          type="text"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Email
                                          </span>
                                        </div>
                                        <input
                                          value={Proveedor.email}
                                          disabled={
                                            !operacion.includes("registrar")
                                          }
                                          onChange={handleChangeText}
                                          id="email"
                                          type="mail"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </form>
                              </div>
                            </Col>

                            {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}

                            <Col lg={12}>
                              <h4 className="mb-3 mt-4">
                                Productos y/o Servicios
                              </h4>
                              <div className="basic-form">
                                <form action="#">
                                  <Row>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Segmento
                                          </span>
                                        </div>
                                        <select
                                          className="form-control"
                                          value={Proveedor.segmento}
                                          onChange={handleChangeText}
                                          id="segmento"
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                        >
                                          {dropdownData.segmento.map((e, i) => (
                                            <option value={e}>{e}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Rubro
                                          </span>
                                        </div>

                                        <select
                                          className="form-control"
                                          value={Proveedor.rubro}
                                          onChange={handleChangeText}
                                          id="rubro"
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                        >
                                          {rubroOptions.map((e, i) => (
                                            <option value={e}>{e}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </Col>
                                    <Col lg={12}>
                                      <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text">
                                            Detalle de Producto/ Servicio
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          value={Proveedor.detalle}
                                          disabled={operacion.includes(
                                            "consultar"
                                          )}
                                          onChange={handleChangeText}
                                          id="detalle"
                                          className="form-control"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </form>
                              </div>
                            </Col>

                            {/* OTRA BLOQUE DE INPUTS        OTRO BLOQUE DE INPUTS */}
                            {Proveedor.radioForm && Proveedor.radioForm.length && (
                              <Radios
                                operacion={operacion}
                                value={Proveedor.radioForm}
                                onChange={handleChangeText}
                              ></Radios>
                            )}
                            {/* BOTOES DE ABAJO */}

                            <Col sm={12} className="mt-5">
                              <Row className="mt-5 ">
                                <Col lg={6} sm={12} className="mb-2">
                                  <Row style={{ justifyContent: "flex-start" }}>
                                    {
                                      (operacion.includes("consultar") ||
                                        operacion.includes("editar")) && (
                                        <div></div>
                                      )
                                      // <Button variant="danger" onClick={() => history.push("/proveedores")} size={"sm"}>
                                      //   <svg
                                      //     width="14"
                                      //     height="14"
                                      //     viewBox="0 0 14 14"
                                      //     fill="none"
                                      //     xmlns="http://www.w3.org/2000/svg"
                                      //   >
                                      //     <path
                                      //       fill-rule="evenodd"
                                      //       clip-rule="evenodd"
                                      //       d="M13.3178 0.875026C12.8345 0.391758 12.051 0.391712 11.5678 0.874923L7.19204 5.25L2.8175 0.875078C2.33427 0.391799 1.55074 0.391798 1.0675 0.875077C0.584326 1.3583 0.584327 2.1417 1.0675 2.62492L5.44219 7L1.0675 11.3751C0.584327 11.8583 0.584326 12.6417 1.0675 13.1249C1.55074 13.6082 2.33427 13.6082 2.8175 13.1249L7.19204 8.75L11.5678 13.1251C12.051 13.6083 12.8345 13.6082 13.3178 13.125C13.801 12.6417 13.801 11.8583 13.3178 11.375L8.94312 7L13.3178 2.62497C13.801 2.14173 13.801 1.35827 13.3178 0.875026Z"
                                      //       fill="white"
                                      //     />
                                      //   </svg>

                                      //   <span style={{ paddingLeft: 10 }}>
                                      //     Eliminar Proveedor
                                      //   </span>
                                      // </Button>
                                    }
                                  </Row>
                                </Col>
                                <Col lg={6} sm={12}>
                                  <Row style={{ justifyContent: "flex-end" }}>
                                    {buttonSubmit()}
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WrapperHeader>
  );
}
