import React, { Fragment } from "react";

/// Components
import Markup from "./jsx";


/// Style
import "./css/style.css";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";

import { withResizeDetector } from "react-resize-detector";
import { GlobalProvider } from "./gContext";
import { ToastContainer } from "react-toastify";

const App = ({ width }) => {
   const body = document.querySelector("body");

   width >= 1300
      ? body.setAttribute("data-sidebar-style", "full")
      : width <= 1299 && width >= 767
      ? body.setAttribute("data-sidebar-style", "mini")
      : body.setAttribute("data-sidebar-style", "overlay");
   return (
      <Fragment>
         <GlobalProvider>
       
         <Markup />
         <ToastContainer></ToastContainer>
         </GlobalProvider>
         
      </Fragment>
   );
};

export default withResizeDetector(App);
