import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Table, Pagination, Dropdown } from "react-bootstrap";
import data from "./Tabldata.js";
import { toast } from "react-toastify";
import Stateless from "./Stateless.js";

const Alldata = ({ listaPacientes, columnas }) => {
  const history = useHistory();
  const sort = 10;
  let jobPagination = Array(Math.ceil(listaPacientes.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const activePag = useRef(0);
  const jobData = useRef(
    listaPacientes.slice(
      activePag.current * sort,
      (activePag.current + 1) * sort
    )
  );
  const [demo, setdemo] = useState();
  const onClick = i => {
    activePag.current = i;

    jobData.current = listaPacientes.slice(
      activePag.current * sort,
      (activePag.current + 1) * sort
    );
    setdemo(
      listaPacientes.slice(
        activePag.current * sort,
        (activePag.current + 1) * sort
      )
    );
  };

  useEffect(() => {
    onClick(0);
  }, [listaPacientes, columnas]);
  const handleCopy = key => {
    navigator.clipboard.writeText(
      "https://localhost:3000/page-completar-cadastro/" +
        key
    );
    toast.success("Link Copiado", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  };
 
  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="navpills-1"
              role="tabpanel"
            >
              {jobData.current.length == 0 ? (
                <Stateless
                  text={"No hay datos en esta lista!"}
                  icon={"flaticon-381-layer-1"}
                ></Stateless>
              ) : (
                <Table
                  responsive
                  className="table-responsive card-table rounded table-hover fs-14"
                >
                  <div id="example_wrapper" className="dataTables_wrapper">
                    <table
                      id="example"
                      className="table border-no display mb-4 dataTablesCard table-responsive-xl project-bx"
                    >
                      <thead>
                        <tr role="row">
                          {columnas.map((d, i) => (
                            <th key={i}>{d}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {jobData.current.map((d, i) => (
                          <tr key={i}>
                            <td>
                              <div>
                                <p className="text-primary mb-sm-2 mb-0">
                                  {d.id}
                                </p>
                                <h4 className="title font-w600 mb-2">
                                  <div  className="text-black">
                                    {d.name}
                                  </div>
                                </h4>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                {!d.hasCompletedRegistration ? (
                                  <button
                                    onClick={() => window.open("/page-completar-cadastro/"+d.activationKey,"_blank")}
                                    class="btn btn-primary btn-lg"
                                    style={{
                                      height: "20%",
                                      width: "90%",
                                      fontSize: 20
                                    }}
                                    
                                  >
                                    link registrar
                                  </button>
                                ) : (
                                  <span
                                    className="bolt-icon mr-sm-3 mr-2"
                                    style={{ zIndex: 999 }}
                                  >
                                    <i
                                      className={
                                        d.hasCompletedRegistration
                                          ? "fa fa-check"
                                          : "fa fa-exclamation-triangle"
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                {d.email}{" "}
                             
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                {d.role}{" "}
                             
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                {d.invitedBy?.name}{" "}
                                {d.invitedBy?.lastName}
                                <br />

                                {d.invitedBy?.email}
                              </div>
                            </td>
                         
                            <td>
                              <div >
                                <i
                                  className="fa fa-calendar-o mr-3"
                                  aria-hidden="true"
                                ></i>

                                {new Date(d.createdAt).toLocaleString()}
                              </div>
                            </td>
                            <td>
                              <Link
                                to={`/operar-usuario/${d._id}/consultar-usuario`}
                              >
                                <i
                                  className="fa fa-eye mr-3"
                                  style={{ fontSize: "1.5rem" }}
                                ></i>
                              </Link>
                              <Link
                                to={`/operar-usuario/${d._id}/editar-usuario`}
                              >
                                <i
                                  className="fa fa-edit mr-3"
                                  style={{ fontSize: "1.5rem" }}
                                ></i>
                              </Link>
                              <Link
                                to={`/operar-usuario/${d._id}/eliminar-usuario`}
                              >
                                <i
                                  className="fa fa-trash mr-3"
                                  style={{ fontSize: "1.5rem" }}
                                ></i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex justify-content-end  align-items-center mt-3">
                      <div className="dataTables_paginate paging_simple_numbers">
                        <Pagination size="lg">
                          <li
                            className="page-item page-indicator "
                            onClick={() =>
                              activePag.current > 0 &&
                              onClick(activePag.current - 1)
                            }
                          >
                            {/* <Link className="page-link" to="#">  <i className="la la-angle-left" /></Link> */}
                            <Link className="paginate_button previous" to="#">
                              {" "}
                              Volver
                            </Link>
                          </li>
                          {jobPagination.map((number, i) => (
                            <Pagination.Item
                              className={
                                activePag.current === i ? "active " : ""
                              }
                              style={{color:"black"}}
                              onClick={() => onClick(i)}
                            >
                              <a style={{color:"black"}}>
                              {number}
                              </a>
                            </Pagination.Item>
                          ))}
                          <li
                            className="page-item page-indicator"
                            onClick={() =>
                              activePag.current + 1 < jobPagination.length &&
                              onClick(activePag.current + 1)
                            }
                          >
                            {/* <Link className="page-link" to="#"><i className="la la-angle-right" /></Link> */}
                            <Link className="paginate_button next" to="#">
                              {" "}
                              Proximo
                            </Link>
                          </li>
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </Table>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Alldata;
