import { Card } from "@material-ui/core";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import Rte from "./Rte";

function ModalCot({ handleChangeCot, showCot, handleClose, createCot, list }) {
  const [title, settitle] = React.useState("");

  React.useEffect(() => {
    settitle("")
  },[handleClose])
  return (
    <Modal
      show={showCot}
      onHide={() => handleClose()}
      className="modal fade"
      id="addOrderModalside"

      size="xl"
      scrollable={false}
    >
      <div role="document" className="m-5">
        <h3 className="pb-2">Nuevo Pedido de cotización</h3>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text">Línea de asunto</span>
          </div>
          <input
            value={title}
            onChange={t => {
              settitle(t.target.value);
              handleChangeCot(t.target.value, "title");
            }}
            id="title"
            type="text"
            className="form-control"
          />
        </div>
        <Rte handleChange={t => handleChangeCot(t, "descripcion")}></Rte>
        <Button
          variant="primary"
          style={{ display: "flex", alignItems: "center" }}
          size={"md"}
          onClick={() => createCot()}
        >
          <i class="las la-paper-plane" style={{ fontSize: "1.2rem" }}></i>
          <span style={{ paddingLeft: 10, color: "white" }}>
            Enviar Pedido de cotización: {list} Mails
          </span>
        </Button>
      </div>
    </Modal>
  );
}

export default ModalCot;
